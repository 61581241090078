import React, { Component } from 'react';
import Select from "react-select";

class SelecPosition extends Component {
    render() {
        return (
            <React.Fragment>
                <input
                    type="hidden"
                    name={this.props.name}
                    value={this.props.value}
                />
                <Select
                    isMulti
                    closeMenuOnSelect={false}
                    autoFocus={false}
                    className={`link-form`}
                    classNamePrefix="select"
                    placeholder="Seleccione"
                    name={this.props.name}
                    onChange={this.props.handleChangeAutocomplete}
                    options={this.props.options}
                    defaultValue={this.props.editValues}
                />
            </React.Fragment>
        );
    }
}

export default SelecPosition;
