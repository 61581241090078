import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";

class ModalUpdateImagen extends Component {

    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modeEdit: false,
            formUpdateSize: {
                max_width_image: this.props.formato.max_width_image
            }
        }
    }

    handleChangeRange = (e) => {
        this.setState({
            formUpdateSize: {
                ...this.state.formUpdateSize,
                [e.target.name]: e.target.value
            }
        })
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    handleClickEdit = (state) => {
        this.setState({
            modeEdit: state
        })

        if (!state) {
            this.setState({
                formUpdateSize: {
                    ...this.state.formUpdateSize,
                    ["max_width_image"]: this.props.formato.max_width_image
                }
            })
        }
    }

    handleClickUpdate = () => {
        fetch(`/formatos/update_max_width_image/${this.props.formato.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateSize), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.messageSuccess(data);
                this.setState({
                    modeEdit: false,
                    formUpdateSize: {
                        ...this.state.formUpdateSize,
                        ["max_width_image"]: data.register.max_width_image
                    }
                })
            });
    }

    render() {
        const styleButtonEdit = {
            color: "#3f6ad8",
            cursor: "pointer"
        }


        const styleButtonCanecel = {
            color: "#ff0000",
            cursor: "pointer"
        }


        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className={`col-md-12`}>
                                    {(this.props.url_img == null || this.props.modeEditFile) ? (
                                        <React.Fragment>
                                            <Dropzone onDrop={(files) => this.props.handleFile(files)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div
                                                        {...getRootProps({
                                                            className: 'dropzone',
                                                        })}
                                                    >
                                                        <input {...getInputProps()} accept={"image/*"} />
                                                        <p>{`${(this.props.nameFile != "" ? this.props.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                    </div>
                                                )}
                                            </Dropzone>

                                            <div className="col-md-12 mt-3 text-center p-0">
                                                <div className="alert alert-info mb-0" role="alert">
                                                    <b>Solo puede subir imagen de máximo 1 MB</b>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="col-md-12 text-center pt-3 pb-3">
                                                <img src={this.props.url_img} style={{ width: `${this.state.formUpdateSize.max_width_image}%`, height: "auto" }} />
                                            </div>

                                            <div className="col-md-12">
                                                <input
                                                    type="range"
                                                    name="max_width_image"
                                                    disabled={!this.state.modeEdit}
                                                    min={10}
                                                    max={100}
                                                    className='form form-control'
                                                    onChange={this.handleChangeRange}
                                                    value={this.state.formUpdateSize.max_width_image}
                                                />
                                                <div>{this.state.formUpdateSize.max_width_image}</div>
                                                {this.state.modeEdit ? <span style={styleButtonEdit} onClick={this.handleClickUpdate}> Actualizar </span> : <span onClick={() => this.handleClickEdit(true)} style={styleButtonEdit}> Editar </span>}
                                                {this.state.modeEdit ? <span style={styleButtonCanecel} onClick={() => this.handleClickEdit(false)}> Cancelar </span> : null}
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            {this.props.nameFile != "" ? (
                                <React.Fragment>
                                    <button
                                        className="btn-shadow btn btn-info mr-2"
                                        onClick={() => this.props.HandleClickFile()}
                                    >
                                        {this.props.modeEditFile ? "Actualizar imagen" : "Subir imagen"}
                                    </button>

                                    <button
                                        className="btn btn-outline-danger"
                                        onClick={() => this.props.cancelUpload()}
                                    >
                                        Cancelar
                                    </button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {this.props.modeEditFile ? (
                                        <React.Fragment>
                                            <button
                                                className="btn-shadow btn btn-info mr-2"
                                                onClick={() => this.props.HandleClickFile()}
                                            >
                                                Actualizar imagen
                                            </button>

                                            <button
                                                className="btn btn-outline-danger"
                                                onClick={() => this.props.cancelUpload()}
                                            >
                                                Cancelar
                                            </button>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <button
                                                className="btn btn-light"
                                                onClick={() => this.props.editFIle()}
                                            >
                                                <i className="fas fa-pencil-alt"></i>
                                            </button>

                                            {this.props.url_img != null && (
                                                <button
                                                    className="btn-shadow btn btn-danger ml-3"
                                                    onClick={() => this.props.removeImagen()}
                                                >
                                                    <i className="fas fa-trash-alt"></i>
                                                </button>
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}

                            <button className="btn btn-outline-danger" onClick={() => this.props.toggle()}>Cerrar</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalUpdateImagen;
