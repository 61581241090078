import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ModalUpdateQuestionType from './ModalUpdateQuestionType';
import { isMobile } from 'react-device-detect';
import Ckaditor from './Ckeditor';
import ReactHtmlParser from 'react-html-parser';
import Select from "react-select";
import Dropzone from "react-dropzone";
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

import ModalConfigurationDate from './ModalConfigurationDate'
import ModalConfigurationDinamicFile from './ModalConfigurationDinamicFile'
import ModalResponsabilidades from '../FormatResponsabilities/Index';
import FormCreateVersions from '../FormatVersions/FormCreate'
import ModalSelectType from '../Formats/ModalSelectType'
import ModalShowVersions from '../FormatVersions/Index'
import IndexFormatAssociations from '../FormatAssociations/Index'
import IndexDuplicateItem from '../FormatDuplication/Index';
import ModalPDFs from '../FormatPdf/Index'
import IndexExelReport from '../FormatExcelReports/Index'
import IndexQuestionAssociations from '../QuestionAssociation/Index';
import IndexIndicators from '../FormatIndicators/Index'
import Preloader from '../../../GeneralComponents/Preloaders';
import IndexUpdateTaskBoard from '../FormatUpdateTaskBoard/Index'
import ConfigurationFormat from '../ConfigurationFormat/Index'
import ModalQuestionFormatInput from '../QuestionFormatInput/ModalIndex';
import ModalQuestionFormatConditional from '../QuestionFormatCondition/ModalIndex';
import FormatSearchValueIndex from '../FormatSearchValue/Index';
import ModalUpdateFormatDate from './ModalUpdateFormatDate';
import ModalFormatQr from '../FormatQr/Index';
import is_imagen from 'is-image'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ModalIndexCustomView from '../FormatCustomView/Index';
import ModalIndexQuestionAction from '../QuestionAction/ModalIndex';
import QuestionCustomFormula from '../QuestionCustomFormula/Index';
import QuestionDateIndex from '../QuestionDate/Modalindex';
import TimeField from "react-simple-timefield";
import IndexEditLabel from '../QuestionLabel/Index';
//Icons google
import GoogleFontLoader from 'react-google-font-loader';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';
import ModalQuestionStyle from '../QuestionStyle/ModalIndex';

//modals configuration format 

import ModalUpdateImagen from './ModalUpdateImagen';
import { ajaxSettings } from 'jquery';
import QuestionTypeMatrix from './QuestionMatrix/QuestionTypeMatrix';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class Show extends Component {
    constructor(props) {
        super(props)
        const date = new Date();
        var mes = date.getMonth() + 1;
        this.fecha = `${date.getFullYear()}-${mes < 10 ? '0' + mes : mes.toString()}-${date.getDate() < 10 ? 0 : ""}${date.getDate()}`
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modalSelectType: false,
            showTitle: false,
            showMessage: false,
            showMessageError: false,
            modalQr: false,
            showQuestions: true,
            modalUpdateQuestionType: false,
            modalQuestionDate: false,
            modalIndexCustomView: false,
            modalUpdateFormatDate: false,
            modalQuestionAction: false,
            modalQuestionCustomFormula: false,
            modalIndexDuplicateItem: false,
            questionMatrix: {},
            showInfoCode: false,
            ErrorValues: true,
            ErrorValueOptionUpdate: true,
            errorValueOptions: true,
            errorCommaCheckbox: true,
            modalFormatSearchValueIndex: false,
            errorMessage: "",
            ErrorValuesCreateQuestion: true,
            ErrorValuesOptions: true,
            has_taks: true,
            modalVersionCreate: false,
            modalShowVersions: false,
            modalQuestionFormatCondition: false,
            has_comments: true,
            modeEditFile: false,
            showCheckBoxQuestion: this.props.configuration_format.is_test,
            modal: false,
            modalQuestionStyle: false,
            modalConfigurationDinamicFile: false,
            modalQuestionFormatInput: false,
            data_step: {},
            was_edited: this.props.format.was_edited,
            step_position_id: "",
            loadedIcon: false,
            step: {},
            showAlertClipboard: false,

            nameBtn: "",
            question_id: "",
            question_config_id: "",
            step_id: "",
            stepId: "",
            generalId: "",
            countMax: 0,
            question_dinamic: {},

            formAddOptions: {
                name: ""
            },

            formUpdateOption: {
                name: "",
                index: null,
            },

            formCreateSeccion: {
                name: "Sección",
                format_id: this.props.format.id,
            },

            formUpdatePositions: {
                position_ids: [],
            },

            selectedOption: {
                format_relation_id: "",
                label: "Formato"
            },

            selectedOptionCargo: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionQuestion: {
                question_format_id: "",
                label: "Pregunta"
            },

            selectedOptionMulti: {
                label: "Formatos",
                formatos: []
            },

            formUpdateStep: {
                name: "",
                description: "",
                is_private: false,
                position_ids: [],
            },

            formUpdateFormatTemplate: {
                user_ids: [],
                question_id: "",
                task_board_id: "",
            },

            selectedOptionTaskBoardTemplate: {
                label: this.props.format.task_board ? this.props.format.task_board.name : "Seleccione la plantilla",
                task_board_id: this.props.format.task_board ? this.props.format.task_board.id : "",
            },

            selectedOptionUsersTemplate: {
                label: "Usuarios",
                user_ids: [],
            },

            selectedOptionQuestionTemplate: {
                label: this.props.format.question_task_board ? this.props.format.question_task_board.name : "Seleccione el nombre del tablero",
                value: this.props.format.question_task_board ? this.props.format.question_task_board.id : "",
            },

            selectedOptionFormatPdf: {
                format_pdf_id: "",
                label: "Seleccione el pdf"
            },

            question_info: {},

            formQuestion: {
                question: "",
                question_type: "",
                decimal_count_show: 2,
                answer_formula: "",
                question_alias: "",
                format_relation_id: "",
                question_format_id: "",
                formatos: [],
                code_format: "",
                value_compare: "",
                step_id: "",
                description: "",
                decimal_count: 2,
                is_multiple: false,
                is_currency: false,
                has_default_value: false,
                default_value_text: "",
                default_value_number: "",
                add_new: false,
                align_item: "vertical",
                options: [],
                position_ids: [],
                format_pdf_id: "",
                special_value: "",
            },

            formOperation: {
                name: "",
                operation: "",
            },

            formUpdateQuestion: {
                question: "",
                position: "",
                question_type: "",
                decimal_count_show: 2,
                answer_formula: "",
                question_alias: "",
                format_relation_id: "",
                formatos: [],
                code_format: "",
                value_compare: "",
                description: "",
                decimal_count: 2,
                is_multiple: false,
                is_currency: false,
                has_default_value: false,
                default_value_text: "",
                default_value_number: "",
                add_new: false,
                align_item: "vertical",
                question_format_id: "",
                options: [],
                position_ids: [],
                format_pdf_id: "",
                special_value: "",
            },


            question_last_id: "",
            question_style: {},

            formUpdateQuestionLast: {
                question: "",
                position: "",
                question_type: "",
                answer_formula: "",
                format_relation_id: "",
                code_format: "",
                description: "",
                decimal_count: 2,
                is_multiple: false,
                is_currency: false,
                add_new: false,
                question_format_id: "",
                options: [],
            },

            formUpdateStepPosition: {
                position: "",
            },

            formUpdateFile: {
                file: {},
            },

            configuration_format: {
                has_taks: this.props.configuration_format.has_taks,
                has_comments: this.props.configuration_format.has_comments,
                has_cause_analisis: this.props.configuration_format.has_cause_analisis,
                active_external_link: this.props.configuration_format.active_external_link,
                send_notification: this.props.configuration_format.send_notification,
                email_content: this.props.configuration_format.email_content,
                columns_format_number: this.props.configuration_format.columns_format_number,
                positions: this.props.configuration_format.positions,
            },

            showTable: false,
            format_responsability_id: "",

            url_img: this.props.format.imagen_url,
            nameFile: "",

            selectValues: [],
            formatos: [],
            questions: [],
            cargos: [],
            editValues: [],
            editValuesCargos: [],
            editValuesPosition: [],
            step_questions: [],
            all_formats: [],
            format_associations: [],
            arrayString: "",
            questions_alias: [],
            current_questions: this.props.data[0].questions,

            format_resposabilities: this.props.format_resposabilities,
            loader_create_question: false,

            nameFileQuestionImagen: "",
            sizeFileQuestionImagen: "",

            //modals configuration format

            modalUpdateImagen: false,
            modalConfigurationFormat: false,
            modalTemplateTask: false,
            modalFormatAssociation: false,
            modalQuestionAssociation: false,
            modalResponsabilities: false,
            modalPDFs: false,

            format_questions: []
        }
    }

    clearValues = () => {
        this.setState({
            showTitle: false,
            showQuestions: true,
            showPopover: false,
            ErrorValues: true,
            ErrorValuesOptions: true,
            errorValueOptions: true,
            errorCommaCheckbox: true,

            errorMessage: "",

            selectedOption: {
                format_relation_id: "",
                label: "Formato"
            },

            formUpdateOption: {
                name: "",
                index: null,
            },

            selectedOptionQuestion: {
                question_format_id: "",
                label: "Pregunta"
            },

            selectedOptionFormatPdf: {
                format_pdf_id: "",
                label: "Seleccione el pdf"
            },

            formQuestion: {
                question: "",
                question_type: "",
                decimal_count_show: 2,
                value_compare: "",
                answer_formula: "",
                format_relation_id: "",
                code_format: "",
                question_alias: "",
                question_format_id: "",
                formatos: [],
                step_id: "",
                description: "",
                is_multiple: false,
                align_item: "vertical",
                is_currency: false,
                has_default_value: false,
                default_value_text: "",
                default_value_number: "",
                add_new: false,
                options: [],
                position_ids: [],
                format_pdf_id: "",
            },

            formUpdateStep: {
                name: "",
                description: "",
                is_private: false,
                position_ids: [],
            },

            formUpdatePositions: {
                position_ids: [],
            },

            formAddOptions: {
                name: ""
            },

            formUpdateQuestion: {
                question: "",
                position: "",
                decimal_count_show: 2,
                value_compare: "",
                file: {},
                question_type: "",
                answer_formula: "",
                question_alias: "",
                format_relation_id: "",
                formatos: [],
                code_format: "",
                description: "",
                question_format_id: "",
                is_multiple: false,
                is_currency: false,
                align_item: "vertical",
                has_default_value: false,
                default_value_text: "",
                default_value_number: "",
                add_new: false,
                options: [],
                position_ids: [],
                format_pdf_id: "",
                special_value: "",
            },

            question_id: "",

            nameBtn: "",
            step_id: "",
            stepId: "",

            nameFile: "",
            sizeFile: "",
            questionMatrix: {},
            selectValues: [],
        })
    }

    componentDidMount = () => {
        this.setDefaultValues()
        this.LoadQuestion(this.props.format.id)

        var div = document.getElementById('ui-scroll');

        var hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
        var hasVerticalScrollbar = div.scrollHeight > div.clientHeight;

        console.log("hasHorizontalScrollbar", hasHorizontalScrollbar);
        console.log("hasVerticalScrollbar", hasVerticalScrollbar);

        let array_format_questions = []

        this.props.steps_questions.filter(question => question.question_type === "answer_string" || question.question_type === "answer_integer" || question.question_type === "answer_date" || question.question_type === "code_format" || question.question_type === "answer_select" || question.question_type === "answer_integer" || question.question_type === "email").map((item) => (
            array_format_questions.push({ label: item.question, value: item.id })
        ))

        this.setState({
            format_questions: array_format_questions,
        })
    }

    setDefaultValues = () => {
        let array = [];
        let arrayCargos = [];
        let arrayPositonEdit = [];
        let arrayAssociation = [];
        let arrayFormatAssociation = [];

        this.props.positions.map((item) => (
            arrayCargos.push({ label: item.name, value: item.id })
        ))

        this.props.formatos.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.props.configuration_format.positions.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id })
        ))

        this.props.all_formats.map((item) => (
            arrayAssociation.push({ label: item.name, value: item.id })
        ))

        this.props.format_associations.map((item) => {
            arrayFormatAssociation.push({ label: item.name, value: item.id })
        })

        this.setState({
            formatos: array,
            cargos: arrayCargos,
            editValues: arrayPositonEdit,
            all_formats: arrayAssociation,
            format_associations: arrayFormatAssociation,
        })

    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    messageSuccessConfirm = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: true,
            customClass: 'ui-confirm-try-type-formula'

        });
    };


    handleChangeStateQuestion = (e, field) => {
        if (this.state.question_id != "") {
            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    [field]: e.target.checked,
                }
            }, () => {
                this.HadleClickUpdateQuestion();
            });
        } else {
            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    [field]: e.target.checked,
                }
            })
        }
    }

    handleChangeStateHasDefaultValue = (e, field) => {
        if (this.state.question_id) {
            if (e.target.checked) {
                this.setState({
                    formUpdateQuestion: {
                        ...this.state.formUpdateQuestion,
                        [field]: e.target.checked,
                    },

                    /* selectedOptionFormatPdf: {
                        format_pdf_id: "",
                        label: "Seleccione el pdf"
                    }, */
                }, () => {
                    this.HadleClickUpdateQuestion();
                });
            } else {
                this.setState({
                    formUpdateQuestion: {
                        ...this.state.formUpdateQuestion,
                        /*   default_value_text: "", */
                        [field]: e.target.checked,
                    },

                }, () => {
                    this.HadleClickUpdateQuestion();
                });
            }

        } else {
            if (e.target.checked) {
                this.setState({
                    formQuestion: {
                        ...this.state.formQuestion,
                        /*    format_pdf_id: "", */
                        [field]: e.target.checked,
                    },

                    /*  selectedOptionFormatPdf: {
                         format_pdf_id: "",
                         label: "Seleccione el pdf"
                     }, */
                })
            } else {
                this.setState({
                    formQuestion: {
                        ...this.state.formQuestion,
                        /*   default_value_text: "", */
                        [field]: e.target.checked,
                    },
                })
            }
        }
    }

    handleChangeStateQuestionNumber = (e) => {
        if (this.state.question_id != "") {
            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    is_currency: e.target.checked,
                    answer_formula: (!e.target.checked && this.state.formUpdateQuestion.question_type == "answer_date" ? "" : this.state.formUpdateQuestion.answer_formula)
                }
            }, () => {
                this.HadleClickUpdateQuestion();
            });
        } else {
            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    is_currency: e.target.checked,
                    answer_formula: (!e.target.checked && this.state.formQuestion.question_type == "answer_date" ? "" : this.state.formQuestion.answer_formula)
                }
            })
        }
    }

    //select 

    handleChangeAutocompleteFormatAssociation = selectedOption => {
        if (this.state.question_id != "") {
            this.setState({
                selectedOption,
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    format_relation_id: selectedOption.value
                }
            }, () => {
                this.HadleClickUpdateQuestion();
            });
        } else {
            this.setState({
                selectedOption,
                formQuestion: {
                    ...this.state.formQuestion,
                    format_relation_id: selectedOption.value
                }
            });
        }
    };


    handleChangeAutocompleteSubForm = selectedOption => {
        if (this.state.question_id != "") {
            this.setState({
                selectedOption,
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    format_relation_id: selectedOption.value
                }
            }, () => {
                this.HadleClickUpdateQuestion();
            });
        } else {
            this.setState({
                selectedOption,
                formQuestion: {
                    ...this.state.formQuestion,
                    format_relation_id: selectedOption.value
                }
            });
        }

        this.LoadQuestion(this.props.format.id, "format_id")
    };

    handleChangeAutocomplete = selectedOption => {
        if (this.state.question_id != "") {
            this.setState({
                selectedOption,
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    format_relation_id: selectedOption.value
                }
            }, () => {
                this.HadleClickUpdateQuestion();
            });
        } else {
            this.setState({
                selectedOption,
                formQuestion: {
                    ...this.state.formQuestion,
                    format_relation_id: selectedOption.value
                }
            });
        }

        this.LoadQuestion(selectedOption.value, "format_id")
    };

    handleChangeAutocompleteQuestion = selectedOptionQuestion => {
        if (this.state.formUpdateQuestion.question_format_id != "") {
            this.setState({
                selectedOptionQuestion,
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    question_format_id: selectedOptionQuestion.value
                }
            }, () => {
                this.HadleClickUpdateQuestion();
            });
        } else {
            this.setState({
                selectedOptionQuestion,
                formQuestion: {
                    ...this.state.formQuestion,
                    question_format_id: selectedOptionQuestion.value
                }
            });
        }
    };

    handleChangeAutocompleteFormatPdf = selectedOptionFormatPdf => {
        if (this.state.question_id) {
            this.setState({
                selectedOptionFormatPdf,
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    format_pdf_id: selectedOptionFormatPdf.value
                }
            }, () => {
                this.HadleClickUpdateQuestion();
            });
        } else {
            this.setState({
                selectedOptionFormatPdf,
                formQuestion: {
                    ...this.state.formQuestion,
                    format_pdf_id: selectedOptionFormatPdf.value
                }
            });
        }
    };

    validationFormOptionCreate = () => {
        if (this.state.formQuestion.question_type == "answer_checkbox" || this.state.formQuestion.question_type == "answer_radio" || this.state.formQuestion.question_type == "answer_select") {
            if (this.state.formQuestion.options.length >= 1) {
                this.setState({ ErrorValuesOptions: true })
                return true
            } else {
                this.setState({ ErrorValuesOptions: false })
                return false
            }
        } else {
            if (this.state.formQuestion.question_type != "answer_checkbox" || this.state.formQuestion.question_type != "answer_radio" || this.state.formQuestion.question_type != "answer_select") {
                this.setState({ ErrorValuesOptions: true })
                return true
            } else {
                this.setState({ ErrorValuesOptions: false })
                return false
            }
        }
    }

    validationForm = () => {
        if (this.state.formQuestion.question != "" &&
            this.state.formQuestion.decimal_count_show != (this.state.formQuestion.question_type == "answer_integer" ? "" : "asdasd") &&
            this.state.formQuestion.question_type != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    compare = () => {
        var options = (this.state.question_id ? (this.state.formUpdateOption.index != null ? this.state.formUpdateQuestion.options.filter((e, index) => index != this.state.formUpdateOption.index) : this.state.formUpdateQuestion.options) : (this.state.formUpdateOption.index != null ? this.state.formQuestion.options.filter((e, index) => index != this.state.formUpdateOption.index) : this.state.formQuestion.options));
        var palabraMin1 = this.state.formUpdateOption.index != null ? this.state.formUpdateOption.name.toLowerCase() : this.state.formAddOptions.name.toLowerCase();
        let is_valid = false

        options.forEach((element) => {
            var palabraMin2 = element.name.toLowerCase();

            var palabraSinAcentos1 = palabraMin1.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            var palabraSinAcentos2 = palabraMin2.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

            if (palabraSinAcentos1 === palabraSinAcentos2) {
                is_valid = true
            }
        });

        return is_valid
    }


    validationOptions = () => {
        const form = this.state.question_id ? this.state.formUpdateQuestion : this.state.formQuestion
        if (form.question_type == "answer_checkbox" || form.question_type == "answer_radio" || form.question_type == "answer_select") {
            if (this.compare()) {
                this.setState({ errorValueOptions: false })
                return false
            } else {
                this.setState({ errorValueOptions: true })
                return true
            }
        } else {
            return true
        }
    }

    editOptions = (name, index) => {
        console.log("editOptions", name);
        console.log("index", index);

        this.setState({
            formUpdateOption: {
                name: name,
                index: index,
            }
        })
    }

    validationFormQuestions = () => {
        console.log("status")
        if (this.state.formQuestion.question_type == "answer_formula") {

            if (this.state.formQuestion.answer_formula != "") {
                this.setState({ ErrorValuesCreateQuestion: true })
                return true
            } else {
                this.setState({ ErrorValuesCreateQuestion: false })
                return false
            }

        } else if (this.state.formQuestion.question_type == "code_format") {
            console.log("status")
            let status = false
            if (this.state.formQuestion.code_format.includes('{cons}')) {
                status = true
            }
            console.log(status)

            if (this.state.formQuestion.code_format != "" && status) {
                this.setState({ ErrorValuesCreateQuestion: true })
                return true
            } else {
                console.log("qui")
                this.setState({ ErrorValuesCreateQuestion: false })
                return false
            }

        } else if (this.state.formQuestion.question_type == "format_id" || this.state.formQuestion.question_type == "answer_subform") {
            console.log("aqui")
            if (this.state.formQuestion.format_id != "" &&
                this.state.formQuestion.question_format_id != ""

            ) {
                this.setState({ ErrorValuesCreateQuestion: true })
                return true
            } else {
                this.setState({ ErrorValuesCreateQuestion: false })
                return false
            }

        } else {
            return true
        }
    }

    updateSeccion = () => {
        fetch(`/formatos/steps/${this.state.step_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateStep), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccess(data);
                this.props.updateItem(data.step);
                this.updateStateVersion(true)
                this.clearValues();
            });
    }

    updateStepQuestion = (e, question_id, last_step_id) => {
        fetch(`/formatos/update_step_question/${e.target.value}/${question_id}/${last_step_id}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData()
            });
    }

    updateStepPosition = (e) => {
        fetch(`/formatos/update_step_position/${this.state.step_position_id}/${this.props.format.id}/${e.target.value}/${this.state.formUpdateStepPosition.position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.setState({
                    step_position_id: "",
                    formUpdateStepPosition: {
                        position: "",
                    }
                })
            });
    }

    createQuestion = () => {
        if (this.validationForm() && this.validationFormQuestions() && this.validationFormOptionCreate()) {
            this.setState({ loader_create_question: true })
            fetch(`/formatos/questions`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formQuestion), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if (data.type != "error") {
                        this.LoadQuestion(this.props.format.id);
                        this.props.loadData()
                        this.updateStateVersion(true)
                        this.clearValues();
                        this.setState({ loader_create_question: false, showMessage: true })
                        setTimeout(() => {
                            this.setState({ showMessage: false })
                        }, 2000);
                    } else {
                        this.clearValues();
                        this.setState({ loader_create_question: false, showMessageError: true, errorMessage: data.error })
                        setTimeout(() => {
                            this.setState({ showMessageError: false, errorMessage: "" })
                        }, 5000);
                    }
                });
        }
    }

    LoadQuestion = (format_id, from = null) => {
        fetch(`/formatos/get_questions/${format_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                let array = [];
                let arrayAlias = [];

                if (from == "format_id") {
                    data.register.filter((e) => e.question_type != "answer_text" && e.question_type != "answer_file" && e.question_type != "url" && e.question_type != "answer_localization" && e.question_type != "pdf_viewer" && e.question_type != "answer_subform" && e.question_type != "answer_signature" && e.question_type != "format_id").map((item) => (
                        array.push({ label: item.question, value: item.id }),
                        arrayAlias.push({ label: item.question_alias, type: item.question_type, value: item.id })
                    ))
                } else {
                    data.register.map((item) => (
                        array.push({ label: item.question, value: item.id }),
                        arrayAlias.push({ label: item.question_alias, type: item.question_type, value: item.id })
                    ))
                }

                this.setState({
                    questions: array,
                    question_alias: arrayAlias,
                })
            });
    }

    removeFile = (e, question_id) => {
        e.stopPropagation()
        fetch(`/formatos/remove_file_question/${question_id}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.update_question(data.register)
            });
    }

    testQuestionFormula = () => {
        fetch(`/formatos/test_question_formula/${this.state.question_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccessConfirm(data)
            });
    }

    deleteStep = (step) => {
        Swal.fire({
            title: 'Escribe el nombre de la sección para poder eliminarla',
            input: 'text',
            footer: `<p>El nombre de la sección es (${step.name}) </p>`,

            inputAttributes: {
                autocapitalize: 'off'
            },

            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#16aaff',
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                if (login == step.name.trim()) {
                    fetch(`/formatos/delete_step/${step.id}`, {
                        method: "PATCH",
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            this.props.updateCount(response.steps);
                            this.props.reduceCountMax();
                            this.updateStateVersion(true)
                            this.props.loadData();
                        });
                } else {
                    Swal.showValidationMessage("El nombre no concuerda")
                }
            },

            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {

            }
        })
    };


    removeImagen = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡La imagen será eliminada para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/remove_img/${this.props.format.id}`, {
                    method: "PATCH",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.setState({
                            url_img: null,
                        })
                    });
            }
        });
    }

    deleteQuestion = (question) => {
        Swal.fire({
            title: 'Escribe el nombre de la pregunta para poder eliminarla',
            input: 'text',
            footer: `<p>El nombre de la pregunta es (${question.question}) </p>`,

            inputAttributes: {
                autocapitalize: 'off'
            },

            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#16aaff',
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                if (login == question.question.trim()) {
                    fetch(`/formatos/delete_question/${question.id}`, {
                        method: "PATCH",
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            if (response.type == "success") {
                                this.props.loadData()
                                this.clearValues();
                                this.updateStateVersion(true)
                            } else if (response.type == "error_format_id") {

                                alert("¡No puedes eliminar esta pregunta ya que esta asociada a un Subformulario");
                            }

                        });
                } else {
                    Swal.showValidationMessage("El nombre no concuerda")
                }
            },

            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {

            }
        })
    };


    HandleChangeCreateSeccion = (e) => {
        this.setState({
            formCreateSeccion: {
                ...this.state.formCreateSeccion,
                [e.target.name]: e.target.value,
            }
        })
    }

    add_step = () => {
        this.setState({
            modalCreateSeccion: true,
            formCreateSeccion: {
                ...this.state.formCreateSeccion,
                name: `Sección ${this.props.data.length + 1}`
            }
        })
    }

    handleClickCreateSeccion = () => {
        fetch(`/formatos/add_step/${this.props.format.id}`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.formCreateSeccion),
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateData(data.step);
                this.updateStateVersion(true);
                this.props.sumState();
                this.props.sumCountMax();
                this.setState({
                    modalCreateSeccion: false,
                    formCreateSeccion: {
                        ...this.state.formCreateSeccion,
                        name: "Sección"
                    }
                })
            });
    }

    updateValue = (e) => {
        console.log(e.target.value)
    }

    getChangeName = (name) => {

        if (name == "Respuesta breve") {
            return "answer_string"

        } else if (name == "Párrafo") {
            return "answer_text"

        } else if (name == "Fecha") {
            return "answer_date"

        } else if (name == "Fecha Hora") {
            return "answer_datetime"

        } else if (name == "Hora") {
            return "answer_hour"
        }
        else if (name == "Carga de archivos") {
            return "answer_file"

        } else if (name == "Lista desplegable") {
            return "answer_select"

        } else if (name == "Opción múltiple") {
            return "answer_radio"

        } else if (name == "Casillas de verificación") {
            return "answer_checkbox"

        } else if (name == "Lista de usuarios") {
            return "answer_user_id"

        } else if (name == "Lista de procesos") {
            return "proceso_id"

        } else if (name == "Lista de áreas específicas") {
            return "specific_area_id"

        } else if (name == "Fórmula") {
            return "answer_formula"

        } else if (name == "Formato") {
            return "format_id"

        } else if (name == "Lista de cargos") {
            return "position_id"

        } else if (name == "Lista de eventos relacionados") {
            return "related_event_id"

        } else if (name == "Código") {
            return "code_format"

        } else if (name == "Campo numérico") {
            return "answer_integer"

        } else if (name == "Campo dinámico") {
            return "dynamic_field"

        } else if (name == "Link") {
            return "url"

        } else if (name == "Asociación entre campos") {
            return "answer_association"

        } else if (name == "Email") {
            return "email"

        } else if (name == "Geolocalización") {
            return "answer_localization"

        } else if (name == "Fecha / Hora (Automática)") {
            return "answer_autdatetime"

        } else if (name == "Subformulario") {
            return "answer_subform"

        } else if (name == "Firma") {
            return "answer_signature"

        } else if (name == "CE10") {
            return "ce_10"

        } else if (name == "Matriz") {
            return "answer_matrix"

        } else if (name == "Visor PDF") {
            return "pdf_viewer"
        }
    }

    // answer_string answer_checkbox answer_text answer_date answer_integer answer_hour answer_radio answer_select answer_formula

    getReturnName = (name) => {
        if (name == "answer_string") {
            return "Respuesta breve"

        } else if (name == "answer_text") {
            return "Párrafo"

        } else if (name == "answer_date") {
            return "Fecha"

        } else if (name == "answer_datetime") {
            return "Hora Fecha"

        } else if (name == "answer_hour") {
            return "Hora"
        }
        else if (name == "answer_file") {
            return "Carga de archivos"

        } else if (name == "answer_select") {
            return "Lista desplegable"

        } else if (name == "answer_radio") {
            return "Opción múltiple"

        } else if (name == "answer_checkbox") {
            return "Casillas de verificación"

        } else if (name == "answer_integer") {
            return "Campo numérico"

        } else if (name == "answer_user_id") {
            return "Lista de usuarios"

        } else if (name == "dynamic_field") {
            return "Campo dinámico"

        } else if (name == "proceso_id") {
            return "Lista de procesos"

        } else if (name == "specific_area_id") {
            return "Lista de áreas específicas"

        } else if (name == "position_id") {
            return "Lista de cargos"

        } else if (name == "answer_formula") {
            return "Fórmula"

        } else if (name == "format_id") {
            return "Formato"

        } else if (name == "answer_matrix") {
            return "Matriz"

        } else if (name == "code_format") {
            return "Código"

        } else if (name == "related_event_id") {
            return "Lista de eventos relacionados"

        } else if (name == "url") {
            return "Link"

        } else if (name == "answer_association") {
            return "Asociación entre campos"

        } else if (name == "email") {
            return "Email"

        } else if (name == "answer_localization") {
            return "Geolocalización"

        } else if (name == "answer_autdatetime") {
            return "Fecha / Hora (Automática)"

        } else if (name == "answer_subform") {
            return "Subformulario"

        } else if (name == "answer_signature") {
            return "Firma"

        } else if (name == "ce_10") {
            return "CE10"
        } else if (name == "pdf_viewer") {
            return "Visor PDF"
        }
    }

    setName = (name) => {
        if (this.state.question_id) {
            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    question_type: this.getChangeName(name),
                    //options: this.state.selectValues,
                },

                showPopover: false,
                modalUpdateQuestionType: false,
                ErrorValues: true,
                nameBtn: name,
            }, () => {
                this.HadleClickUpdateQuestion();
            });
        } else {
            this.setState({
                showPopover: false,
                ErrorValues: true,
                modalSelectType: false,
                nameBtn: name,

                formQuestion: {
                    ...this.state.formQuestion,
                    question_type: this.getChangeName(name),
                },
            })
        }
    }

    HandleChangeFormOptions = (e, question_type) => {
        if (question_type === "answer_checkbox" && e.target.value.includes(',')) {
            this.setState({
                errorCommaCheckbox: false
            });
        } else {
            this.setState({
                errorCommaCheckbox: true,
                formAddOptions: {
                    name: e.target.value
                }
            });
        }
        console.log("hola mundo", e.target.value)

    }

    HandleChangeFormOptionsBlur = (e, question_type) => {
        console.log("on blur")
        if (question_type === "answer_checkbox") {
            this.setState({
                errorCommaCheckbox: true
            });
        }
    }

    HandleChangeQuestion = (e, step) => {
        this.setState({
            formQuestion: {
                ...this.state.formQuestion,
                [e.target.name]: e.target.value,
                step_id: step.id
            }
        });
    }

    HandleChangeFormula = (e) => {
        this.setState({
            formQuestion: {
                ...this.state.formQuestion,
                [e.target.name]: e.target.value,
            }
        });
    }

    handleChangeDefaultSelect = (e) => {
        if (this.state.question_id) {
            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    default_value_text: "",
                    [e.target.name]: e.target.value,
                }
            });
        } else {
            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    default_value_text: "",
                    [e.target.name]: e.target.value,
                }
            });
        }
    }

    handleChangeDefaultValueText = (e) => {
        if (this.state.question_id) {
            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    special_value: "",
                    [e.target.name]: e.target.value,
                }
            });
        } else {
            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    special_value: "",
                    [e.target.name]: e.target.value,
                }
            });
        }
    }

    AddNameToQuestion = (name) => {
        this.setState({
            formQuestion: {
                ...this.state.formQuestion,
                question_type: name,
            }
        });
    }

    HandleChangeUpdateStep = (e) => {
        this.setState({
            formUpdateStep: {
                ...this.state.formUpdateStep,
                [e.target.name]: e.target.value,
            }
        })
    }

    HandleChangeUpdateStepDescription = (e) => {
        this.setState({
            formUpdateStep: {
                ...this.state.formUpdateStep,
                description: e,
            }
        })
    }

    HandleChangeUpdateConfiguration = (e) => {
        this.setState({
            configuration_format: {
                ...this.state.configuration_format,
                email_content: e,
            }
        })
    }

    HandleChangeUpdateConfigurationText = (e) => {
        this.setState({
            configuration_format: {
                ...this.state.configuration_format,
                [e.target.name]: e.target.value,
            }
        })
    }


    HandleChangeOptions = (e, selectValuesIndex) => {
        const input = e.target.value
        this.setState({
            selectValues: this.state.selectValues.map((item, index) => {
                if (selectValuesIndex === index) {
                    return input
                }
                return item;
            })
        });
    }

    toogleLabelQuestion = (e, question) => {
        e.stopPropagation();
    }

    HandleChangeOptionsFormCreate = (e, selectValuesIndex, question_type) => {

        if (question_type === "answer_checkbox" && e.target.value.includes(',')) {
            this.setState({
                errorCommaCheckbox: false
            });
        } else {
            const input = e.target.value
            this.setState({
                errorCommaCheckbox: false,
                formQuestion: {
                    ...this.state.formQuestion,
                    options: this.state.formQuestion.options.map((item, index) => {
                        if (selectValuesIndex === index) {
                            return { name: input, value: false }
                        }
                        return item;
                    })
                }
            });
        }


    }

    updateIsTest = (state) => {
        this.setState({
            showCheckBoxQuestion: state,
        })
    }

    getIcon = (name) => {
        if (name == "Respuesta breve") {
            return <i className="fas fa-align-center color-blue"></i>
        } else if (name == "Párrafo") {
            return <i className="fas fa-align-left color-blue"></i>
        } else if (name == "Fecha") {
            return <i className="fas fa-calendar-day color-blue"></i>
        } else if (name == "Hora Fecha") {
            return <i className="fas fa-clock color-blue"></i>
        } else if (name == "Hora") {
            return <i className="fas fa-clock color-blue"></i>
        }
        else if (name == "Carga de archivos") {
            return <i className="fas fa-cloud-upload-alt color-blue"></i>
        } else if (name == "Lista desplegable") {
            return <i className="fas fa-chevron-circle-down color-blue"></i>
        } else if (name == "Opción múltiple") {
            return <i className="far fa-dot-circle color-blue"></i>
        } else if (name == "Casillas de verificación") {
            return <i className="fas fa-check-square color-blue"></i>
        } else if (name == "Lista de usuarios") {
            return <i className="fas fa-users color-blue"></i>
        } else if (name == "Lista de procesos") {
            return <i className="fas fa-users color-blue"></i>
        } else if (name == "Lista de áreas específicas") {
            return <i className="fas fa-users color-blue"></i>
        } else if (name == "Lista de cargos") {
            return <i className="fas fa-users color-blue"></i>
        } else if (name == "Fórmula") {
            return <i className="fas fa-square-root-alt color-blue"></i>
        } else if (name == "Lista de eventos relacionados") {
            return <i className="fas fa-users color-blue"></i>
        } else if (name == "Formato") {
            return <i className="fas fa-database color-blue"></i>
        } else if (name == "Campo numérico") {
            return <i className="fas fa-list-ol color-blue"></i>
        } else if (name == "Código") {
            return <i className="far fa-list-alt color-blue"></i>
        } else if (name == "Campo dinámico") {
            return <i className="fas fa-list-ol color-blue"></i>
        } else if (name == "Link") {
            return <i className="fas fa-link color-blue"></i>
        } else if (name == "Email") {
            return <i className="fas fa-envelope color-blue"></i>
        } else if (name == "Asociación entre campos") {
            return <i className="fas fa-envelope color-blue"></i>
        } else if (name == "Geolocalización") {
            return <i className="fas fa-map-marker-alt color-blue"></i>
        } else if (name == "Fecha / Hora (Automática)") {
            return <i className="fas fa-clock color-blue"></i>
        } else if (name == "Subformulario") {
            return <i className="fas fa-copy color-blue"></i>
        } else if (name == "Firma") {
            return <i className="fas fa-file-signature color-blue"></i>
        } else if (name == "CE10") {
            return <i className="fas fa-file-medical color-blue"></i>
        } else if (name == "Matriz") {
            return <i className="fas fa-table color-blue"></i>
        } else if (name == "Visor PDF") {
            return <i className="fas fa-file-pdf color-blue"></i>
        }
    }

    getTypeQuestion = (question) => {
        if (question.question_type == "answer_string") {
            return (
                <div className='row'>
                    <div className="col-md-5">
                        <input
                            type="text"
                            className="form form-control imput-options-disabled"
                            disabled
                            placeholder={"Texto de respuesta breve"}
                        />
                    </div>

                    <div className={`col-md-5`}>

                    </div>
                </div>
            )

        } else if (question.question_type == "answer_integer") {
            return (
                <input
                    type="number"
                    className="form form-control imput-options-disabled"
                    disabled
                    placeholder={"Texto numerico"}
                />
            )

        } else if (question.question_type == "answer_hour") {
            return (
                <input
                    type="text"
                    className="form form-control imput-options-disabled"
                    disabled
                    placeholder={"Hora"}
                />
            )

        }
        else if (question.question_type == "url") {
            return (
                <input
                    type="number"
                    className="form form-control imput-options-disabled"
                    disabled
                    placeholder={"Url"}
                />
            )

        } else if (question.question_type == "email") {
            return (
                <input
                    type="number"
                    className="form form-control imput-options-disabled"
                    disabled
                    placeholder={"Email"}
                />
            )

        } else if (question.question_type == "answer_user_id") {
            return (
                <select
                    className="form form-control imput-options-disabled"
                    disabled
                >
                    <option value="">Selección de usuarios</option>
                </select>
            )

        } else if (question.question_type == "dynamic_field") {
            return (
                <button
                    className="btn-shadow btn btn-info"
                    onClick={(e) => this.toogleQuestionStyle(e, "new", question)}
                >
                    <i className="fas fa-gear"></i> Configurar campo
                </button>
            )

        } else if (question.question_type == "proceso_id") {
            return (
                <select
                    className="form form-control imput-options-disabled"
                    disabled
                >
                    <option value="">Selección de procesos</option>
                </select>
            )

        } else if (question.question_type == "specific_area_id") {
            return (
                <select
                    className="form form-control imput-options-disabled"
                    disabled
                >
                    <option value="">Selección de áreas específicas</option>
                </select>
            )

        } else if (question.question_type == "position_id") {
            return (
                <select
                    className="form form-control imput-options-disabled"
                    disabled
                >
                    <option value="">Selección de cargos</option>
                </select>
            )

        } else if (question.question_type == "related_event_id") {
            return (
                <select
                    className="form form-control imput-options-disabled"
                    disabled
                >
                    <option value="">Selección de eventos relacionados</option>
                </select>
            )

        } else if (question.question_type == "answer_formula") {
            return (
                <input
                    type="text"
                    className="form form-control imput-options-disabled"
                    disabled
                    value={question.answer_formula}
                />
            )
        } else if (question.question_type == "code_format") {
            return (
                <input
                    type="text"
                    className="form form-control imput-options-disabled"
                    disabled
                    value={question.code_format}
                />
            )

        } else if (question.question_type == "answer_subform") {
            return (
                <input
                    type="text"
                    className="form form-control imput-options-disabled"
                    disabled
                    value={question.format_relation != undefined ? question.format_relation.name : ""}
                />
            )

        } else if (question.question_type == "format_id") {
            return (
                <div className="row">
                    <div className="col-md-6 ui-showFormatCategories-fieldFormat-cardClose">
                        <input
                            type="text"
                            className="form form-control imput-options-disabled"
                            disabled
                            value={question.format_relation != undefined ? question.format_relation.name : ""}
                        />
                    </div>

                    <div className="col-md-6">
                        <input
                            type="text"
                            className="form form-control imput-options-disabled"
                            disabled
                            value={question.question_format != undefined ? question.question_format.question : ""}
                        />
                    </div>
                </div>
            )

        } else if (question.question_type == "answer_text") {
            return (
                <textarea
                    className="form form-control imput-options-disabled"
                    disabled
                    rows="3"
                    placeholder={"Texto de respuesta largo"}
                />
            )

        } else if (question.question_type == "answer_date") {
            return (
                <input
                    type="text"
                    className={`form form-control`}
                    disabled
                    value={this.fecha}
                />
            )

        } else if (question.question_type == "answer_datetime") {
            return (
                <input
                    type="text"
                    className={`form form-control`}
                    disabled
                    value={this.fecha}
                />
            )

        } else if (question.question_type == "answer_file") {
            return (
                <button
                    className="btn bnt-light"
                    style={{ border: "1px solid" }}
                    disabled
                >
                    <i className="fas fa-upload mr-2"></i> Agregar archivo
                </button>
            )

        } else if (question.question_type == "pdf_viewer") {
            return (
                <div className="col-md-8 pl-0">
                    <button
                        disabled="disabled"
                        className="btn btn-light"
                    >
                        <i className="fas fa-map-marker-alt mr-2"></i> {question.has_default_value ? <a href={question.default_value_text} target="_blank" >{question.default_value_text} (Pdf externo)</a> : `${question.format_pdf ? question.format_pdf.name : ""} (Pdf creado desde beewo)`}
                    </button>
                </div>
            )

        } else if (question.question_type == "answer_localization") {
            return (
                <div className="col-md-8 pl-0">
                    <button
                        disabled="disabled"
                        className="btn btn-light"
                    >
                        <i className="fas fa-map-marker-alt mr-2"></i> Geolocalización
                    </button>
                </div>
            )

        } else if (question.question_type == "answer_autdatetime") {
            return (
                <div className="col-md-8 pl-0">
                    <button
                        disabled="disabled"
                        className="btn btn-light"
                    >
                        <i className="fas fa-clock mr-2"></i> Fecha / Hora (Automática)
                    </button>
                </div>
            )

        } else if (question.question_type == "ce_10") {
            return (
                <div className="col-md-8 pl-0">
                    <button
                        disabled="disabled"
                        className="btn btn-light"
                    >
                        <i className="fas fa-clock mr-2"></i> CE10
                    </button>
                </div>
            )

        } else if (question.question_type == "answer_association") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-3">
                            <b>Formato</b>
                            <input
                                type="text"
                                className="form form-control imput-options-disabled"
                                disabled
                                placeholder={question.format_relation ? question.format_relation.name : ""}
                            />
                        </div>

                        <div className="col-md-3">
                            <b>Pregunta</b>
                            <input
                                type="text"
                                className="form form-control imput-options-disabled"
                                disabled
                                placeholder={question.question_format ? question.question_format.question : ""}
                            />
                        </div>

                        <div className="col-md-3">
                            <b>Formula</b>
                            <input
                                type="text"
                                className="form form-control imput-options-disabled"
                                disabled
                                placeholder={question.answer_formula}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (question.question_type == "answer_select") {

            return (
                <React.Fragment>
                    {question.options.map((option, index) => (
                        <p className={`${index == 0 ? "mt-3" : ""}`}>{index + 1}. {option.name}</p>
                    ))}
                </React.Fragment>
            )

        } else if (question.question_type == "answer_matrix") {
            return (
                <div className='card col-md-12 mt-3 pt-4'>
                    <QuestionTypeMatrix
                        question={question}
                        questionMatrix={this.state.questionMatrix}
                        updateQuestionMatrix={this.props.updateQuestionMatrix}
                    />
                </div>
            )

        } else if (question.question_type == "answer_radio") {

            return (
                <React.Fragment>
                    {question.align_item == "vertical" ? (
                        <React.Fragment>
                            {question.options.map((option, index) => (
                                <p className={`${index == 0 ? "mt-3" : ""}`}><i className="far fa-dot-circle"></i> {option.name}</p>
                            ))}
                        </React.Fragment>
                    ) : (
                        <div className="ui-showFormatFormatos-radioHorizontal">
                            <React.Fragment>
                                {question.options.map((option, index) => (
                                    <div className="ui-showFormatFormatos-radio">
                                        <div className="ui-showFormatFormatos-optionNameRadio">{option.name}</div>
                                        <i className="far fa-dot-circle"></i>
                                    </div>
                                ))}
                                {/* <p>Arreglar la vista en horizontal</p> */}
                            </React.Fragment>
                        </div>
                    )}
                </React.Fragment>
            )

        } else if (question.question_type == "answer_checkbox") {

            return (
                <React.Fragment>
                    {question.align_item == "vertical" ? (
                        <React.Fragment>
                            {question.options.map((option, index) => (
                                <p className={`${index == 0 ? "mt-3" : ""}`}><i className="fas fa-check-square"></i> {option.name}</p>
                            ))}
                        </React.Fragment>
                    ) : (
                        <div className="ui-showFormatFormatos-checkboxHorizontal">
                            <React.Fragment>
                                {question.options.map((option, index) => (
                                    <div className="ui-showFormatFormatos-checkbox">
                                        <i className="fas fa-check-square"></i>
                                        <div className="ui-showFormatFormatos-optionNameCheck">{option.name}</div>
                                    </div>
                                ))}
                                {/* <p>Arreglar la vista en horizontal</p> */}
                            </React.Fragment>
                        </div>
                    )}
                </React.Fragment>
            )
        } else if (question.question_type == "answer_signature") {

            return (
                <React.Fragment>
                    <div className="col-md-8 pl-0">
                        <button
                            disabled="disabled"
                            className="btn btn-light"
                        >
                            <i className="fas fa-file-signature mr-2"></i> Firma
                        </button>
                    </div>
                </React.Fragment>
            )
        }
    }

    removeArray = (index) => {
        var arr = this.state.selectValues
        delete arr[index]
        this.setState({
            selectValues: arr.filter(item => item != null)
        })
    }

    removeArrayUpdateQuestion = (indexItem) => {
        var array = this.state.formUpdateQuestion.options
        //console.log("array.filter((item, index) => index !== indexItem)", array.filter((item, index) => index !== indexItem));
        this.setState({
            formUpdateQuestion: {
                ...this.state.formUpdateQuestion,
                options: array.filter((item, index) => index !== indexItem),
            },
        }, () => {
            this.HadleClickUpdateQuestionOptionValue();
        });
    }

    addArray = () => {
        if (this.validationFormOptions() && this.validationOptions()) {
            const array = this.state.question_id ? this.state.formUpdateQuestion.options : this.state.formQuestion.options
            array.push({ name: this.state.formAddOptions.name, value: false })

            if (this.state.question_id != "") {

                this.setState({
                    formUpdateQuestion: {
                        ...this.state.formUpdateQuestion,
                        options: array,
                    },

                    formAddOptions: {
                        name: ""
                    },
                    errorValueOptions: true
                }, () => {
                    //this.HadleClickUpdateQuestionOptionValue();
                });
            } else {
                this.setState({
                    formQuestion: {
                        ...this.state.formQuestion,
                        options: array,
                    },

                    formAddOptions: {
                        name: ""
                    },
                    errorValueOptions: true
                })
            }
        }
    }

    addArrayFormUpdate = () => {
        if (this.validationFormOptions() && this.validationOptions()) {
            const array = this.state.formUpdateQuestion.options
            array.push({ name: this.state.formAddOptions.name, value: false })
            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    options: array,
                },

                formAddOptions: {
                    name: ""
                },
            }, () => {
                this.HadleClickUpdateQuestionOptionValue();
            });
        }
    }

    HandleChangeUpdateAnswer = (e) => {
        this.setState({
            formUpdateQuestion: {
                ...this.state.formUpdateQuestion,
                [e.target.name]: e.target.value,
            },
        })
    }

    validationFormOptions = () => {
        if (this.state.formAddOptions.name != "") {
            this.setState({ ErrorValuesOptions: true })
            return true
        } else {
            this.setState({ ErrorValuesOptions: false })
            return false
        }
    }

    addAnother = () => {
        if (this.state.question_id != "") {
            const array = this.state.formUpdateQuestion.options
            array.push({ name: "Otra..", value: false })

            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    options: array,
                },

                formAddOptions: {
                    name: ""
                },

                selectValues: array
            })
        } else {

            const array = this.state.formQuestion.options
            array.push({ name: "Otra..", value: false })

            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    options: array,
                },

                formAddOptions: {
                    name: ""
                },

                selectValues: array
            })
        }
    }

    HandleChangeQuestionFormula = (e) => {
        if (this.state.question_id) {

            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    answer_formula: this.state.formUpdateQuestion.answer_formula + e.target.value,
                }
            }, () => {
                this.HadleClickUpdateQuestion();
            });

        } else {

            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    answer_formula: this.state.formQuestion.answer_formula + e.target.value,
                },
            })


            // this.state.formQuestion.answer_formula != "" ? this.state.formQuestion.answer_formula + array.join('').toString() : array.join('').toString()

        }
    }

    handleChangeAutocompleteMulti = (selectedOptionMulti) => {
        let array = []

        if (selectedOptionMulti) {
            selectedOptionMulti.map((item) => (
                array.push(item.value)
            ))
        }

        if (this.state.question_id) {
            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    formatos: selectedOptionMulti ? array : [],
                }
            })
        } else {
            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    formatos: selectedOptionMulti ? array : [],
                }
            })
        }
    }

    handleChangeUpdateOption = (e, question_type) => {
        if (question_type === "answer_checkbox" && e.target.value.includes(',')) {
            this.setState({
                errorCommaCheckbox: false
            });
        } else {
            this.setState({
                errorCommaCheckbox: true,
                formUpdateOption: {
                    ...this.state.formUpdateOption,
                    name: e.target.value
                }
            });
        }
    }


    validateUpdateOption = () => {
        if (this.state.formUpdateOption.name != "") {
            this.setState({ ErrorValueOptionUpdate: true })
            return true
        } else {
            this.setState({ ErrorValueOptionUpdate: false })
            return false
        }
    }


    handleClickUpdateOption = () => {
        if (this.validationOptions() && this.validateUpdateOption()) {
            if (this.state.question_id) {
                this.setState({
                    formUpdateQuestion: {
                        ...this.state.formUpdateQuestion,
                        options: this.state.formUpdateQuestion.options.map((item, index) => {
                            if (this.state.formUpdateOption.index === index) {
                                return { name: this.state.formUpdateOption.name, value: item.value }
                            }
                            return item;
                        })
                    }
                }, () => {
                    this.HadleClickUpdateQuestion(true);
                });
            } else {

                this.setState({
                    formQuestion: {
                        ...this.state.formQuestion,
                        options: this.state.formQuestion.options.map((item, index) => {
                            if (this.state.formUpdateOption.index === index) {
                                return { name: this.state.formUpdateOption.name, value: item.value }
                            }
                            return item;
                        })
                    }
                }, () => {
                    this.cancelEditOptions();
                });
            }
        }
    }

    removeArray = (indexItem) => {
        if (this.state.question_id) {
            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    options: this.state.formUpdateQuestion.options.filter((item, index) => index !== indexItem),
                }
            }, () => {
                this.HadleClickUpdateQuestion(true);
            });
        } else {
            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    options: this.state.formQuestion.options.filter((item, index) => index !== indexItem),
                }
            })
        }
    }

    getCheckBox = (options, index) => {
        console.log("question", options[index]);
        return (
            <React.Fragment>
                {this.state.formUpdateQuestion.question_type == "answer_radio" ? (
                    <input type="radio" checked={options[index] ? options[index].value : ""} onChange={(e) => this.HandleChangeUpdateOptionsValue(e, index)} />
                ) : (
                    <input type="checkbox" checked={options[index] ? options[index].value : ""} onChange={(e) => this.HandleChangeUpdateOptionsValue(e, index)} />
                )}
            </React.Fragment>
        )
    }

    renderViewOptions = (handleChangeOptions, view) => {
        const options = this.state.question_id ? this.state.formUpdateQuestion.options : this.state.formQuestion.options

        if (view == "vertical") {
            return (
                <div className="row">
                    {options.map((option, index) => (
                        <React.Fragment>
                            <div className={`col-md-7 ${index >= 0 ? "mt-3" : ""} ui-showFormatCategories-optionsFields`} key={index}>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className={`form form-control ${(this.state.question_id ? (this.state.formUpdateQuestion.options[index] ? this.state.formUpdateQuestion.options[index].value : "") : (this.state.formQuestion.options[index] ? this.state.formQuestion.options[index].value : "")) ? "imput-options-color" : ""}`}
                                        value={this.state.formUpdateOption.index == index ? this.state.formUpdateOption.name : (this.state.question_id ? (this.state.formUpdateQuestion.options[index] ? this.state.formUpdateQuestion.options[index].name : "") : (this.state.formQuestion.options[index] ? this.state.formQuestion.options[index].name : ""))}
                                        placeholder={!this.state.ErrorValueOptionUpdate && this.state.formUpdateOption.index == index ? "El campo no puede estar vacio" : option.name}
                                        onChange={(e) => this.state.formUpdateOption.index == index ? this.handleChangeUpdateOption(e, this.state.formUpdateQuestion.question_type) : this.HandleChangeOptionsFormCreate(e, index, this.state.formUpdateQuestion.question_type)}
                                        disabled={this.state.formUpdateOption.index == index ? false : true}
                                    />

                                    <div className="input-group-append">
                                        {true && (
                                            <button className="btn ui-btn-timeRomove" type="button" onClick={() => this.editOptions(option.name, index)}>
                                                <i className="fas fa-pencil-alt icon-remove-option ui-showFormatCategories-iconTime" style={{ fontSize: "20px" }}></i>
                                            </button>
                                        )}

                                        {options.length >= 2 && (
                                            <button className="btn ui-btn-timeRomove" type="button" onClick={() => this.removeArray(index)}>
                                                <i className="fas fa-times icon-remove-option ui-showFormatCategories-iconTime" style={{ fontSize: "20px" }}></i>
                                            </button>
                                        )}

                                        {this.state.showCheckBoxQuestion && (
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    {this.getCheckBox(options, index)}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {this.state.formUpdateOption.index == index && (
                                    <React.Fragment>
                                        <a
                                            className="link-add"
                                            onClick={() => this.handleClickUpdateOption()}
                                        >
                                            Actualizar
                                        </a>

                                        <a
                                            className="link-remove btn-outline-danger"
                                            onClick={() => this.cancelEditOptions(index)}
                                        >
                                            Cancelar
                                        </a>
                                    </React.Fragment>
                                )}

                            </div>
                        </React.Fragment>
                    ))}
                </div>
            )
        } else {
            return (
                <div className="ui-showFormatFormatos-inputRadioHorizontal">
                    {options.map((option, index) => (
                        <React.Fragment>
                            <div className="ui-showFormatFormatos-inputRadio-radio" key={index}>
                                <div className="ui-showFormatFormatos-inputRadio-input-group">
                                    <input
                                        type="text"
                                        className={`form form-control ${(this.state.question_id ? (this.state.formUpdateQuestion.options[index] ? this.state.formUpdateQuestion.options[index].value : "") : (this.state.formQuestion.options[index] ? this.state.formQuestion.options[index].value : "")) ? "imput-options-color" : ""}`}
                                        value={this.state.formUpdateOption.index == index ? this.state.formUpdateOption.name : (this.state.question_id ? (this.state.formUpdateQuestion.options[index] ? this.state.formUpdateQuestion.options[index].name : "") : (this.state.formQuestion.options[index] ? this.state.formQuestion.options[index].name : ""))}
                                        placeholder={!this.state.ErrorValueOptionUpdate && this.state.formUpdateOption.index == index ? "El campo no puede estar vacio" : option.name}
                                        onBlur={this.HadleClickUpdateQuestionOptionValue}
                                        onChange={(e) => this.state.formUpdateOption.index == index ? this.handleChangeUpdateOption(e, index) : this.HandleChangeOptionsFormCreate(e, index)}
                                        disabled={this.state.formUpdateOption.index == index ? false : true}
                                    />

                                    <div className="input-group-append">
                                        {true && (
                                            <button className="btn ui-btn-timeRomove" type="button" onClick={() => this.editOptions(option.name, index)}>
                                                <i className="fas fa-pencil-alt icon-remove-option ui-showFormatCategories-iconTime" style={{ fontSize: "20px" }}></i>
                                            </button>
                                        )}

                                        {options.length >= 2 && (
                                            <button className="btn ui-btn-timeRomove" type="button" onClick={() => this.removeArray(index)}>
                                                <i className="fas fa-times icon-remove-option ui-showFormatCategories-iconTime" style={{ fontSize: "20px" }}></i>
                                            </button>
                                        )}

                                        {this.state.showCheckBoxQuestion && (
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    {this.getCheckBox(options, index)}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {this.state.formUpdateOption.index == index && (
                                    <React.Fragment>
                                        <a
                                            className="link-add"
                                            onClick={() => this.handleClickUpdateOption()}
                                        >
                                            Actualizar
                                        </a>

                                        <a
                                            className="link-remove btn-outline-danger"
                                            onClick={() => this.cancelEditOptions(index)}
                                        >
                                            Cancelar
                                        </a>
                                    </React.Fragment>
                                )}
                            </div>
                        </React.Fragment>
                    ))}

                </div>
                // <p>Arreglar la vista en horizontal</p>
            )
        }
    }

    cancelEditOptions = (index) => {
        this.setState({
            formUpdateOption: {
                name: "",
                index: null
            },

            errorValueOptions: true,
            ErrorValuesOptions: true,
            errorCommaCheckbox: true
        })
    }

    selectValues = (value) => {
        const monts = [
            { name: "Enero", value: "Enero" },
            { name: "Febrero", value: "Febrero" },
            { name: "Marzo", value: "Marzo" },
            { name: "Abril", value: "Abril" },
            { name: "Mayo", value: "Mayo" },
            { name: "Junio", value: "Junio" },
            { name: "Julio", value: "Julio" },
            { name: "Agosto", value: "Agosto" },
            { name: "Septiembre", value: "Septiembre" },
            { name: "Octubre", value: "Octubre" },
            { name: "Noviembre", value: "Noviembre" },
            { name: "Diciembre", value: "Diciembre" }
        ]

        const genders = [
            { name: "Femenino", value: "Femenino" },
            { name: "Masculino", value: "Masculino" },
            { name: "Indeterminado", value: "Indeterminado" },
        ]

        console.log("value", value);

        Swal.fire({
            title: "¿Estas seguro que quieres generar estos datos?",
            text: `¡Los valores de ${value} serán añadidos!`,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Añadir valores",
            cancelButtonText: "Cancelar"
        }).then((result, e) => {


            if (result.value) {


                if (this.state.question_id) {

                    if (value == "meses") {
                        this.setState({
                            formUpdateQuestion: {
                                ...this.state.formUpdateQuestion,
                                options: monts
                            },

                            //selectValues: monts
                        })
                    } else {
                        this.setState({
                            formUpdateQuestion: {
                                ...this.state.formUpdateQuestion,
                                options: genders
                            },

                            //selectValues: genders
                        })
                    }
                } else {

                    if (value == "meses") {
                        this.setState({
                            formQuestion: {
                                ...this.state.formQuestion,
                                options: monts
                            },

                            selectValues: monts
                        })
                    } else {
                        this.setState({
                            formQuestion: {
                                ...this.state.formQuestion,
                                options: genders
                            },

                            selectValues: genders
                        })
                    }
                }
            }
        });
    }

    getOptions = (add_array, removeArray, handleChangeOptions) => {
        const options = this.state.question_id ? this.state.formUpdateQuestion.options : this.state.formQuestion.options
        const form = this.state.question_id ? this.state.formUpdateQuestion : this.state.formQuestion

        return (
            <React.Fragment>
                <div className="row">

                    <div className="col-md-6">
                        <input
                            type="text"
                            name="name"
                            onBlur={(e) => this.HandleChangeFormOptionsBlur(e, form.question_type)}
                            onChange={(e) => this.HandleChangeFormOptions(e, form.question_type)}
                            // style={{ width: "490px" }}
                            className={`form form-control ${!this.state.ErrorValuesOptions && this.state.formAddOptions.name == "" ? "error-class" : ""}`}
                            value={this.state.formAddOptions.name}
                            placeholder={`Opción ${options.length == 0 ? "1" : options.length + 1}`}
                        />
                    </div>

                    <div className="col-md-3">
                        <select
                            className="form form-control"
                            onChange={(e) => this.selectValues(e.target.value)}
                        >
                            <option value="">Valores predeterminados</option>
                            <option value="meses">Meses</option>
                            <option value="generos">Generos</option>
                        </select>
                    </div>

                    <div className="col-md-3">
                        {((this.state.formUpdateQuestion.has_default_value || this.state.formQuestion.has_default_value) && this.state.question_id) && (
                            <select
                                name="default_value_text"
                                className="form form-control"
                                onBlur={this.state.question_id ? this.HadleClickUpdateQuestion : console.log("a")}
                                value={this.state.question_id ? this.state.formUpdateQuestion.default_value_text : this.state.formQuestion.default_value_text}
                                onChange={this.state.question_id ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                            >
                                <option value="">Seleccione el valor por defecto</option>
                                {options.map((item) => (
                                    <option value={item.name}>{item.name}</option>
                                ))}
                            </select>
                        )}
                    </div>

                    <div className="col-md-6">

                        {form.question_type != "answer_select" && (
                            <select
                                name="align_item"
                                className="form form-control mt-3"
                                onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                                value={this.state.question_id != "" ? this.state.formUpdateQuestion.align_item : this.state.formQuestion.align_item}
                                onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                            >
                                <option value="vertical">Vertical</option>
                                <option value="horizontal">Horizontal</option>
                            </select>
                        )}

                        <a
                            className="link-add"
                            onClick={() => this.addArray()}
                        >
                            Agregar
                        </a>

                        <a
                            className="link-remove btn-outline-danger"
                            onClick={() => this.clearValues()}
                        >
                            Cancelar
                        </a>
                    </div>

                    <div className={`col-md-12 mt-3`}>
                        {this.state.question_id ? (
                            <React.Fragment>
                                {this.state.formUpdateQuestion.align_item == "vertical" ? (
                                    <React.Fragment>
                                        {this.renderViewOptions(handleChangeOptions, "vertical")}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {this.renderViewOptions(handleChangeOptions, "horizontal")}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {this.state.formQuestion.align_item == "vertical" ? (
                                    <React.Fragment>
                                        {this.renderViewOptions(handleChangeOptions, "vertical")}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {this.renderViewOptions(handleChangeOptions, "horizontal")}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </div>

                    {(!this.state.errorValueOptions || !this.state.ErrorValuesOptions) && (
                        <div className="col-md-6">
                            <div className="alert alert-danger mt-2 text-center" role="alert">
                                {options.length == 0 ? (
                                    <b>¡Debe de haber almenos una opcion para poder guardar!</b>
                                ) : (
                                    <b>{"¡No se permiten opciones con el mismo nombre!"}</b>
                                )}
                            </div>
                        </div>
                    )}
                    {(!this.state.errorCommaCheckbox) && (
                        <div className="col-md-6">
                            <div className="alert alert-danger mt-2 text-center" role="alert">
                                <b>¡ No puedes ingresar una coma "," en las opciones !</b>
                            </div>
                        </div>
                    )}

                </div>
            </React.Fragment>
        )
    }

    getInputs = (name, array) => {
        if (name == "Respuesta breve") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className={`col-md-6`}>
                            <input
                                type="text"
                                className="form form-control imput-options-disabled"
                                disabled
                                placeholder={"Texto de respuesta breve"}
                            />
                        </div>

                        <div className="col-md-5">
                            {(this.state.formUpdateQuestion.has_default_value || this.state.formQuestion.has_default_value) && (
                                <React.Fragment>
                                    <div className="col-md-12 mb-3">
                                        <input
                                            type="text"
                                            className={`form form-control imput-options-disabled ui-show-formats-formatos-default_values`}
                                            name="default_value_text"
                                            onBlur={this.state.question_id ? this.HadleClickUpdateQuestion : console.log("a")}
                                            value={this.state.question_id ? this.state.formUpdateQuestion.default_value_text : this.state.formQuestion.default_value_text}
                                            onChange={this.handleChangeDefaultValueText}
                                            placeholder={"Valor por defecto"}
                                        />
                                    </div>

                                    <div className={`col-md-12`}>
                                        <select
                                            className={`form form-control`}
                                            name="special_value"
                                            onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                                            value={this.state.question_id != "" ? this.state.formUpdateQuestion.special_value : this.state.formQuestion.special_value}
                                            onChange={this.handleChangeDefaultSelect}
                                        >
                                            <option value="">Valor por defecto especial</option>
                                            <option value="ip">Dirección IP</option>
                                            <option value="movil_information">Informacion del dispositivo</option>
                                        </select>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>

                        {this.state.showCheckBoxQuestion && (
                            <React.Fragment>
                                <div className={`col-md-6`}>
                                    <input
                                        type="text"
                                        className="form form-control"
                                        name="value_compare"
                                        onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                                        value={this.state.question_id != "" ? this.state.formUpdateQuestion.value_compare : this.state.formQuestion.value_compare}
                                        onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                        placeholder={"Respuesta correcta"}
                                    />
                                </div>
                            </React.Fragment>
                        )}

                        {(this.state.formUpdateQuestion.is_currency || this.state.formQuestion.is_currency) && (
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className={`form form-control imput-options-disabled ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.answer_formula == "" ? "error-class" : ""}`}
                                    name="answer_formula"
                                    style={{ marginTop: "29px" }}
                                    onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                                    value={this.state.question_id != "" ? this.state.formUpdateQuestion.answer_formula : this.state.formQuestion.answer_formula}
                                    onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                    placeholder={"Copia la formula"}
                                />

                                <div className="mt-3 mb-2 row">
                                    <div className="col-md-6 mt-1">
                                        <select
                                            onChange={(e) => this.HandleChangeQuestionFormula(e)}
                                            name="name"
                                            className="mr-2 form form-control"

                                            value={this.state.formOperation.name}
                                        >
                                            <option value="">seleccione el alias</option>
                                            {this.state.question_alias.filter(x => x.type == "answer_date" || x.type == "answer_hour" || x.type == "answer_formula" || x.type == "answer_integer").map((question) => (
                                                <option value={`{${question.label}}`}>{`{${question.label}}`}</option>
                                            ))}answer_formula answer_date answer_hour answer_formula
                                        </select>
                                    </div>
                                </div>

                            </div>
                        )}

                        <div className={`col-md-2 mt-3`}>
                            <div className="custom-control custom-switch">
                                <input type="checkbox" onChange={(e) => this.handleChangeStateQuestionNumber(e)} className="custom-control-input" id={`customCheckboxStateNumber`} checked={this.state.question_id ? this.state.formUpdateQuestion.is_currency : this.state.formQuestion.is_currency} />
                                <label className="custom-control-label" htmlFor={`customCheckboxStateNumber`}>Concatenar</label>
                            </div>
                        </div>

                        <div className={`col-md-4 mt-3`}>
                            <div className="custom-control custom-switch">
                                <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "has_default_value")} className="custom-control-input" id={`customCheckboxDefaultValue`} checked={this.state.question_id ? this.state.formUpdateQuestion.has_default_value : this.state.formQuestion.has_default_value} />
                                <label className="custom-control-label" htmlFor={`customCheckboxDefaultValue`}>Añadir valor por defecto</label>
                            </div>
                        </div>

                    </div>
                </React.Fragment>
            )

        } else if (name == "Párrafo") {
            return (
                <React.Fragment>
                    <div className={`col-md-12 pl-0`}>
                        <textarea
                            className="form form-control imput-options-disabled"
                            disabled
                            rows="4"
                            placeholder={"Texto de respuesta largo"}
                        />

                        {(this.state.formUpdateQuestion.has_default_value || this.state.formQuestion.has_default_value) && (
                            <input
                                type="text"
                                className={`form form-control imput-options-disabled`}
                                name="default_value_text"
                                style={{ marginTop: "29px" }}
                                onBlur={this.state.question_id ? this.HadleClickUpdateQuestion : console.log("a")}
                                value={this.state.question_id ? this.state.formUpdateQuestion.default_value_text : this.state.formQuestion.default_value_text}
                                onChange={this.state.question_id ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                placeholder={"Valor por defecto"}
                            />
                        )}

                        <div className="custom-control custom-switch mt-3">
                            <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "has_default_value")} className="custom-control-input" id={`customCheckboxDefaultValue`} checked={this.state.question_id ? this.state.formUpdateQuestion.has_default_value : this.state.formQuestion.has_default_value} />
                            <label className="custom-control-label" htmlFor={`customCheckboxDefaultValue`}>Añadir valor por defecto</label>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (name == "Link") {
            return (
                <div className={`col-md-7 pl-0`}>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        disabled
                        // style={{ width: "490px" }}
                        placeholder={"Url"}
                    />
                </div>
            )

        } else if (name == "CE10") {
            return (
                <div className={`col-md-7 pl-0`}>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        disabled
                        placeholder={"CE10"}
                    />
                </div>
            )

        } else if (name == "Visor PDF") {
            const value = this.state.question_id ? this.state.formUpdateQuestion.has_default_value : this.state.formQuestion.has_default_value

            return (
                <div className="row">
                    <div className={`col-md-12 mb-3`}>
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => this.handleChangeStateHasDefaultValue(e, "has_default_value")} className="custom-control-input" id={`customCheckboxDefaultValue`} checked={this.state.question_id ? this.state.formUpdateQuestion.has_default_value : this.state.formQuestion.has_default_value} />
                            <label className="custom-control-label" htmlFor={`customCheckboxDefaultValue`}>¿Url estatica?</label>
                        </div>
                    </div>

                    {!value && (
                        <div className="col-md-6">
                            <input
                                type="hidden"
                                name="format_pdf_id"
                                value={this.state.selectedOptionFormatPdf.format_pdf_id}
                            />
                            <Select
                                onChange={this.handleChangeAutocompleteFormatPdf}
                                options={this.props.format_pdfs}
                                autoFocus={false}
                                className={`link-form`}
                                value={this.state.selectedOptionFormatPdf}
                            />
                        </div>
                    )}

                    {value && (
                        <div className="col-md-6">
                            <input
                                type="text"
                                name="default_value_text"
                                className={`form form-control`}
                                onBlur={this.state.question_id ? this.HadleClickUpdateQuestion : console.log("a")}
                                onChange={this.state.question_id ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                value={this.state.question_id ? this.state.formUpdateQuestion.default_value_text : this.state.formQuestion.default_value_text}
                                placeholder={`Url del pdf externo`}
                            />
                        </div>
                    )}

                </div>
            )

        } else if (name == "Email") {
            return (
                <div className={`col-md-7 pl-0`}>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        disabled
                        placeholder={"Email"}
                    />
                </div>
            )

        } else if (name == "Campo numérico") {
            const form = this.state.question_id ? this.state.formUpdateQuestion : this.state.formQuestion

            return (
                <React.Fragment>

                    <div className="row">
                        <div className="col-md-7">
                            <input
                                type="number"
                                style={{ marginTop: "29px" }}
                                className="form form-control imput-options-disabled"
                                disabled
                                placeholder={"Campo numerico (1234..)"}
                            />
                        </div>

                        <div className="col-md-3 p-0 ui-show-formats-formatos-showDeciamls">
                            <label>Decimales para mostrar</label>
                            <input
                                type="number"
                                className={`form form-control ${!this.state.ErrorValues && form.decimal_count_show == "" ? "error-class" : ""}`}
                                name="decimal_count_show"
                                onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                                value={this.state.question_id != "" ? this.state.formUpdateQuestion.decimal_count_show : this.state.formQuestion.decimal_count_show}
                                onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                placeholder={""}
                            />
                        </div>

                        {/*                                 
                        <div className="col-md-3">
                            <label>Decimales para calcular</label>
                                <input
                                    type="number"
                                    className={`form form-control`}
                                    name="decimal_count"
                                    onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                                    value={this.state.question_id != "" ? this.state.formUpdateQuestion.decimal_count : this.state.formQuestion.decimal_count}
                                    onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                    placeholder={"Decimales"}
                                /> 
                        </div>
*/}

                        {(this.state.formUpdateQuestion.has_default_value || this.state.formQuestion.has_default_value) && (
                            <React.Fragment>
                                <div className="col-md-6">
                                    <input
                                        type="number"
                                        className={`form form-control imput-options-disabled`}
                                        name="default_value_number"
                                        style={{ marginTop: "29px" }}
                                        onBlur={this.state.question_id ? this.HadleClickUpdateQuestion : console.log("a")}
                                        value={this.state.question_id ? this.state.formUpdateQuestion.default_value_number : this.state.formQuestion.default_value_number}
                                        onChange={this.state.question_id ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                        placeholder={"Valor por defecto"}
                                    />
                                </div>
                            </React.Fragment>
                        )}

                        <div className={`col-md-4 mt-3`}>
                            <div className="custom-control custom-switch">
                                <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "has_default_value")} className="custom-control-input" id={`customCheckboxDefaultValue`} checked={this.state.question_id ? this.state.formUpdateQuestion.has_default_value : this.state.formQuestion.has_default_value} />
                                <label className="custom-control-label" htmlFor={`customCheckboxDefaultValue`}>Añadir valor por defecto</label>
                            </div>
                        </div>


                        <div className="col-md-3 mt-3">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" onChange={(e) => this.handleChangeStateQuestionNumber(e)} className="custom-control-input" id={`customCheckboxStateNumber`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_currency : this.state.formQuestion.is_currency} />
                                <label className="custom-control-label" htmlFor={`customCheckboxStateNumber`}>Es moneda</label>
                            </div>
                        </div>


                    </div>
                </React.Fragment>
            )

        } else if (name == "Geolocalización") {
            return (
                <div className={`col-md-8 pl-0`}>
                    <button
                        disabled="disabled"
                        className="btn btn-light"
                    >
                        <i className="fas fa-map-marker-alt mr-2"></i> Geolocalización
                    </button>
                </div>
            )

        } else if (name == "Fecha / Hora (Automática)") {
            return (
                <div className={`col-md-7 pl-0`}>
                    <button
                        disabled="disabled"
                        className="btn btn-light"
                        style={{ textAlign: "left" }}
                    >
                        <i className="fas fa-clock mr-2"></i> Fecha / Hora (Automática)
                    </button>
                </div>
            )

        } else if (name == "Hora") {
            return (
                <div className={`col-md-7 pl-0`}>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        disabled
                        // style={{ width: "490px" }}
                        placeholder={"Hora"}
                    />

                    <div className="custom-control custom-switch mt-3">
                        <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "has_default_value")} className="custom-control-input" id={`customCheckboxDefaultValue`} checked={this.state.question_id ? this.state.formUpdateQuestion.has_default_value : this.state.formQuestion.has_default_value} />
                        <label className="custom-control-label" htmlFor={`customCheckboxDefaultValue`}>Añadir valor por defecto</label>

                    </div>
                    {(this.state.formUpdateQuestion.has_default_value || this.state.formQuestion.has_default_value) && (
                        <React.Fragment>

                            <div className="col-md-12 mt-3">
                                <div>Si dejas la hora vacía, creará la hora actual por defecto.</div>

                                <TimeField
                                    onBlur={this.state.question_id ? this.HadleClickUpdateQuestion : console.log("a")}
                                    value={this.state.question_id ? this.state.formUpdateQuestion.default_value_text : this.state.formQuestion.default_value_text}
                                    onChange={this.handleChangeDefaultValueText}
                                    input={
                                        <input
                                            type="text"
                                            className={`form form-control imput-options-disabled ui-show-formats-formatos-default_values`}
                                            name="default_value_text"
                                            placeholder={"Valor por defecto"}
                                        />
                                    }
                                />
                            </div>
                        </React.Fragment>
                    )}

                </div>
            )

        }
        else if (name == "Campo dinámico") {
            return (
                <div className={`col-md-8 pl-0`}>
                    <button
                        disabled="disabled"
                        className="btn btn-light"
                    >
                        Configurar campo
                    </button>
                </div>
            )

        } else if (name == "Fórmula") {
            return (
                <React.Fragment>
                    <div className="row">

                        <div className="col-md-4">
                            <label>Decimales para mostrar</label>
                            <input
                                type="number"
                                className={`form form-control ${this.state.question_id != "" ? (this.state.formUpdateQuestion.decimal_count_show == "" ? "error-class" : "") : (this.state.formQuestion.decimal_count_show == "" ? "error-class" : "")}`}
                                name="decimal_count_show"
                                onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                                value={this.state.question_id != "" ? this.state.formUpdateQuestion.decimal_count_show : this.state.formQuestion.decimal_count_show}
                                onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                placeholder={""}
                            />
                        </div>

                        <div className="col-md-4">
                            {/*  <label>Decimales para calcular</label>
                            <input
                                type="number"
                                className={`form form-control`}
                                name="decimal_count"
                                onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                                value={this.state.question_id != "" ? this.state.formUpdateQuestion.decimal_count : this.state.formQuestion.decimal_count}
                                onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                placeholder={"Decimales"}
                            /> */}
                        </div>

                        <div className="col-md-4 mt-3">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" onChange={(e) => this.handleChangeStateQuestionNumber(e)} className="custom-control-input" id={`customCheckboxStateNumber`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_currency : this.state.formQuestion.is_currency} />
                                <label className="custom-control-label" htmlFor={`customCheckboxStateNumber`}>Es moneda</label>
                            </div>
                        </div>


                        <div className="col-md-12">
                            <input
                                type="text"
                                className={`form form-control imput-options-disabled ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.answer_formula == "" ? "error-class" : ""}`}
                                name="answer_formula"
                                style={{ marginTop: "29px" }}
                                onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                                value={this.state.question_id != "" ? this.state.formUpdateQuestion.answer_formula : this.state.formQuestion.answer_formula}
                                onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                placeholder={"Copia la formula"}
                            />

                            <div className="mt-3 mb-2 row">
                                <div className="col-md-6 mt-1">
                                    <select
                                        onChange={(e) => this.HandleChangeQuestionFormula(e)}
                                        name="name"
                                        className="mr-2 form form-control"

                                        value={this.state.formOperation.name}
                                    >
                                        <option value="">seleccione el alias</option>
                                        {this.state.question_alias.filter(x => x.type == "answer_date" || x.type == "answer_hour" || x.type == "answer_formula" || x.type == "answer_integer" || x.type == "dynamic_field").map((question) => (
                                            <option value={`{${question.label}}`}>{`{${question.label}}`}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-md-6 mt-1">
                                    <select
                                        onChange={(e) => this.HandleChangeQuestionFormula(e)}
                                        name="operation"
                                        className="form form-control"
                                        value={this.state.formOperation.operation}

                                    >
                                        <option value="">Operador</option>
                                        <option value="+">+</option>
                                        <option value="-">-</option>
                                        <option value="*">*</option>
                                        <option value="/">/</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        {this.state.question_id && (
                            <div className="col-md-12 mt-1">
                                <button className="btn btn-outline-primary" onClick={() => this.testQuestionFormula()}>Ejecutar prueba</button>
                            </div>
                        )}



                        {/*<div className="col-md-12">
                            <br />
                            <p>Ejemplo 1: {`{p.S1_P1} + {p.S1_P2}`} suma entre la pregunta 1 de la sección 1 y la pregunta 2 de la sección 1  </p>
                            <p>Ejemplo 2: {`{p.S1_P1} * {p.S2_P1}`} multiplicación entre la pregunta 1 de la sección 1 y pregunta 1 de la sección 2  </p>
                            <p>Cada pregunta de la cual depende la formula se conforma así {`{p.S1_P1}`}</p>
                            <p>Debe ir entre llaves {`{}`}</p>
                            <p>siempre debe ir {`{p.}`}</p>
                            <p>Luego la S y el numero de la sección{`{S1}`}</p>
                            <p>Luego un guion bajo{`{ _ }`}</p>
                            <p>Luego la P y el numero de la pregunta{`{P1}`}</p>
                        </div>*/}

                    </div>

                </React.Fragment>
            )

        } else if (name == "Código") {
            return (

                <div className="row">
                    <div className="col-md-7">
                        <input
                            type="text"
                            className={`form form-control imput-options-disabled ${this.state.ErrorValuesCreateQuestion == false ? "error-class" : ""}ui-showFormatCategoies-fieldCode`}
                            name="code_format"
                            style={{ width: "88%", display: "initial" }}
                            value={this.state.question_id != "" ? this.state.formUpdateQuestion.code_format : this.state.formQuestion.code_format}
                            onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                            onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                            placeholder={"Código"}
                        />
                        <i className="fas fa-info-circle icon-code ui-showFormatCategories-iconCode" style={{ color: '#16aafb' }}></i>
                        {!this.state.ErrorValuesCreateQuestion && (
                            <span style={{ color: "red" }}>El formato del código es invalido debe tener almenos {`{cons}`}</span>
                        )}
                    </div>

                    <div className="col-md-5">
                        {true && (
                            <p style={{ color: "#17a3dc", fontSize: "13px" }}> Debes ingresar {`{cons}`} donde quieras poner el consecutivo y {`{año}`} donde quieras los dos ultimos digitos del año Ej: {`{cons}-FMTO-{año}`}  {`1-FMTO-20`} </p>
                        )}
                    </div>

                    <div className="col-md-12 mt-2 mb-2">
                        <input
                            type="hidden"
                            name="formatos"
                            value={this.state.selectedOptionMulti.formatos}
                        />
                        <Select
                            onChange={this.handleChangeAutocompleteMulti}
                            options={this.state.all_formats}
                            isMulti
                            closeMenuOnSelect={false}
                            autoFocus={false}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione los formatos"
                            name="formatos"
                            defaultValue={this.state.question_id ? this.state.formUpdateQuestion.formatos : []}
                        />
                    </div>

                    <div className="col-md-3 ui-show-formats-formatos-showDeciamls">
                        <label>Ceros adelante</label>
                        <input
                            type="number"
                            className={`form form-control`}
                            name="decimal_count_show"
                            onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                            value={this.state.question_id != "" ? this.state.formUpdateQuestion.decimal_count_show : this.state.formQuestion.decimal_count_show}
                            onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                            placeholder={""}
                        />
                    </div>

                    {(this.state.formUpdateQuestion.has_default_value || this.state.formQuestion.has_default_value) && (
                        <React.Fragment>
                            <div className="col-md-6">
                                <input
                                    type="number"
                                    className={`form form-control imput-options-disabled`}
                                    name="default_value_number"
                                    style={{ marginTop: "29px" }}
                                    onBlur={this.state.question_id ? this.HadleClickUpdateQuestion : console.log("a")}
                                    value={this.state.question_id ? this.state.formUpdateQuestion.default_value_number : this.state.formQuestion.default_value_number}
                                    onChange={this.state.question_id ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                    placeholder={"Valor por defecto"}
                                />
                            </div>
                        </React.Fragment>
                    )}

                    <div className={`col-md-4 mt-3`}>
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "has_default_value")} className="custom-control-input" id={`customCheckboxDefaultValue`} checked={this.state.question_id ? this.state.formUpdateQuestion.has_default_value : this.state.formQuestion.has_default_value} />
                            <label className="custom-control-label" htmlFor={`customCheckboxDefaultValue`}>Añadir valor por defecto</label>
                        </div>
                    </div>

                </div>
            )

        } else if (name == "Subformulario") {
            return (
                <React.Fragment>
                    {!this.state.question_id ? (
                        <div className="row">

                            <div className="col-md-6">
                                <input
                                    type="hidden"
                                    name="format_id"
                                    value={this.state.selectedOption.format_relation_id}
                                />
                                <label htmlFor="">Formato</label>
                                <Select
                                    onChange={this.handleChangeAutocompleteSubForm}
                                    options={this.state.formatos}
                                    autoFocus={false}
                                    className={`link-form ${!this.state.ErrorValuesCreateQuestion && this.state.formQuestion.format_relation_id == "" ? "error-class" : ""}`}
                                    value={this.state.selectedOption}
                                />
                            </div>

                            {this.state.questions.length >= 1 && (
                                <div className="col-md-6">
                                    <input
                                        type="hidden"
                                        name="question_format_id"
                                        value={this.state.selectedOptionQuestion.question_format_id}
                                    />
                                    <label htmlFor="">Ingresa la pregunta que quieres que aparezca en el hijo</label>
                                    <Select
                                        onChange={this.handleChangeAutocompleteQuestion}
                                        options={this.state.questions}
                                        autoFocus={false}
                                        className={`link- ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.question_format_id == "" ? "error-class" : ""}`}
                                        value={this.state.selectedOptionQuestion}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>   <div className="col-md-6 mb-4">
                            <input
                                type="hidden"
                                name="format_id"
                                value={this.state.selectedOption.format_relation_id}
                            />
                            <label htmlFor="">Formato</label>
                            <Select
                                onChange={this.handleChangeAutocomplete}
                                options={this.state.formatos}
                                autoFocus={false}
                                className={`link-form ${!this.state.ErrorValuesCreateQuestion && this.state.formQuestion.format_relation_id == "" ? "error-class" : ""}`}
                                value={this.state.selectedOption}
                                isDisabled={true}
                            />
                        </div>
                            <div className='alert alert-warning'>Si necesitas modificar el formato asociado al Subformulario, elimina este y crea uno nuevo. </div>
                        </div>
                    )}
                </React.Fragment>
            )

        } else if (name == "Formato") {
            return (
                <React.Fragment>
                    <div className="row">


                        <div className="col-md-6">
                            <input
                                type="hidden"
                                name="format_id"
                                value={this.state.selectedOption.format_relation_id}
                            />
                            <label htmlFor="">Formato</label>
                            <Select
                                onChange={this.handleChangeAutocomplete}
                                options={this.state.formatos}
                                autoFocus={false}
                                className={`link-form ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.format_relation_id == "" ? "error-class" : ""}`}
                                value={this.state.selectedOption}
                                isDisabled={this.state.question_id}
                            />
                        </div>

                        {this.state.questions.length >= 1 && (
                            <div className="col-md-6">
                                <input
                                    type="hidden"
                                    name="question_format_id"
                                    value={this.state.selectedOptionQuestion.question_format_id}
                                />
                                <label htmlFor="">Campo para mostrar</label>
                                <Select
                                    onChange={this.handleChangeAutocompleteQuestion}
                                    options={this.state.questions}
                                    autoFocus={false}
                                    className={`link- ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.question_format_id == "" ? "error-class" : ""}`}
                                    value={this.state.selectedOptionQuestion}
                                />
                            </div>
                        )}

                        <div className="col-md-12 mt-3 mb-3">
                            <div className="row">

                                <div className="col-md-3">
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "is_currency")} className="custom-control-input" id={`customCheckboxState`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_currency : this.state.formQuestion.is_currency} />
                                        <label className="custom-control-label" htmlFor={`customCheckboxState`}>¿Tipo texto?</label>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "add_new")} className="custom-control-input" id={`customCheckboxStateNew`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.add_new : this.state.formQuestion.add_new} />
                                        <label className="custom-control-label" htmlFor={`customCheckboxStateNew`}>Puede Añadir un nuevo registro</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 ui-show-formats-formatos-showDeciamls">
                            <label>Numero de letras para buscar</label>
                            <input
                                type="number"
                                className={`form form-control ${this.state.question_id != "" ? (this.state.formUpdateQuestion.decimal_count_show == "" ? "error-class" : "") : (this.state.formQuestion.decimal_count_show == "" ? "error-class" : "")}`}
                                name="decimal_count_show"
                                onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                                value={this.state.question_id != "" ? this.state.formUpdateQuestion.decimal_count_show : this.state.formQuestion.decimal_count_show}
                                onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                placeholder={""}
                            />
                        </div>
                    </div>

                </React.Fragment>
            )

        } else if (name == "Asociación entre campos") {
            return (
                <React.Fragment>
                    <div className="col-md-4 mt-3">
                        <input
                            type="hidden"
                            name="format_id"
                            value={this.state.selectedOption.format_relation_id}
                        />
                        <label htmlFor="">Formato</label>
                        <Select
                            onChange={this.handleChangeAutocomplete}
                            options={this.state.all_formats}
                            autoFocus={false}
                            className={`link-form ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.format_relation_id == "" ? "error-class" : ""}`}
                            value={this.state.selectedOption}
                        />
                    </div>

                    {this.state.questions.length >= 1 && (
                        <div className="col-md-4 mt-3">
                            <input
                                type="hidden"
                                name="question_format_id"
                                value={this.state.selectedOptionQuestion.question_format_id}
                            />
                            <label htmlFor="">Pregunta</label>
                            <Select
                                onChange={this.handleChangeAutocompleteQuestion}
                                options={this.state.questions}
                                autoFocus={false}
                                className={`link- ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.question_format_id == "" ? "error-class" : ""}`}
                                value={this.state.selectedOptionQuestion}
                            />
                        </div>
                    )}

                    <div className="col-md-4 mt-3">
                        <label htmlFor="">Formula</label>
                        <select
                            className="form form-control"
                            name="answer_formula"
                            value={this.state.question_id != "" ? this.state.formUpdateQuestion.answer_formula : this.state.formQuestion.answer_formula}
                            onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                        >
                            <option value="">Seleccione una opción</option>
                            <option value="Suma">Suma</option>
                            <option value="Maximo">Máximo</option>
                            <option value="Minimo">Mínimo</option>
                            <option value="Contador">Cantidad de registros</option>
                            <option value="Valor">Mismo Valor(Ingra el mismo valor del campo que agregaste)</option>
                            {/*  <option value="Promedio">Promedio</option> */}
                        </select>
                    </div>



                    {/*<div className="col-md-12 mt-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e)} className="custom-control-input" id={`customCheckboxState`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_multiple : this.state.formQuestion.is_multiple} />
                            <label className="custom-control-label" htmlFor={`customCheckboxState`}>¿Es multiple?</label>
                        </div>
                    </div>*/}

                </React.Fragment>
            )

        } else if (name == "Lista de usuarios") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className={`col-md-7`}>
                            <select
                                className="form form-control imput-options-disabled"
                                // style={{ width: "490px" }}
                                disabled
                            >
                                <option value="">Selección de usuarios</option>
                            </select>
                        </div>

                        <div className="col-md-4 text-right ui-show-formats-formatos-select-user-checklist">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "is_multiple")} className="custom-control-input" id={`customCheckboxState`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_multiple : this.state.formQuestion.is_multiple} />
                                <label className="custom-control-label" htmlFor={`customCheckboxState`}>Elige varias opciones</label>
                            </div>
                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "has_default_value")} className="custom-control-input" id={`customCheckboxDefaultValue`} checked={this.state.question_id ? this.state.formUpdateQuestion.has_default_value : this.state.formQuestion.has_default_value} />
                                <label className="custom-control-label" htmlFor={`customCheckboxDefaultValue`}>Añadir valor por defecto</label>
                                <div>(Se asignara el usuario actual)</div>
                            </div>

                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (name == "Lista de procesos") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className={`col-md-7`}>
                            <select
                                className="form form-control imput-options-disabled"
                                // style={{ width: "490px" }}
                                disabled
                            >
                                <option value="">Selección de procesos</option>
                            </select>
                        </div>

                        <div className="col-md-4 text-right">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "is_multiple")} className="custom-control-input" id={`customCheckboxState`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_multiple : this.state.formQuestion.is_multiple} />
                                <label className="custom-control-label" htmlFor={`customCheckboxState`}>Elige varias opciones</label>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (name == "Lista de áreas específicas") {
            return (
                <div className="col-md-8">
                    <select
                        className="form form-control imput-options-disabled"
                        disabled
                    >
                        <option value="">Selección de áreas específicas</option>
                    </select>
                </div>
            )

        } else if (name == "Lista de cargos") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-7">
                            <select
                                className="form form-control imput-options-disabled"
                                // style={{ width: "490px" }}
                                disabled
                            >
                                <option value="">Selección de cargos</option>
                            </select>
                        </div>

                        <div className="col-md-4 text-right">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "is_multiple")} className="custom-control-input" id={`customCheckboxState`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_multiple : this.state.formQuestion.is_multiple} />
                                <label className="custom-control-label" htmlFor={`customCheckboxState`}>Elige varias opciones</label>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (name == "Lista de eventos relacionados") {
            return (
                <div className="col-md-7">
                    <select
                        className="form form-control imput-options-disabled"
                        disabled
                    >
                        <option value="">Selección de eventos relacionados</option>
                    </select>
                </div>
            )

        } else if (name == "Fecha") {
            return (

                <div className="row mb-3">
                    <div className={`col-md-${this.state.formQuestion.is_currency || this.state.formUpdateQuestion.is_currency ? "6" : "7"}`}>
                        <input
                            type="text"
                            className={`form form-control`}
                            style={{ width: this.state.formQuestion.is_currency || this.state.formUpdateQuestion.is_currency ? "100%" : "col-md-7", textAlign: "left" }}
                            disabled
                            value={this.fecha}
                        />
                    </div>

                    {(this.state.formQuestion.is_currency || this.state.formUpdateQuestion.is_currency) && (
                        <React.Fragment>
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className={`form form-control imput-options-disabled ${this.state.ErrorValuesCreateQuestion == false && this.state.formQuestion.answer_formula == "" ? "error-class" : ""}`}
                                    name="answer_formula"
                                    onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                                    value={this.state.question_id != "" ? this.state.formUpdateQuestion.answer_formula : this.state.formQuestion.answer_formula}
                                    onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                                    placeholder={"Copia la formula matematica"}
                                />
                            </div>


                            <div className="col-md-6 mt-3">
                                <select
                                    onChange={(e) => this.HandleChangeQuestionFormula(e)}
                                    name="name"
                                    className="mr-2 form form-control"

                                    value={this.state.formOperation.name}
                                >
                                    <option value="">seleccione el alias</option>
                                    {this.state.question_alias.filter(x => x.type == "answer_date" || x.type == "answer_hour" || x.type == "answer_formula" || x.type == "answer_integer").map((question) => (
                                        <option value={`{${question.label}}`}>{`{${question.label}}`}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-6 mt-3">

                                <select
                                    onChange={(e) => this.HandleChangeQuestionFormula(e)}
                                    name="operation"
                                    className="form form-control"
                                    value={this.state.formOperation.operation}

                                >
                                    <option value="">Operador</option>
                                    <option value="+">+</option>
                                    <option value="-">-</option>
                                    <option value="*">*</option>
                                    <option value="/">/</option>
                                </select>

                            </div>
                        </React.Fragment>
                    )}

                    <div className={`col-md-3 ${this.state.formQuestion.is_currency || this.state.formUpdateQuestion.is_currency ? "mt-3" : ""}`}>
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => this.handleChangeStateQuestionNumber(e)} className="custom-control-input" id={`customCheckboxStateNumber`} checked={this.state.question_id != "" ? this.state.formUpdateQuestion.is_currency : this.state.formQuestion.is_currency} />
                            <label className="custom-control-label" htmlFor={`customCheckboxStateNumber`}>Es fórmula</label>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "has_default_value")} className="custom-control-input" id={`customCheckboxDefaultValue`} checked={this.state.question_id ? this.state.formUpdateQuestion.has_default_value : this.state.formQuestion.has_default_value} />
                            <label className="custom-control-label" htmlFor={`customCheckboxDefaultValue`}>Añadir valor por defecto</label>
                            <div>(Se creara la fecha actual por defecto)</div>
                        </div>

                    </div>

                </div>
            )

        } else if (name == "Hora") {
            return (
                <div className="col-md-7 pl-0">
                    <button
                        className="btn bnt-light"
                        style={{ border: "1px solid" }}
                        disabled
                    >
                        <i className="fas fa-clock"></i> {this.fecha}
                    </button>
                </div>
            )

        } else if (name == "Carga de archivos") {
            return (
                <div className="row">
                    <div className="col-md-2">
                        <button
                            className="btn bnt-light"
                            style={{ border: "1px solid" }}
                            disabled
                        >
                            <i className="fas fa-upload mr-2"></i> Agregar archivo
                        </button>
                    </div>

                    <div className={`col-md-4`}>
                        <select
                            className={`form form-control`}
                            name="special_value"
                            onBlur={this.state.question_id != "" ? this.HadleClickUpdateQuestion : console.log("a")}
                            value={this.state.question_id != "" ? this.state.formUpdateQuestion.special_value : this.state.formQuestion.special_value}
                            onChange={this.state.question_id != "" ? this.HandleChangeUpdateAnswer : this.HandleChangeFormula}
                        >
                            <option value="">Valor por defecto especial</option>
                            <option value="all_files">Todos los archivos</option>
                            <option value="image">Imagen</option>
                            <option value="camera">Camara</option>
                        </select>
                    </div>
                </div>
            )

        } else if (name == "Casillas de verificación") {
            return (
                <React.Fragment>
                    {this.getOptions(this.HandleChangeOptions)}
                </React.Fragment>
            )

        } else if (name == "Opción múltiple") {
            return (
                <React.Fragment>
                    {this.getOptions(this.HandleChangeOptions)}
                </React.Fragment>
            )

        } else if (name == "Lista desplegable") {
            return (
                <React.Fragment>

                    {this.getOptions(this.HandleChangeOptions)}
                </React.Fragment>
            )
        } else if (name == "Firma") {
            return (
                <React.Fragment>
                    <div className="col-md-8 pl-0">
                        <button
                            disabled="disabled"
                            className="btn btn-light"
                        >
                            <i className="fas fa-file-signature mr-2"></i> Firma
                        </button>
                    </div>
                </React.Fragment>
            )
        } else if (name == "Matriz") {
            return (
                <React.Fragment>
                    {(this.state.question_id && this.state.questionMatrix.id == this.state.question_id) ? (
                        <p>asdasdasd</p>
                    ) : (
                        <div className="col-md-8 pl-0">
                            <button
                                disabled="disabled"
                                className="btn btn-light"
                            >
                                <i className="fas fa-file-signature mr-2"></i> Configuración de matrix
                            </button>
                        </div>
                    )}

                </React.Fragment>
            )
        }
    }

    getButtomQuestionStyle = (question) => {
        if (question.question_type != "answer_file" && question.question_type != "format_id" && question.question_type != "answer_subform" && question.question_type != "pdf_viewer" && question.question_type != "ce_10" && question.question_type != "answer_signature" && question.question_type != "code_format" && question.question_type != "answer_autdatetime" && question.question_type != "answer_matrix" && question.question_type != "url" && question.question_type != "answer_localization" && question.question_type != "dynamic_field" && question.question_type != "format_id") {
            return (
                <button className="link-add btn mr-0" onClick={(e) => this.toogleQuestionStyle(e, "new", question)}>
                    <UncontrolledTooltip placement='bottom' target={`ed${question.id}`}>
                        {"Estilos Dinámicos"}
                    </UncontrolledTooltip>

                    <span
                        id={`ed${question.id}`}
                        className="material-symbols-outlined ui-showFormatCategories-palette"

                        style={{ color: question.has_question_styles_value ? '#57eaaa' : 'inherit' }}

                    >
                        &#xe40a;
                    </span>
                </button>
            )
        }
    }

    getButtomQuestion = (question) => {
        if (question.question_type == "answer_string" || question.question_type == "answer_integer" || question.question_type == "answer_date" || question.question_type == "answer_hour") {
            return (
                <button className="link-add btn mr-0" onClick={(e) => this.toogleFormatSearchValue(e, "new", question)}>
                    {!isMobile && (
                        <UncontrolledTooltip placement='bottom' target={`formatSearch${question.id}`}>
                            {"Buscador de valores"}
                        </UncontrolledTooltip>
                    )}
                    <span
                        id={`formatSearch${question.id}`}
                        className="material-symbols-outlined ui-showFormatCategories-palette"
                        style={{ color: question.has_new_format_search_value ? '#57eaaa' : 'inherit' }}
                    >
                        &#xe8b6;
                    </span>

                </button>
            )
        }
    }

    getButtomAnswerDate = (question) => {
        if (question.question_type == "answer_date") {
            return (
                <button className="link-add btn mr-0" onClick={(e) => this.toogleFormatDate(e, "new", question)}>
                    <UncontrolledTooltip placement='bottom' target={`formatDate${question.id}`} >
                        {"Actualizar formato"}
                    </UncontrolledTooltip>
                    <span
                        id={`formatDate${question.id}`}
                        className="material-symbols-outlined ui-showFormatCategories-palette"

                        style={{ color: question.has_custom_format ? '#57eaaa' : 'inherit' }}
                    >
                        &#xe8b5;

                    </span>
                </button>
            )
        }
    }

    getButtomQuestionAction = (question) => {
        if (question.question_type == "answer_radio" || question.question_type == "answer_select") {
            return (
                <button className="link-add btn mr-0" onClick={(e) => this.toogleQuestionAction(e, "new", question)}>
                    <UncontrolledTooltip placement='bottom' target={`QuestionAction${question.id}`}>
                        {"Acciones de la pregunta"}
                    </UncontrolledTooltip>
                    <span
                        id={`QuestionAction${question.id}`}
                        className="material-symbols-outlined ui-showFormatCategories-palette"
                        style={{ color: question.has_new_format_search_value ? '#57eaaa' : 'inherit', paddingRight: '0.75rem' }}
                    >
                        &#xe8b6;
                    </span>

                    <span
                        id={`QuestionAction${question.id}`}
                        className="material-symbols-outlined ui-showFormatCategories-palette"

                        style={{ color: question.has_question_actions_value ? '#57eaaa' : 'inherit', paddingLeft: '0.75rem' }}
                    >
                        &#xe1bd;
                    </span>

                </button >
            )
        }
    }

    getButtomQuestionCustomFormula = (question) => {
        if (question.question_type == "answer_formula") {
            return (
                <button className="link-add btn mr-0" onClick={(e) => this.toogleQuestionCustomFormula(e, "new", question)}>
                    <UncontrolledTooltip placement='bottom' target={`QuestionCustomFormula${question.id}`}>
                        {"Acciones de la pregunta"}
                    </UncontrolledTooltip>
                    <span
                        id={`QuestionCustomFormula${question.id}`}
                        className="material-symbols-outlined ui-showFormatCategories-palette"

                        style={{ color: question.has_question_custom_formula_value ? '#57eaaa' : 'inherit' }}
                    >
                        &#xe1bd;
                    </span>

                </button >
            )
        }
    }

    getButtomQuestionMatrix = (question) => {
        if (question.question_type == "answer_matrix" && Object.keys(this.state.questionMatrix).length != 0 && this.state.questionMatrix.id == question.id) {
            return (
                <React.Fragment>
                    <i className="far fa-trash-alt icon-show-format ml-4 mr-4" style={{ color: "#df7d7d", fontSize: "19px" }} onClick={() => this.deleteQuestion(question)}></i>

                    <button
                        className="btn-shadow btn btn-info mb-3"
                        onClick={(e) => this.clearQuestionMatrixState(e)}
                    >
                        Cerrar
                    </button>
                </React.Fragment>
            )
        }
    }

    getButtomQuestionDate = (question) => {
        if (question.question_type == "answer_date") {
            return (
                <button className="link-add btn mr-0" onClick={(e) => this.toogleQuestionDate(e, "new", question)}>
                    <UncontrolledTooltip placement='bottom' target={`QuestionDate${question.id}`}>
                        {"Configurar formato de fecha"}
                    </UncontrolledTooltip>
                    <span
                        id={`QuestionDate${question.id}`}
                        className="material-symbols-outlined ui-showFormatCategories-palette"

                        style={{ color: question.has_configuration_question_date_value ? '#57eaaa' : 'inherit' }}
                    >
                        &#xe742;
                    </span>

                </button>
            )
        }
    }

    clearQuestionMatrixState = (e) => {
        e.stopPropagation();
        this.setState({
            questionMatrix: {}
        })
    }


    HandleChangeUpdateSelect = (e) => {
        fetch(`/formatos/update_select/${this.props.configuration_format.id}/${e.target.value}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    configuration_format: {
                        ...this.state.configuration_format,
                        has_taks: data.configuration_format.has_taks,
                        has_comments: data.configuration_format.has_comments,
                        send_notification: data.configuration_format.send_notification,
                        active_external_link: data.configuration_format.active_external_link,
                        has_cause_analisis: data.configuration_format.has_cause_analisis,
                        columns_format_number: data.configuration_format.columns_format_number,
                    }
                })
            });
    }


    //questions options 

    editQuestion = (question, step) => {

        let array = []
        let arrayEdit = []

        question.positions.map((item) => (
            arrayEdit.push({ label: `${item.name}`, value: `${item.id}` }),
            array.push(item.id)
        ))

        if (question.question_format_id) {
            this.LoadQuestion(question.format_relation_id, "format_id")
        }

        this.loadQuestionPositions(question.id);

        this.setState({
            formUpdateQuestion: {
                question: question.question,
                position: question.position,
                decimal_count_show: question.decimal_count_show,
                decimal_count: question.decimal_count,
                value_compare: question.value_compare,
                question_alias: question.question_alias,
                file: question.file,
                formatos: question.formatos,
                question_type: question.question_type,
                answer_formula: question.answer_formula,
                code_format: question.code_format,
                is_multiple: question.is_multiple,
                is_currency: question.is_currency,
                has_default_value: question.has_default_value,
                default_value_text: question.default_value_text,
                default_value_number: question.default_value_number,
                add_new: question.add_new,
                description: question.description,
                align_item: question.align_item,
                question_format_id: question.question_format_id,
                format_relation_id: question.format_relation_id,
                options: question.options,
                position_ids: array,
                format_pdf_id: !question.has_default_value && question.format_pdf ? question.format_pdf.id : "",
                special_value: question.special_value
            },

            question_last_id: this.state.question_last_id != "" ? (this.state.question_last_id != this.state.question_id ? question.id : this.state.question_last_id) : question.id,

            formQuestion: {
                question: "",
                question_type: "",
                answer_formula: "",
                format_relation_id: "",
                question_format_id: "",
                code_format: "",
                step_id: "",
                formatos: [],
                description: "",
                align_item: "vertical",
                decimal_count: 2,
                has_default_value: false,
                default_value_text: "",
                default_value_number: "",
                is_multiple: false,
                is_currency: false,
                options: [],
                position_ids: [],
                format_pdf_id: "",
                special_value: ""
            },

            questionMatrix: {},

            selectedOption: {
                format_relation_id: question.format_relation_id,
                label: `${question.format_relation != null ? question.format_relation.name : "Seleccione el formato"}`
            },

            selectedOptionQuestion: {
                question_format_id: question.question_format_id,
                label: `${question.question_format != null ? question.question_format.question : "Seleccione el campo"}`
            },

            selectedOptionFormatPdf: {
                format_pdf_id: !question.has_default_value && question.format_pdf ? question.format_pdf.id : "",
                label: !question.has_default_value && question.format_pdf ? question.format_pdf.name : "Seleccione el pdf"
            },

            errorValueOptions: true,
            ErrorValuesOptions: true,

            stepId: step,
            question_id: question.id,
            question_config_id: question.id,
            questionMatrix: question,
            nameBtn: this.getReturnName(question.question_type)
        }, () => {
            if (this.state.question_id != this.state.question_last_id) {
                console.log("son diferentes");
                //this.HadleClickUpdateQuestionLast();
                this.setState({ nameFileQuestionImagen: "" });
            } else {
                console.log("son iguales");
            }

            console.log("this.state.question_last_id", this.state.question_last_id);
            console.log("this.state.question_id", this.state.question_id);
            console.log("///////////////////////////////////////////////////////////");
            console.log("formUpdateQuestion", this.state.formUpdateQuestion);
            console.log("formUpdateQuestionLast", this.state.formUpdateQuestionLast);
            //this.HadleClickUpdateQuestion();
        });
    }

    loadQuestionPositions = (question_id) => {
        fetch(`/formatos/get_question_positions/${question_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.updatePosition(data.positions);
            });
    }

    HandleChangeUpdateQuestion = (e) => {
        this.setState({
            formUpdateQuestion: {
                ...this.state.formUpdateQuestion,
                [e.target.name]: e.target.value,
            },
        })
    }

    handleChangeUpdateQuestionAlias = (e) => {
        this.setState({
            formUpdateQuestion: {
                ...this.state.formUpdateQuestion,
                [e.target.name]: e.target.value.replace(/ /g, "_").normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            },
        })
    }

    handleFileQuestionImagen = (archivo) => {
        const file = archivo[0]
        const formData = new FormData();
        formData.append("file", file)
        fetch(`/formatos/update_file_question/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                console.log("data.register", data.register);
                this.props.update_question(data.register)
                this.setState({
                    nameFileQuestionImagen: file.path,
                    sizeFileQuestionImagen: file.size
                })
            })
    };

    handleClickUpdate = (question_id, type_position) => {
        fetch(`/formatos/update_position/${question_id}/${type_position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData()
                this.clearValues();
            });
    }

    updatePositionQuestion = (e, step_id) => {
        fetch(`/formatos/update_position/${this.state.question_id}/${step_id}/${e.target.value}/${this.state.formUpdateQuestion.position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.updateStateVersion(true)
                this.clearValues();
            });
    }

    //ACTUALIZA PREGUNTA
    HadleClickUpdateQuestion = (showMessage = false) => {
        fetch(`/formatos/questions/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateQuestion), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.update_question(data.register)
                this.updateStateVersion(true);

                if (showMessage == true) {
                    this.messageSuccess(data);
                    this.setState({
                        ErrorValueOptionUpdate: true,
                        formUpdateOption: {
                            name: "",
                            index: null,
                        },
                    })
                }
            });
    }

    HadleClickUpdateQuestionClose = () => {
        fetch(`/formatos/questions/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateQuestion), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.update_question(data.register);
                this.clearValues();
            });
    }

    HadleClickUpdateQuestionLast = () => {
        fetch(`/formatos/questions/${this.state.question_last_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateQuestionLast), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.update_question(data.register)
                this.updateStateVersion(true);
            });
    }

    HadleClickUpdateQuestionOptionValue = () => {
        fetch(`/formatos/update_option_value/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateQuestion), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {

                this.props.update_question(data.register)
                this.updateStateVersion(true);
            });
    }

    HandleChangeUpdateOptions = (e, selectValuesIndex) => {
        const input = e.target.value
        this.setState({
            formUpdateQuestion: {
                ...this.state.formUpdateQuestion,
                options: this.state.formUpdateQuestion.options.map((item, index) => {
                    if (selectValuesIndex === index) {
                        return { name: input, value: item.value }
                    }
                    return item;
                })
            }
        })
    }

    HandleChangeUpdateOptionsValue = (e, selectValuesIndex) => {
        const input = e.target.checked;
        const countOptionTrue = this.state.question_id ? this.state.formUpdateQuestion.options.filter((e) => e.value == true).length : this.state.formQuestion.options.filter((e) => e.value == true).length

        if (this.state.question_id) {
            if (this.state.formUpdateQuestion.question_type == "answer_radio") {

                if (countOptionTrue >= 1) {

                    this.setState({
                        formUpdateQuestion: {
                            ...this.state.formUpdateQuestion,
                            options: this.state.formUpdateQuestion.options.map((item, index) => {
                                if (selectValuesIndex === index) {
                                    return { name: item.name, value: true }
                                } else {
                                    return { name: item.name, value: false }
                                }
                            })
                        }
                    }, () => {
                        this.HadleClickUpdateQuestionOptionValue();
                    });
                } else {
                    this.setState({
                        formUpdateQuestion: {
                            ...this.state.formUpdateQuestion,
                            options: this.state.formUpdateQuestion.options.map((item, index) => {
                                if (selectValuesIndex === index) {
                                    return { name: item.name, value: input }
                                }
                                return item;
                            })
                        }
                    }, () => {
                        this.HadleClickUpdateQuestionOptionValue();
                    });
                }
            } else {
                this.setState({
                    formUpdateQuestion: {
                        ...this.state.formUpdateQuestion,
                        options: this.state.formUpdateQuestion.options.map((item, index) => {
                            if (selectValuesIndex === index) {
                                return { name: item.name, value: input }
                            }
                            return item;
                        })
                    }
                }, () => {
                    this.HadleClickUpdateQuestionOptionValue();
                });
            }
        } else {

            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    options: this.state.formQuestion.options.map((item, index) => {
                        if (selectValuesIndex === index) {
                            return { name: item.name, value: input }
                        }
                        return item;
                    })
                }
            })

        }
    }


    updateId = (id) => {
        this.setState({
            step_id: id,
            showPopover: true,
        })
    }

    clearId = () => {
        this.setState({
            showPopover: false
        })
    }

    updateState = (id, state, countryside) => {
        fetch(`/formatos/update_state/${id}/${state}/${countryside}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData()
            });
    }

    handleChangeAutocompletePosition = selectedOption => {
        let array = []

        if (selectedOption) {
            selectedOption.map((item) => (
                array.push(item.value)
            ))
        }

        if (this.state.question_id) {
            /*
            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    position_ids: selectedOption ? array : []
                }
            })
            */

            this.setState({
                formUpdateQuestion: {
                    ...this.state.formUpdateQuestion,
                    position_ids: selectedOption ? array : []
                }
            }, () => {
                this.updatePositions();
            });

        } else {
            this.setState({
                formQuestion: {
                    ...this.state.formQuestion,
                    position_ids: selectedOption ? array : []
                }
            })
        }
    }

    updatePositions = () => {
        fetch(`/formatos/update_question_positions/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateQuestion), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.updatePosition(data.positions);
                this.props.update_question(data.data);
                //this.messageSuccess(data);

                const arrayCargos = []

                data.data.positions.map((item) => (
                    arrayCargos.push(item.id)
                ))

                this.setState({
                    editValuesPosition: [],

                    formUpdateQuestion: {
                        ...this.state.formUpdateQuestion,
                        position_ids: arrayCargos
                    },

                    formQuestion: {
                        ...this.state.formQuestion,
                        position_ids: []
                    },

                    selectedOptionCargo: {
                        position_ids: "",
                        label: "Cargos"
                    },
                })
            });
    }

    updatePosition = (positions) => {
        const arrayCargos = []

        positions.map((item) => (
            arrayCargos.push({ label: item.name, value: item.id })
        ))

        this.setState({
            cargos: arrayCargos,
        })
    }


    configQuestion = (question) => {
        const array = [];
        const arrayIds = [];

        if (question.positions.length >= 1) {

            question.positions.map((item) => (
                array.push({ label: item.name, value: item.id }),
                arrayIds.push(item.id)
            ))

            this.setState({
                editValuesPosition: array,
                formUpdatePositions: {
                    position_ids: arrayIds,
                }
            })
        } else {
            this.setState({
                editValuesPosition: array,
                formUpdatePositions: {
                    position_ids: []
                }
            })
        }

        if (this.state.question_config_id != "") {
            this.setState({
                question_config_id: "",
            })
        } else {
            this.setState({
                question_config_id: question.id,
            })
        }
    }


    updateStateStep = (e) => {
        this.setState({
            formUpdateStep: {
                ...this.state.formUpdateStep,
                is_private: e.target.checked,
            }
        })
    }

    handleChangeAutocompleteCargoStep = selectedOption => {
        let array = []

        if (selectedOption != null) {
            selectedOption.map((item) => (
                array.push(item.value)
            ))

            this.setState({
                formUpdateStep: {
                    ...this.state.formUpdateStep,
                    position_ids: array
                }
            })

        } else {

            this.setState({
                formUpdateStep: {
                    ...this.state.formUpdateStep,
                    position_ids: []
                }
            })
        }

    }

    editStep = (step) => {
        const array = []

        step.positions.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            showTitle: !this.state.showTitle,
            step_id: step.id,
            formUpdateStep: {
                name: step.name,
                description: step.description,
                is_private: step.is_private,
            },

            editValuesCargos: array,
        })
    }

    //añadir archivo 

    HandleClickFile = () => {
        const formData = new FormData();
        formData.append("file", this.state.formUpdateFile.file)

        fetch(`/formatos/update_file_format/${this.props.format.id}`, {
            method: 'PATCH', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    formUpdateFile: {
                        file: {},
                    },

                    url_img: data.imagen,
                    nameFile: "",
                    modeEditFile: false,
                })
            });
    }


    handleFile = (archivo) => {
        const noAcceptFiles = ["application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "image/webp", "application/vnd.oasis.opendocument.text", "text/markdown", "application/zip", "application/json"]
        const acceptFiles = ["image/jpg", "image/jpeg", "image/png"]

        archivo.map(file => (
            this.setState({
                formUpdateFile: {
                    ...this.state.formUpdateFile,
                    file: file.size >= 11485760 || noAcceptFiles.includes(file.type) ? {} : file
                },

                nameFile: (file.size >= 11485760 || noAcceptFiles.includes(file.type)) ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path,
                sizeFile: file.size
            })
        ));
    };

    cancelUpload = () => {
        this.setState({
            formUpdateFile: {
                ...this.state.formUpdateFile,
                file: ""
            },

            nameFile: "",
            modeEditFile: false,
        })
    }

    editFIle = () => {
        this.setState({
            modeEditFile: true,
        })
    }

    getIconsPosition = (question) => {
        const size = {
            fontSize: "26px",
            cursor: "pointer"
        }

        if (question.position == 1) {
            return <i className="fas fa-angle-down" onClick={() => this.handleClickUpdate(question.id, "down")} style={size}></i>
        } else if (question.position == question.last_position) {
            return <i className="fas fa-angle-up" onClick={() => this.handleClickUpdate(question.id, "up")} style={size}></i>
        } else {
            return (
                <React.Fragment>
                    <i className="fas fa-angle-down mr-3" onClick={() => this.handleClickUpdate(question.id, "down")} style={size}></i>
                    <i className="fas fa-angle-up" onClick={() => this.handleClickUpdate(question.id, "up")} style={size}></i>
                </React.Fragment>
            )
        }
    }

    toogle = (from, question, questions) => {
        if (from == "new") {
            this.setState({ modal: true, question_info: question, step_questions: questions })
        } else {
            this.setState({ modal: false, question_info: {}, step_questions: [] })
            this.props.loadData()
        }
    }

    toogleSelectType = (from, step) => {
        if (from == "new") {
            this.setState({
                modalSelectType: true,
                step_id: step.id,
                step: step,
                question_id: "",
                nameBtn: "",

                formQuestion: {
                    ...this.state.formQuestion,
                    decimal_count_show: 2,
                },
            })
        } else {
            this.setState({ modalSelectType: false })
        }
    }

    toogleSelectTypeUpdate = (from, question) => {
        if (from == "new") {
            this.setState({ modalUpdateQuestionType: true, question_style: question })
        } else {
            this.setState({ modalUpdateQuestionType: false, question_style: {} })
        }
    }

    toogleDinamicFile = (from, question) => {
        if (from == "new") {
            this.setState({ modalConfigurationDinamicFile: true, question_dinamic: question })
        } else {
            this.setState({ modalConfigurationDinamicFile: false, question_dinamic: {} })
            this.props.loadData()

        }
    }


    toogleVersions = (from) => {
        if (from == "new") {
            this.setState({ modalVersionCreate: true })
        } else {
            this.setState({ modalVersionCreate: false })
        }
    }

    toogleQuestionStyle = (e, from, question) => {
        if (from == "new") {
            this.setState({ modalQuestionStyle: true, question_style: question })
            e.stopPropagation();
        } else {
            this.setState({ modalQuestionStyle: false, question_style: {} })
        }
    }

    toogleQuestionCustomFormula = (e, from, question) => {
        if (from == "new") {
            this.setState({ modalQuestionCustomFormula: true, question_style: question })
            e.stopPropagation();
        } else {
            this.setState({ modalQuestionCustomFormula: false, question_style: {} })
        }
    }

    toogleFormatSearchValue = (e, from, question) => {
        if (from == "new") {
            this.setState({ modalFormatSearchValueIndex: true, question_style: question })
            e.stopPropagation();
        } else {
            this.setState({ modalFormatSearchValueIndex: false, question_style: {} })
        }
    }

    toogleQuestionFormatInput = (e, from, question) => {
        if (from == "new") {
            this.setState({ modalQuestionFormatInput: true, question_style: question })
            e.stopPropagation();
        } else {
            this.setState({ modalQuestionFormatInput: false, question_style: {} })
        }
    }

    toogleFormatDate = (e, from, question) => {
        if (from == "new") {
            this.setState({ modalUpdateFormatDate: true, question_style: question })
            e.stopPropagation();
        } else {
            this.setState({ modalUpdateFormatDate: false, question_style: {} })
        }
    }

    toogleQuestionAction = (e, from, question) => {
        if (from == "new") {
            this.setState({ modalQuestionAction: true, question_style: question })
            e.stopPropagation();
        } else {
            this.setState({ modalQuestionAction: false, question_style: {} })
        }
    }

    toogleQuestionFormatCondition = (e, from, question) => {
        if (from == "new") {
            this.setState({ modalQuestionFormatCondition: true, question_style: question })
            e.stopPropagation();
        } else {
            this.setState({ modalQuestionFormatCondition: false, question_style: {} })
        }
    }

    toogleQuestionDate = (e, from, question) => {
        if (from == "new") {
            this.setState({ modalQuestionDate: true, question_style: question })
            e.stopPropagation();
        } else {
            this.setState({ modalQuestionDate: false, question_style: {} })
        }
    }

    toogleShowVersions = (from) => {
        if (from == "new") {
            this.setState({ modalShowVersions: true })
        } else {
            this.setState({ modalShowVersions: false })
        }
    }

    updateStateVersion = (state) => {
        this.setState({ was_edited: state })
    }

    duplicateQuestion = (question) => {
        fetch(`/formatos/duplicate_question/${question.id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.LoadQuestion(this.props.format.id);
                this.clearValues();
                this.messageSuccess(data)
            });
    }

    setStepPosition = (step) => {
        this.setState({
            step_position_id: step.id,
            formUpdateStepPosition: {
                position: step.step_position,
            }
        })
    }

    updateInputs = () => {
        this.HadleClickUpdateQuestion();
    }

    closeModal = () => {
        this.setState({
            modalTemplateTask: false
        })
    }

    //toogle modals configuration format

    toogleUpdateImagen = (from) => {
        if (from == "new") {
            this.setState({ modalUpdateImagen: true })
        } else {
            this.setState({ modalUpdateImagen: false, modeEditFile: false })
        }
    }

    toogleConfigurationFormat = (from) => {
        if (from == "new") {
            this.setState({ modalConfigurationFormat: true })
        } else {
            this.setState({ modalConfigurationFormat: false })
        }
    }

    toogleTemplateTask = (from) => {
        if (from == "new") {
            this.setState({ modalTemplateTask: true })
        } else {
            this.setState({
                formUpdateFormatTemplate: {
                    user_ids: [],
                    question_id: "",
                    task_board_id: "",
                },

                selectedOptionTaskBoardTemplate: {
                    label: this.props.format.task_board ? this.props.format.task_board.name : "Seleccione la plantilla",
                    task_board_id: this.props.format.task_board ? this.props.format.task_board.id : "",
                },

                selectedOptionUsersTemplate: {
                    label: "Usuarios",
                    user_ids: [],
                },

                selectedOptionQuestionTemplate: {
                    label: this.props.format.question_task_board ? this.props.format.question_task_board.name : "Seleccione el nombre del tablero",
                    value: this.props.format.question_task_board ? this.props.format.question_task_board.id : "",
                },

                modalTemplateTask: false
            })
        }
    }

    toogleFormatAssociation = (from) => {
        if (from == "new") {
            this.setState({ modalFormatAssociation: true })
        } else {
            this.setState({ modalFormatAssociation: false })
        }
    }

    toogleQuestionAssociation = (from) => {
        if (from == "new") {
            this.setState({ modalQuestionAssociation: true })
        } else {
            this.setState({ modalQuestionAssociation: false })
        }
    }

    toogleResponsabilities = (from) => {
        if (from == "new") {
            this.setState({ modalResponsabilities: true })
        } else {
            this.setState({ modalResponsabilities: false })
        }
    }

    tooglePDFs = (from) => {
        if (from == "new") {
            this.setState({ modalPDFs: true })
        } else {
            this.setState({ modalPDFs: false })
        }
    }

    toogleQr = (from) => {
        if (from == "new") {
            this.setState({ modalQr: true })
        } else {
            this.setState({ modalQr: false })
        }
    }

    toogleIndexCustomView = (from) => {
        if (from == "new") {
            this.setState({ modalIndexCustomView: true })
        } else {
            this.setState({ modalIndexCustomView: false })
        }
    }

    toogleIndexDuplicateItem = (from) => {
        if (from == "new") {
            this.setState({ modalIndexDuplicateItem: true })
        } else {
            this.setState({ modalIndexDuplicateItem: false })
        }
    }

    confirmMessage = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡La pregunta no se creará en este momento!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                this.clearValues()
            }
        });
    }

    handleChangeAutocompleteQuestionTemplate = selectedOptionQuestionTemplate => {
        this.setState({
            selectedOptionQuestionTemplate,
            formUpdateFormatTemplate: {
                ...this.state.formUpdateFormatTemplate,
                question_id: selectedOptionQuestionTemplate.value
            }
        });
    };

    handleChangeAutocompleteTaskBoardTemplate = selectedOptionTaskBoardTemplate => {
        this.setState({
            selectedOptionTaskBoardTemplate,
            formUpdateFormatTemplate: {
                ...this.state.formUpdateFormatTemplate,
                task_board_id: selectedOptionTaskBoardTemplate.value
            }
        });
    };

    handleChangeAutocompleteUsersTemplate = (selectedOptionMulti) => {
        let array = []

        if (selectedOptionMulti) {
            selectedOptionMulti.map((item) => (
                array.push(item.value)
            ))
        }

        this.setState({
            formUpdateFormatTemplate: {
                ...this.state.formUpdateFormatTemplate,
                user_ids: selectedOptionMulti ? array : []
            }
        })
    }

    handleClickUpdateTemplate = () => {
        fetch(`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/update_task_board_format`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateFormatTemplate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccess(data)
                this.setState({ modalTemplateTask: false })
            });
    }

    //drag-and-drop functions 

    onDragEnd = result => {
        const { source, destination } = result;
        const question_id = Number(result.draggableId);

        if (!destination) {
            return;
        }

        fetch(`/formatos/get_step_question/${question_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                let questions = this.props.data.filter(e => e.id == data.register)[0].questions
                const itemsTest = reorder(
                    questions,
                    source.index,
                    destination.index
                );
                this.updatePositionQuestions(data.register, itemsTest);
            });
    };

    updatePositionQuestions = (step_id, new_array) => {
        let array = []

        new_array.map((item) => (
            array.push(item.id)
        ))

        const updateValues = {
            questions_ids: array
        }

        fetch(`/formatos/update_position_questions/${step_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(updateValues), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateStepQuestionsData(step_id, data.questions)
            });
    }

    updateDefaultValue = (register) => {
        this.setState({
            selectedOptionQuestionTemplate: {
                label: register.question_task_board ? register.question_task_board.name : "Seleccione el nombre del tablero",
                value: register.question_task_board ? register.question_task_board.id : "",
            },

            selectedOptionTaskBoardTemplate: {
                label: register.task_board ? register.task_board.name : "Seleccione la plantilla",
                task_board_id: register.task_board ? register.task_board.id : "",
            },

            formUpdateFormatTemplate: {
                user_ids: register.user_ids,
                question_id: register.question_task_board ? register.question_task_board.id : "",
                task_board_id: register.task_board ? register.task_board.id : "",
            },
        })
    }

    updateDropdozeRemove = (e, id) => {
        this.removeFile(e, id);
        this.setState({ nameFileQuestionImagen: "" });
    }

    clipboardValueIDquestion = (question_id) => {
        navigator.clipboard.writeText(question_id);
        this.setState({ showAlertClipboard: true });

        setTimeout(() => {
            this.setState({ showAlertClipboard: false });

        }, 3000);
    }

    selectMatrixQuestion = (question) => {
        this.setState({
            questionMatrix: question
        })
    }

    activeClassDv = (question_type) => {
        if (question_type === 'Matrix' || question_type === 'answer_matrix') {
            return 'ui-show_formats-question-type__container'
        } else {
            return 'col-md-12';
        }

    }

    render() {

        const panelConfigFormat = [
            { name: 'Configuración General', icon: '\ue8b8', onclick: () => this.toogleConfigurationFormat("new") },
            { name: 'Imagen Portada', icon: '\ue413', onclick: () => this.toogleUpdateImagen("new") },
            { name: 'Plantilla Tareas', icon: '\uf23a', onclick: () => this.toogleTemplateTask("new") },
            { name: 'Asociación Formatos', icon: '\uf743', onclick: () => this.toogleFormatAssociation("new") },
            { name: 'Asociación Preguntas', icon: '\uf04c', onclick: () => this.toogleQuestionAssociation("new") },
            { name: 'Asignar Responsables', icon: '\ue7fd', onclick: () => this.toogleResponsabilities("new") },
            { name: 'Documentos PDF', icon: '\ue415', onclick: () => this.tooglePDFs("new") },
            { name: 'QR', icon: '\uef6b', onclick: () => this.toogleQr("new") },
            { name: 'Estadísticas', icon: '\ue917', onclick: () => this.toogleIndexCustomView("new") },
            { name: 'Duplicar items', icon: '\ue917', onclick: () => this.toogleIndexDuplicateItem("new") }
        ];

        const config = {
            language: 'es',
            quickInsertEnabled: false,
            colorsHEXInput: true,
            autoFocus: true,
            toolbartop: true,
            linkAlwaysBlank: true,
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            htmlExecuteScripts: true,
            key: this.props.key_text,
            imageUploadToS3: this.props.imageUpload,
            iframe: true,
            tabSpaces: 4,
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
            toolbarButtons: {
                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass'],

                    // Alignment of the group in the toolbar.
                    align: 'left',

                    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                    buttonsVisible: 12
                },


                moreParagraph: {
                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL'],
                    align: 'left',
                    buttonsVisible: 11
                },

                moreRich: {
                    buttons: ['insertLink', 'insertImage', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly'],
                    align: 'left',
                    buttonsVisible: 10
                },

                moreMisc: {
                    buttons: ['fullscreen'],
                    align: 'right',
                    buttonsVisible: 9
                }
            }
        }

        return (
            <React.Fragment>
                <GoogleFontLoader
                    fonts={[
                        {
                            font: 'Material Symbols Outlined',
                            weights: [400],
                        },
                    ]}
                    async={true}
                    defer={true}
                />

                {this.state.modalUpdateImagen && (
                    <ModalUpdateImagen
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalUpdateImagen}
                        toggle={this.toogleUpdateImagen}
                        title={"Imagen de Portada"}

                        url_img={this.state.url_img}
                        modeEditFile={this.state.modeEditFile}
                        nameFile={this.state.nameFile}
                        handleFile={this.handleFile}
                        messageSuccess={this.messageSuccess}

                        HandleClickFile={this.HandleClickFile}
                        cancelUpload={this.cancelUpload}
                        editFIle={this.editFIle}
                        removeImagen={this.removeImagen}
                        formato={this.props.format}
                    />
                )}

                {this.state.modalConfigurationFormat && (
                    <ConfigurationFormat
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalConfigurationFormat}
                        toggle={this.toogleConfigurationFormat}
                        title={"Configuración General"}

                        format={this.props.format}
                        configuration_format={this.props.configuration_format}
                        questions={this.state.questions}

                        cargos={this.props.cargos}
                        procesos={this.props.procesos}
                        users={this.props.users}
                        updateIsTest={this.updateIsTest}
                        pdfs={this.props.pdfs}
                        config={config}
                    />
                )}

                {this.state.modalTemplateTask && (
                    <IndexUpdateTaskBoard
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalTemplateTask}
                        toggle={this.toogleTemplateTask}
                        title={"Configuración de Plantilla de Tarea"}
                        closeModal={this.closeModal}
                        users={this.props.users}

                        format={this.props.format}
                        format_category={this.props.format_category}
                        task_board_templates={this.props.task_board_templates}

                        formUpdateFormatTemplate={this.state.formUpdateFormatTemplate}

                        selectedOptionTaskBoardTemplate={this.state.selectedOptionTaskBoardTemplate}
                        handleChangeAutocompleteTaskBoardTemplate={this.handleChangeAutocompleteTaskBoardTemplate}

                        selectedOptionQuestionTemplate={this.state.selectedOptionQuestionTemplate}
                        handleChangeAutocompleteQuestionTemplate={this.handleChangeAutocompleteQuestionTemplate}

                        selectedOptionUsersTemplate={this.state.selectedOptionTaskBoardTemplate}
                        handleChangeAutocompleteUsersTemplate={this.handleChangeAutocompleteUsersTemplate}
                        handleClick={this.handleClickUpdateTemplate}
                        updateDefaultValue={this.updateDefaultValue}
                    />
                )}

                {this.state.modalFormatAssociation && (
                    <IndexFormatAssociations
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalFormatAssociation}
                        toggle={this.toogleFormatAssociation}
                        title={"Asociación de Formatos"}

                        format={this.props.format}
                        formatos={this.state.formatos}
                        steps_questions={this.props.steps_questions}
                        config={config}
                    />
                )}

                {this.state.modalIndexDuplicateItem && (
                    <IndexDuplicateItem
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalIndexDuplicateItem}
                        toggle={this.toogleIndexDuplicateItem}
                        title={"Duplicar items"}

                        format={this.props.format}
                        formatos={this.state.formatos}
                        steps_questions={this.props.steps_questions}
                    />
                )}

                {this.state.modalQuestionAssociation && (
                    <IndexQuestionAssociations
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalQuestionAssociation}
                        toggle={this.toogleQuestionAssociation}
                        title={"Asociación de Preguntas"}

                        format={this.props.format}
                        formatos={this.state.format_associations}
                        steps_questions={this.props.steps_questions}
                        config={config}
                    />
                )}

                {this.state.modalResponsabilities && (
                    <ModalResponsabilidades
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalResponsabilities}
                        toggle={this.toogleResponsabilities}
                        title={"Asignar Responsables"}

                        format={this.props.format}
                        questions_users={this.props.questions_users}
                        config={config}
                    />
                )}

                {this.state.modal && (
                    <ModalConfigurationDate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Configuración del Campo Tipo Fecha"}
                        question={this.state.question_info}
                        positions={this.props.positions}
                        steps_questions={this.props.steps_questions}
                        config={config}
                    />
                )}

                {this.state.modalConfigurationDinamicFile && (
                    <ModalConfigurationDinamicFile
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalConfigurationDinamicFile}
                        toggle={this.toogleDinamicFile}
                        title={"Configuración del Campo Dinámico"}
                        question={this.state.question_dinamic}
                        messageSuccess={this.messageSuccess}
                        format={this.props.format}
                        cargos={this.state.cargos}
                        config={config}
                    />
                )}

                {this.state.modalVersionCreate && (
                    <FormCreateVersions
                        backdrop={"static"}
                        modal={this.state.modalVersionCreate}
                        toggle={this.toogleVersions}
                        title={"Crear Versión"}
                        format={this.props.format}
                        updateStateVersion={this.updateStateVersion}
                        config={config}
                    />
                )}

                {this.state.modalSelectType && (
                    <ModalSelectType
                        backdrop={"static"}
                        modal={this.state.modalSelectType}
                        toggle={this.toogleSelectType}
                        title={"Opciones Tipo de Pregunta"}

                        setName={this.setName}
                        step={this.state.step}
                        getIcon={this.getIcon}
                        formQuestion={this.state.formQuestion}
                        nameBtn={this.state.nameBtn}
                        modeEdit={false}
                        format={this.props.format}
                        config={config}
                    />
                )}

                {this.state.modalShowVersions && (
                    <ModalShowVersions
                        backdrop={"static"}
                        modal={this.state.modalShowVersions}
                        toggle={this.toogleShowVersions}
                        title={"Control de Cambios"}
                        format={this.props.format}
                        messageSuccess={this.messageSuccess}
                        updateStateVersion={this.updateStateVersion}
                        config={config}
                    />
                )}

                {this.state.modalQuestionStyle && (
                    <ModalQuestionStyle
                        backdrop={"static"}
                        modal={this.state.modalQuestionStyle}
                        toggle={this.toogleQuestionStyle}
                        title={"Configuración del Campo"}

                        question={this.state.question_style}
                        formato={this.props.format}

                        users={this.props.users}
                        procesos={this.props.procesos}
                        cargos={this.props.cargos}
                        config={config}
                    />
                )}

                {this.state.modalPDFs && (
                    <ModalPDFs
                        backdrop={"static"}
                        modal={this.state.modalPDFs}
                        toggle={this.tooglePDFs}
                        title={"Documentos PDF"}

                        format={this.props.format}
                        format_category={this.props.format_category}
                        config={config}
                    />
                )}

                {this.state.modalQr && (
                    <ModalFormatQr
                        backdrop={"static"}
                        modal={this.state.modalQr}
                        toggle={this.toogleQr}
                        title={"Qrs del Formato"}

                        format={this.props.format}
                        format_category={this.props.format_category}
                        formatos={this.state.formatos}
                        config={config}
                    />
                )}


                {this.state.modalUpdateQuestionType && (
                    <ModalUpdateQuestionType
                        backdrop={"static"}
                        modal={this.state.modalUpdateQuestionType}
                        toggle={this.toogleSelectTypeUpdate}
                        title={`Actualizar el Tipo de Pregunta de ${this.state.question_style.question}`}

                        setName={this.setName}
                        getIcon={this.getIcon}
                        formQuestion={this.state.formQuestion}
                        nameBtn={this.state.nameBtn}
                        question={this.state.question_style}
                        format={this.props.format}
                        config={config}
                    />
                )}

                {this.state.modalQuestionFormatInput && (
                    <ModalQuestionFormatInput
                        backdrop={"static"}
                        modal={this.state.modalQuestionFormatInput}
                        toggle={this.toogleQuestionFormatInput}
                        question={this.state.question_style}
                        formato={this.props.format}
                        title={"Asociar Respuesta"}
                        config={config}
                    />
                )}

                {this.state.modalQuestionFormatCondition && (
                    <ModalQuestionFormatConditional
                        backdrop={"static"}
                        modal={this.state.modalQuestionFormatCondition}
                        toggle={this.toogleQuestionFormatCondition}
                        question={this.state.question_style}
                        formato={this.props.format}
                        title={"Filtros del Formato"}

                        users={this.props.users}
                        procesos={this.props.procesos}
                        cargos={this.props.cargos}
                        config={config}
                    />
                )}

                {this.state.modalFormatSearchValueIndex && (
                    <FormatSearchValueIndex
                        backdrop={"static"}
                        modal={this.state.modalFormatSearchValueIndex}
                        toggle={this.toogleFormatSearchValue}
                        title={"Buscador de Valores"}
                        question={this.state.question_style}
                        formato={this.props.format}
                        formatos={this.state.formatos}
                        config={config}
                    />
                )}

                {this.state.modalQuestionDate && (
                    <QuestionDateIndex
                        backdrop={"static"}
                        modal={this.state.modalQuestionDate}
                        toggle={this.toogleQuestionDate}
                        title={"Buscador de Valores"}
                        question={this.state.question_style}
                        formato={this.props.format}
                        config={config}
                    />
                )}


                {this.state.modalUpdateFormatDate && (
                    <ModalUpdateFormatDate
                        backdrop={"static"}
                        modal={this.state.modalUpdateFormatDate}
                        toggle={this.toogleFormatDate}
                        title={"Actualizar Formato de la Fecha"}
                        question={this.state.question_style}
                        update_question={this.props.update_question}
                        config={config}
                    />
                )}

                {this.state.modalQuestionCustomFormula && (
                    <QuestionCustomFormula
                        backdrop={"static"}
                        modal={this.state.modalQuestionCustomFormula}
                        toggle={this.toogleQuestionCustomFormula}
                        title={"Acciones de la pregunta"}
                        question={this.state.question_style}
                        formato={this.props.format}
                        config={config}
                    />
                )}

                {this.state.modalIndexCustomView && (
                    <ModalIndexCustomView
                        backdrop={"static"}
                        modal={this.state.modalIndexCustomView}
                        toggle={this.toogleIndexCustomView}
                        format={this.props.format}
                        format_category={this.props.format_category}
                        section_custom_views={this.props.section_custom_views}
                        icons={this.props.icons}
                        custom_view_permissions={this.props.custom_view_permissions}
                        categories={this.props.format.array_categories}
                        title={"Estadística del Formato"}
                        config={config}
                    />
                )}

                {this.state.modalQuestionAction && (
                    <ModalIndexQuestionAction
                        backdrop={"static"}
                        modal={this.state.modalQuestionAction}
                        toggle={this.toogleQuestionAction}
                        title={"Acciones de la Pregunta"}

                        question={this.state.question_style}
                        formato={this.props.format}

                        users={this.props.users}
                        procesos={this.props.procesos}
                        cargos={this.props.cargos}
                        config={config}
                    />
                )}

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a
                                href={`/formatos/format_categories?categories=${this.props.format.array_categories}`}
                                data-turbolinks="true"
                            >
                                {"ForData"}
                            </a>
                        </li>

                        <li className="breadcrumb-item">
                            {this.props.format.name}
                        </li>

                        <li className="breadcrumb-item">
                            <a
                                href={`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/surveys?view=ACTIVE`}
                                data-turbolinks="true"
                            >
                                {"Ver registros"}
                            </a>
                        </li>
                    </ol>
                </nav>

                < div className="ui-formatsShow-container-columns">
                    <div className={`format-fields ui-formatsShow-container-all-formats mb-3 ${isMobile ? "p-0" : ""}`}>



                        {/* {isMobile && (
                            <div className="mb-3">
                                <a
                                    onClick={() => this.add_step()}
                                    className="btn-shadow btn btn-info btn-block"
                                    style={{ color: "#ffff" }}
                                >
                                    <i className="fas fa-plus"></i> Crear Formulario
                                </a>
                            </div>
                        )} */}

                        <ul className="nav nav-tabs mb-0" id="myTab" role="tablist">
                            <div className="ui-scroll-horizontal-steps" id="ui-scroll">
                                {this.props.data.length <= 14 && (
                                    <React.Fragment>
                                        <li className="nav-item">
                                            <a onClick={() => this.add_step()} className="nav-link" id="PopoverLegacy"> <i className="fas fa-plus"></i> </a>
                                        </li>

                                        <UncontrolledPopover isOpen={this.state.modalCreateSeccion} placement="right" target={`PopoverLegacy`}>
                                            <PopoverHeader>{"Crear sección"}<a className="close-popover" onClick={() => this.setState({ modalCreateSeccion: false })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                            <PopoverBody>
                                                <div style={{ width: "289px" }}>

                                                    <input
                                                        type="text"
                                                        name="name"
                                                        value={this.state.formCreateSeccion.name}
                                                        onChange={this.HandleChangeCreateSeccion}
                                                        className={`form form-control`}
                                                        placeholder=""
                                                    />

                                                    <div className="mt-3">
                                                        <button
                                                            className="btn-shadow btn btn-info btn-block"
                                                            onClick={() => this.handleClickCreateSeccion()}
                                                        >
                                                            Crear sección
                                                        </button>
                                                    </div>
                                                </div>
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </React.Fragment>
                                )}


                                {this.props.data.map((step, index) => (
                                    <li className="nav-item ui-formatShow_nav-item" key={index}>
                                        <a
                                            className={`nav-link  ${this.props.count == (index + 1) ? "active" : ""}`}
                                            onClick={() => { this.clearValues(); this.props.updateCount(index + 1) }}
                                            id={`home-tab${step.id}`}
                                            data-toggle="tab"
                                            //href={`#home${step.id}`}
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected="true"
                                        >
                                            <UncontrolledTooltip placement="left" target={`home-tab${step.id}`}>
                                                {step.name}
                                            </UncontrolledTooltip>

                                            <div className='ui-ShowFormats-navItem'>
                                                {step.name == "Formulario sin título" ? "Sección" : step.name}
                                            </div>

                                            {(this.state.step_position_id == step.id) ? (
                                                <React.Fragment>
                                                    <select
                                                        value={step.step_position}
                                                        onChange={(e) => this.updateStepPosition(e, step.id)}
                                                    >
                                                        {this.props.data.map((item, index) => (
                                                            <React.Fragment>
                                                                <option value={item.step_position}>{index + 1}</option>
                                                            </React.Fragment>
                                                        ))}
                                                    </select>

                                                    <i className="fas fa-trash-alt icon-show-format ml-3" style={{ color: "red", fontSize: "14px", paddingRight: '10px', borderRight: '1px solid #ababab' }} onClick={() => this.deleteStep(step)} title='Eliminar Sección' ></i>

                                                    <i className="fas fa-times ml-1" style={{ color: "#ababab", cursor: "pointer", fontSize: 16, paddingLeft: '8px' }} onClick={() => this.setState({ step_position_id: "" })}></i>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <span className="ml-1">{index + 1}/{this.props.data.length}</span>
                                                    <i onClick={() => this.setStepPosition(step)} className="fas fa-exchange-alt ml-1"></i>
                                                </React.Fragment>
                                            )}
                                        </a>
                                    </li>
                                ))}
                            </div>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            {this.props.data.map((step, index) => (
                                <div className={`tab-pane fade show ${this.props.count == (index + 1) ? "active" : ""}`} id={`home${step.id}`} role="tabpanel" aria-labelledby="home-tab" key={step.id}>
                                    <div className={`card mt-4`}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {(this.state.showTitle && this.state.step_id == step.id) ? (
                                                        <React.Fragment>
                                                            <div className="row">
                                                                <div className="col-md-12 ui-show-formats-formatos-input-seccion-container">
                                                                    <input
                                                                        type="text"
                                                                        className="form form-control ui-show-formats-formatos-input-seccion"
                                                                        style={{ width: "95%", display: "initial" }}
                                                                        name="name"
                                                                        value={this.state.formUpdateStep.name}
                                                                        onChange={this.HandleChangeUpdateStep}
                                                                    />

                                                                    <i className="fas fa-times icon-show-format pl-3 color-blue" onClick={() => this.setState({ showTitle: !this.state.showTitle, step_id: "" })}></i>
                                                                </div>

                                                                <div className="col-md-12 mt-3">
                                                                    <FroalaEditor
                                                                        model={this.state.formUpdateStep.description}
                                                                        config={config}
                                                                        onModelChange={this.HandleChangeUpdateStepDescription}
                                                                    />
                                                                </div>


                                                                <div className="col-md-7 mt-3">
                                                                    {this.state.formUpdateStep.is_private && (
                                                                        <React.Fragment>
                                                                            <label>Cargos que pueden acceder</label>
                                                                            <input
                                                                                type="hidden"
                                                                                name="position_ids"
                                                                                value={this.state.selectedOptionCargo.position_ids}
                                                                            />
                                                                            <Select
                                                                                style={{ zIndex: 9 }}
                                                                                isMulti
                                                                                closeMenuOnSelect={false}
                                                                                autoFocus={false}
                                                                                className={`link-form ui-show-formats-formatos_select_multipleOptios_charges`}
                                                                                classNamePrefix="select"
                                                                                placeholder="Seleccione"
                                                                                name="position_ids"
                                                                                onChange={this.handleChangeAutocompleteCargoStep}
                                                                                options={this.state.cargos}
                                                                                defaultValue={this.state.editValuesCargos}
                                                                            />
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>

                                                                <div className="col-md-3">
                                                                    <div className="custom-control custom-switch mt-5">
                                                                        <input type="checkbox" onChange={(e) => this.updateStateStep(e)} className="custom-control-input" id={`customGestion`} checked={this.state.formUpdateStep.is_private} />
                                                                        <label className="custom-control-label" htmlFor={`customGestion`}>Es privado</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-2 text-right">
                                                                    <i className="fas fa-check icon-show-format ml-3 mt-5" style={{ color: "green", fontSize: "25px" }} onClick={() => this.updateSeccion()}></i>
                                                                    <i className="fas fa-trash-alt icon-show-format ml-3" style={{ color: "red", fontSize: "25px" }} onClick={() => this.deleteStep(step)}></i>
                                                                </div>

                                                            </div>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <h4 onClick={() => this.setState({ showTitle: !this.state.showTitle })}>{step.name} <i className="fas fa-pencil-alt icon-show-format float-right color-blue" onClick={() => this.editStep(step)}></i></h4>
                                                            {ReactHtmlParser(step.description)}
                                                        </React.Fragment>
                                                    )}
                                                </div>


                                                {/*<div className="col-md-2 text-right">
                                                    {(this.state.showTitle && this.state.step_id == step.id) ? (
                                                        <React.Fragment>
                                                            <i className="fas fa-times icon-show-format" onClick={() => this.setState({ showTitle: !this.state.showTitle, step_id: "" })}></i>
                                                        </React.Fragment>
                                                    ) : (
                                                        <i className="fas fa-pencil-alt icon-show-format" onClick={() => this.editStep(step)}></i>
                                                    )}
                                                </div>*/}
                                            </div>
                                        </div>

                                        {/*<div className="card-footer text-right">
                                            <a
                                                className="btn-actions"
                                                onClick={() => this.deleteStep(step)}
                                            >
                                                <i className="fas fa-trash-alt"></i>
                                            </a>
                                        </div>*/}
                                    </div>

                                    {this.state.showMessage && (
                                        <div className="alert alert-success mt-2" role="alert">
                                            <b>¡La pregunta fue creada con éxito!</b>
                                        </div>
                                    )}

                                    {this.state.showMessageError && (
                                        <div className="alert alert-danger mt-2" role="alert">
                                            <b>{this.state.errorMessage}</b>
                                        </div>
                                    )}

                                    <div className={`card mb-4 mt-4`}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className={`col-md-7 ${isMobile ? "mb-3" : ""}`}>
                                                    <input
                                                        type="text"
                                                        className={`form form-control ${!this.state.ErrorValues && this.state.formQuestion.question == "" ? "error-class" : ""}`}
                                                        name="question"
                                                        value={this.state.formQuestion.question}
                                                        onChange={(e) => this.HandleChangeQuestion(e, step)}
                                                        placeholder={"Pregunta"}
                                                    />

                                                    {/*    <input
                                                        type="text"
                                                        className={`form mt-3 form-control ${this.state.ErrorValues == false && this.state.formQuestion.question_alias == "" ? "error-class" : ""}`}
                                                        name="question_alias"
                                                        value={this.state.formQuestion.question_alias}
                                                        onChange={(e) => this.HandleChangeQuestion(e, step)}
                                                        placeholder={"Alias de la pregunta"}
                                                    /> */}


                                                    {(!this.state.ErrorValues && this.state.ErrorValuesOptions) && (
                                                        <div className="alert alert-danger mt-3" role="alert">
                                                            <ul className="mb-0 pl-2">
                                                                <li className={`${this.state.formQuestion.question != "" ? "color-password" : ""}`}>{"La pregunta no debe de estar vacía"}</li>
                                                                <li className={`${this.state.formQuestion.question_type != "" ? "color-password" : ""}`}>{"Debes de seleccionar un tipo de pregunta"}</li>
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-md-5" style={{ display: "flex" }}>
                                                    <button
                                                        type="button"
                                                        className={`btn-shadow btn btn-primary btn-block text-left btn-type-questions ui-showFormatCategories-selectTypeQUestions`}
                                                        style={{ width: "88%", height: "33px" }}
                                                        onClick={() => this.toogleSelectType("new", step)}
                                                    >
                                                        {(this.state.nameBtn != "" && !this.state.question_id) ? (
                                                            <React.Fragment>
                                                                {this.getIcon(this.state.nameBtn)} {this.state.nameBtn}
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <i className="fas fa-info-circle" style={{ color: '#fff', paddingRight: '5px' }}></i>  {"Seleccione tipo de pregunta"}
                                                            </React.Fragment>
                                                        )}
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="btn-shadow btn btn-info btn-block text-left mt-0 ui-formats-btn-check"
                                                        style={{ width: "15%", marginLeft: "10px", height: "33px" }}
                                                        onClick={() => this.createQuestion()}
                                                    >
                                                        {this.state.loader_create_question ? (
                                                            <Preloader />
                                                        ) : (
                                                            <i className="fas fa-check"></i>
                                                        )}
                                                    </button>

                                                    {(this.state.formQuestion.question || this.state.formQuestion.description) && (
                                                        <button
                                                            type="button"
                                                            className="btn-shadow btn btn-danger btn-block text-left mt-0 ui-formats-btn-check"
                                                            style={{ width: "15%", marginLeft: "10px", height: "33px" }}
                                                            onClick={() => this.confirmMessage()}
                                                        >
                                                            <i className="fas fa-times"></i>
                                                        </button>
                                                    )}
                                                </div>

                                                <div className="col-md-7 mt-3 ui-show-formats-formatos_froalaeditor_descriptionQUestion">
                                                    <FroalaEditor
                                                        model={this.state.formQuestion.description}
                                                        config={config}
                                                        onModelChange={(e) => this.HandleChangeQuestion({ target: { name: "description", value: e } }, step)}
                                                    />
                                                </div>

                                                {(this.state.nameBtn != "" && this.state.step_id == step.id && !this.state.question_id) && (
                                                    <div className="col-md-12 mt-4">
                                                        {this.getInputs(this.state.nameBtn)}
                                                    </div>
                                                )}

                                                {false && (
                                                    <div className="col-md-12">
                                                        <div className="alert alert-danger mt-2 text-center" role="alert">
                                                            <b>Debes de agregar al menos una opción para poder guardar</b>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <div className='ui-btree-linkContainer'>
                                            <div className="ui-btree-link-itemContainer">
                                                <Droppable droppableId="droppable">
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            style={{ height: "100%" }}
                                                        >
                                                            {step.questions.map((question, index) => (
                                                                <Draggable
                                                                    key={question.id}
                                                                    draggableId={`${question.id}`}
                                                                    index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <div className="card mt-3 border-questions" style={{ borderLeft: `5px solid ${this.state.question_id == question.id ? "#f78e09" : "#289ef9"}` }} key={question.id} onClick={() => this.state.question_id == question.id ? console.log("asdasdasd") : this.editQuestion(question, step.id)}>
                                                                                <div className={`card-body ${this.state.question_id == question.id ? "" : "pl-0"}`}>

                                                                                    {this.state.question_id == question.id ? (
                                                                                        <React.Fragment>
                                                                                            <div className="row">

                                                                                                <div className={`col-md-7`}>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="question"
                                                                                                        className="form form-control"
                                                                                                        value={this.state.formUpdateQuestion.question}
                                                                                                        onBlur={this.HadleClickUpdateQuestion}
                                                                                                        onChange={(e) => this.HandleChangeUpdateQuestion(e)}
                                                                                                    />

                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className={`form mt-3 form-control`}
                                                                                                        name="question_alias"
                                                                                                        value={this.state.formUpdateQuestion.question_alias}
                                                                                                        onBlur={this.HadleClickUpdateQuestion}
                                                                                                        onChange={(e) => this.handleChangeUpdateQuestionAlias(e)}
                                                                                                        placeholder={"Alias de la pregunta"}
                                                                                                    />
                                                                                                </div>

                                                                                                {true && (
                                                                                                    <div className={`col-md-5 ${isMobile ? "mt-3" : ""}`} style={{ display: "flex" }}>
                                                                                                        {this.state.question_id && (
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className={`btn-shadow btn btn-secondary btn-block text-left ui-showFormatCategories-btnTypeQuestion-Questions`}
                                                                                                                style={{ width: "88%", height: "33px" }}
                                                                                                            // onClick={() => this.toogleSelectTypeUpdate("new", question)}
                                                                                                            >
                                                                                                                {this.getIcon(this.state.nameBtn)} {this.state.nameBtn}
                                                                                                            </button>
                                                                                                        )}

                                                                                                        <button
                                                                                                            className={`btn pt-0 pr-0 ui-showFormatCategories-containerIconClose`}
                                                                                                            style={{ height: "33px" }}
                                                                                                            onClick={() => { this.clearValues(); this.HadleClickUpdateQuestion(); this.setState({ nameFileQuestionImagen: "" }); }}
                                                                                                        >
                                                                                                            <i className="fas fa-times icon-show-format"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                )}

                                                                                                <div className="col-md-12">
                                                                                                    <div className="col-md-7 mt-3 pl-0">
                                                                                                        <FroalaEditor
                                                                                                            model={this.state.formUpdateQuestion.description}
                                                                                                            config={config}
                                                                                                            onModelChange={(e) => this.HandleChangeUpdateQuestion({ target: { name: "description", value: e } }, step)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-md-7 mt-3">
                                                                                                    <Dropzone onDrop={(files) => this.handleFileQuestionImagen(files)}>
                                                                                                        {({ getRootProps, getInputProps }) => (
                                                                                                            <div
                                                                                                                {...getRootProps({
                                                                                                                    className: 'dropzone',
                                                                                                                })}
                                                                                                            >
                                                                                                                <input {...getInputProps()} />
                                                                                                                <p>{`${(this.state.nameFileQuestionImagen != "" ? this.state.nameFileQuestionImagen : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Dropzone>

                                                                                                    {this.state.nameFileQuestionImagen != "" && (
                                                                                                        <a onClick={(e) => this.updateDropdozeRemove(e, question.id)} className="btn colo-red" style={{ color: 'red', fontWeight: 600 }}>
                                                                                                            <i className="fas fa-times"></i> Remover archivo
                                                                                                        </a>
                                                                                                    )}
                                                                                                </div>

                                                                                                <div className="col-md-5 ui-show-formats__container_clipboard">
                                                                                                    {this.state.showAlertClipboard && (
                                                                                                        <span className="alert alert-success" style={{ zIndex: 99999 }}>
                                                                                                            El ID ha sido copiado al portapapeles
                                                                                                        </span>
                                                                                                    )}
                                                                                                    <div className='ui-show-formats_data__container_id' ref={this.iconRef}>
                                                                                                        <label className='mr-3 mt-2 ui-show-formats_message-label'>ID Pregunta</label>
                                                                                                        <div className='ui-show-formats_message_icons'>

                                                                                                            <span className='ui-show-formats_icon_clipboard'>
                                                                                                                <i className="far fa-clone" onClick={() => this.clipboardValueIDquestion(question.id)}></i>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-md-12 mt-3">
                                                                                                    {(question.question_type == "answer_radio" || question.question_type == "answer_select" || question.question_type == "answer_checkbox") ? (
                                                                                                        <React.Fragment>
                                                                                                            {(this.state.nameBtn == "Lista desplegable" || this.state.nameBtn == "Opción múltiple" || this.state.nameBtn == "Casillas de verificación") ? (
                                                                                                                <React.Fragment>
                                                                                                                    <div className="custom-control custom-switch mt-3 mb-3">
                                                                                                                        <input type="checkbox" onChange={(e) => this.handleChangeStateQuestion(e, "has_default_value")} className="custom-control-input" id={`customCheckboxDefaultValue`} checked={this.state.question_id ? this.state.formUpdateQuestion.has_default_value : this.state.formQuestion.has_default_value} />
                                                                                                                        <label className="custom-control-label" htmlFor={`customCheckboxDefaultValue`}>Añadir valor por defecto</label>
                                                                                                                    </div>
                                                                                                                    {this.getOptions(this.HandleChangeUpdateOptions)}
                                                                                                                </React.Fragment>
                                                                                                            ) : (
                                                                                                                <React.Fragment>
                                                                                                                    <p>asdasdasd</p> {this.getInputs(this.state.nameBtn)}
                                                                                                                </React.Fragment>
                                                                                                            )}
                                                                                                        </React.Fragment>
                                                                                                    ) : (
                                                                                                        <React.Fragment>
                                                                                                            {question.question_type == "answer_matrix" ? (
                                                                                                                <React.Fragment>
                                                                                                                    {this.getTypeQuestion(question)}
                                                                                                                </React.Fragment>
                                                                                                            ) : (
                                                                                                                <React.Fragment>
                                                                                                                    {this.getInputs(this.state.nameBtn)}
                                                                                                                </React.Fragment>
                                                                                                            )}

                                                                                                        </React.Fragment>
                                                                                                    )}
                                                                                                </div>

                                                                                                {this.state.question_id == question.id && (

                                                                                                    <React.Fragment>
                                                                                                        <div className="col-md-12 mt-3 mb-2">
                                                                                                            <hr />
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-7 border-content-config ui-showFormatCategories-containerPositionSection">
                                                                                                                    <div className={`${question.configuration_question_date != null ? 'row ui-PositionSeccion-questions' : 'row ui-PositionSeccion-questionsNormal'} `}>
                                                                                                                        <div className={`${question.configuration_question_date != null ? 'ui-showFormatCategories-positions' : 'ui-showFormatCategories-position'} `}>
                                                                                                                            <label>Sección</label>
                                                                                                                            <select
                                                                                                                                className="select-question"
                                                                                                                                //value={question.position}
                                                                                                                                onChange={(e) => this.updateStepQuestion(e, question.id, step.id)}
                                                                                                                            >
                                                                                                                                <option value="" disabled selected>{step.step_position + 1}</option>
                                                                                                                                {this.props.data.map((item, index) => (
                                                                                                                                    <React.Fragment>
                                                                                                                                        <option value={item.id}>{index + 1}</option>
                                                                                                                                    </React.Fragment>
                                                                                                                                ))}
                                                                                                                            </select>
                                                                                                                        </div>

                                                                                                                        <div className={`${question.configuration_question_date != null ? 'ui-showFormatCategories-sections' : 'ui-showFormatCategories-section'} `}>
                                                                                                                            <label>Posición</label>
                                                                                                                            <select
                                                                                                                                className="select-question"
                                                                                                                                value={question.position}
                                                                                                                                onChange={(e) => this.updatePositionQuestion(e, step.id)}
                                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                            >
                                                                                                                                {step.questions.map(item => (
                                                                                                                                    <React.Fragment>
                                                                                                                                        <option value={item.position}>{item.position}</option>
                                                                                                                                    </React.Fragment>
                                                                                                                                ))}
                                                                                                                            </select>
                                                                                                                        </div>

                                                                                                                        {question.configuration_question_date != null && (
                                                                                                                            <React.Fragment>
                                                                                                                                <div className="ui-showFormatCategories-semaphore">
                                                                                                                                    <a
                                                                                                                                        // href="javascript:void(0);"
                                                                                                                                        className="btn"
                                                                                                                                        id={`configuration${question.id}`}
                                                                                                                                        onClick={() => this.toogle("new", question, step.questions)}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-circle" style={{ color: "#23D132" }}></i> <i className="fas fa-circle" style={{ color: "yellow" }}></i> <i className="fas fa-circle" style={{ color: "red" }}></i>
                                                                                                                                    </a>

                                                                                                                                    <UncontrolledTooltip placement="top" target={`configuration${question.id}`}>
                                                                                                                                        {"CONFIGURAR SEMAFORO"}
                                                                                                                                    </UncontrolledTooltip>
                                                                                                                                </div>
                                                                                                                            </React.Fragment>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div className="col-md-2 pl-0 border-content-config text-center ui-showFormatCategories-ContainerIcons-Action">
                                                                                                                    {/*<i className="fas fa-check icon-show-format ml-2" style={{ color: "#a4f1a4", fontSize: "19px" }} onClick={() => this.HadleClickUpdateQuestion()}></i>*/}
                                                                                                                    <i className="far fa-trash-alt icon-show-format ml-4 mr-4" style={{ color: "#df7d7d", fontSize: "19px" }} onClick={() => this.deleteQuestion(question)}></i>

                                                                                                                    {(question.question_type == "answer_string" || question.question_type == "answer_checkbox" || question.question_type == "answer_text" || question.question_type == "answer_date" || question.question_type == "answer_integer" || question.question_type == "answer_hour" || question.question_type == "answer_radio" || question.question_type == "answer_select" || question.question_type == "answer_formula") && (
                                                                                                                        <React.Fragment>
                                                                                                                            <i className="far fa-copy icon-show-format" style={{ fontSize: "19px" }} onClick={() => this.duplicateQuestion(question)} id={`duplicate${question.id}`}></i>

                                                                                                                            <UncontrolledTooltip placement="top" target={`duplicate${question.id}`}>
                                                                                                                                {"DUPLICAR PREGUNTA"}
                                                                                                                            </UncontrolledTooltip>
                                                                                                                        </React.Fragment>
                                                                                                                    )}

                                                                                                                </div>

                                                                                                                <div className="col-md-3 text-center ui-showFormatCategories-RequiredSelect">
                                                                                                                    <div className="custom-control custom-switch">
                                                                                                                        <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.is_mandatory, "mandatory")} className="custom-control-input" id={`customSwitch${question.id}`} checked={question.is_mandatory} />
                                                                                                                        <label className="custom-control-label" htmlFor={`customSwitch${question.id}`}>Obligatorio</label>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {(this.state.question_config_id == question.id) && (
                                                                                                            <div className="col-md-12 p-2">
                                                                                                                <div className="border-config">
                                                                                                                    <div className="row">

                                                                                                                        {(this.state.showCheckBoxQuestion && this.state.question_id && question.question_type == "answer_checkbox") && (
                                                                                                                            <div className="col-md-3">
                                                                                                                                <div className="custom-control custom-switch">
                                                                                                                                    <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.test_any_true, "test_any_true")} className="custom-control-input" id={`customTestAnyTrue${question.id}`} checked={question.test_any_true} />
                                                                                                                                    <label className="custom-control-label" htmlFor={`customTestAnyTrue${question.id}`}>¿Cumple con una sola respuesta?</label>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )}

                                                                                                                        <div className="col-md-3 pt-1">
                                                                                                                            <div className="custom-control custom-switch">
                                                                                                                                <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.show_table, "show_table")} className="custom-control-input" id={`customShowTable${question.id}`} checked={question.show_table} />
                                                                                                                                <label className="custom-control-label" htmlFor={`customShowTable${question.id}`}>Mostrar en la tabla</label>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className="col-md-3 pt-1">
                                                                                                                            <div className="custom-control custom-switch">
                                                                                                                                <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.show_in_gestion, "show_in_gestion")} className="custom-control-input" id={`customGestion${question.id}`} checked={question.show_in_gestion} />
                                                                                                                                <label className="custom-control-label" htmlFor={`customGestion${question.id}`}>Mostrar en gestión</label>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className="col-md-3">
                                                                                                                            <div className="custom-control custom-switch">
                                                                                                                                <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.is_header, "show_header")} className="custom-control-input" id={`customHeader${question.id}`} checked={question.is_header} />
                                                                                                                                <label className="custom-control-label" htmlFor={`customHeader${question.id}`}>Mostrar en encabezado</label>
                                                                                                                            </div>
                                                                                                                        </div>


                                                                                                                        {question.question_type != "answer_localization" && (
                                                                                                                            <div className="col-md-3 pt-3">
                                                                                                                                <div className="custom-control custom-switch">
                                                                                                                                    <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.is_active, "active")} className="custom-control-input" id={`customActive${question.id}`} checked={question.is_active} />
                                                                                                                                    <label className="custom-control-label" htmlFor={`customActive${question.id}`}>Visible</label>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )}

                                                                                                                        {question.question_type == "answer_localization" && (
                                                                                                                            <div className="col-md-3">
                                                                                                                                <div className="custom-control custom-switch">
                                                                                                                                    <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.is_enable, "is_enable")} className="custom-control-input" id={`customEnable${question.id}`} checked={question.is_enable} />
                                                                                                                                    <label className="custom-control-label" htmlFor={`customEnable${question.id}`}>Visible</label>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )}

                                                                                                                        <div className="col-md-3 pt-3">
                                                                                                                            <div className="custom-control custom-switch">
                                                                                                                                <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.is_private, "private")} className="custom-control-input" id={`customPrivate${question.id}`} checked={question.is_private} />
                                                                                                                                <label className="custom-control-label" htmlFor={`customPrivate${question.id}`}>Es privado</label>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        {question.question_type != "answer_localization" && (
                                                                                                                            <div className="col-md-3 pt-3">
                                                                                                                                <div className="custom-control custom-switch">
                                                                                                                                    <input type="checkbox" onChange={(e) => this.updateState(question.id, !question.is_enable, "is_enable")} className="custom-control-input" id={`customEnable${question.id}`} checked={question.is_enable} />
                                                                                                                                    <label className="custom-control-label" htmlFor={`customEnable${question.id}`}>Está activada</label>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )}

                                                                                                                        {question.is_private && (
                                                                                                                            <React.Fragment>
                                                                                                                                <div className="col-md-12 pt-3">
                                                                                                                                    <input
                                                                                                                                        type="hidden"
                                                                                                                                        name="position_ids"
                                                                                                                                        value={this.state.selectedOptionCargo.position_ids}
                                                                                                                                    />

                                                                                                                                    <Select
                                                                                                                                        isMulti
                                                                                                                                        closeMenuOnSelect={false}
                                                                                                                                        autoFocus={false}
                                                                                                                                        className={`link-form`}
                                                                                                                                        classNamePrefix="select"
                                                                                                                                        placeholder="Seleccione"
                                                                                                                                        name="position_ids"
                                                                                                                                        onChange={this.handleChangeAutocompletePosition}
                                                                                                                                        options={this.state.cargos}
                                                                                                                                        defaultValue={
                                                                                                                                            question.positions.map((item, index) => (
                                                                                                                                                { label: `${item.name}`, value: `${item.id}` }
                                                                                                                                            ))
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                </div>

                                                                                                                                {false && (
                                                                                                                                    <div className="col-md-2">
                                                                                                                                        <button
                                                                                                                                            className="btn-shadow btn btn-info mt-3"
                                                                                                                                            onClick={() => this.updatePositions()}
                                                                                                                                        >
                                                                                                                                            Actualizar cargos
                                                                                                                                        </button>
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                            </React.Fragment>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}

                                                                                                    </React.Fragment>
                                                                                                )}

                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            <div className="col-md-12">
                                                                                                <div className="row">
                                                                                                    <div className="col-md-6">
                                                                                                        <h5>{question.position}) {question.question}</h5>
                                                                                                        {ReactHtmlParser(question.description)}
                                                                                                        {question.file.url && (
                                                                                                            <div className="mb-3">
                                                                                                                {is_imagen(question.file.url) ? (
                                                                                                                    <React.Fragment>
                                                                                                                        <img src={question.file.url} alt="" className="img-thumbnail" style={{ height: "192px" }} />
                                                                                                                        <br />
                                                                                                                        <a
                                                                                                                            onClick={(e) => this.updateDropdozeRemove(e, question.id)}
                                                                                                                            className="btn colo-red"
                                                                                                                            style={{ color: 'red', fontWeight: 600 }}
                                                                                                                        >
                                                                                                                            <i className="fas fa-times"></i> Remover archivo
                                                                                                                        </a>
                                                                                                                    </React.Fragment>
                                                                                                                ) : (
                                                                                                                    <React.Fragment>
                                                                                                                        <a
                                                                                                                            href={question.file.url}
                                                                                                                            onClick={(e) => e.stopPropagation()}
                                                                                                                            className="btn colo-red"
                                                                                                                        >
                                                                                                                            Descargar archivo
                                                                                                                        </a>

                                                                                                                        <a
                                                                                                                            onClick={(e) => this.updateDropdozeRemove(e, question.id)}
                                                                                                                            className="btn colo-red"
                                                                                                                            style={{ color: 'red', fontWeight: 600 }}
                                                                                                                        >
                                                                                                                            <i className="fas fa-times"></i> Remover archivo
                                                                                                                        </a>
                                                                                                                    </React.Fragment>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>

                                                                                                    <div className="col-md-6 pr-0 text-right">
                                                                                                        {(question.question_type == "format_id") && (
                                                                                                            <React.Fragment>
                                                                                                                {question.question_type == "format_id" ? <button className="link-add btn" style={{ textDecoration: "none", display: 'inline-flex', marginTop: '-6px' }} onClick={(e) => this.toogleQuestionFormatCondition(e, "new", question)}>
                                                                                                                    <UncontrolledTooltip placement='bottom' target={`fi${question.id}`}>
                                                                                                                        {"BÚSQUEDA POR FILTRO"}
                                                                                                                    </UncontrolledTooltip>
                                                                                                                    <span
                                                                                                                        id={`fi${question.id}`}
                                                                                                                        className="material-symbols-outlined ui-showFormatCategories-palette"

                                                                                                                        style={{ color: question.has_question_format_conditions_value ? '#57eaaa' : 'inherit' }}
                                                                                                                    >
                                                                                                                        &#xef4f;

                                                                                                                    </span>
                                                                                                                    <span
                                                                                                                        className='ui-showFormatCategories-palette-text ml-2'

                                                                                                                        style={{ color: question.has_question_format_conditions_value ? '#57eaaa' : 'inherit' }}

                                                                                                                    >
                                                                                                                        FILTRAR
                                                                                                                    </span> </button> : null}
                                                                                                                <button className="link-add btn" onClick={(e) => this.toogleQuestionFormatInput(e, "new", question)}>
                                                                                                                    <UncontrolledTooltip placement='bottom' target={`aq${question.id}`}>
                                                                                                                        {"Asociar Respuestas"}
                                                                                                                    </UncontrolledTooltip>
                                                                                                                    <span
                                                                                                                        id={`aq${question.id}`}
                                                                                                                        className="material-symbols-outlined ui-showFormatCategories-palette"

                                                                                                                        style={{ color: question.has_question_format_inputs_values ? '#57eaaa' : 'inherit' }}
                                                                                                                    >
                                                                                                                        &#xe8fe;

                                                                                                                    </span>
                                                                                                                </button>
                                                                                                            </React.Fragment>
                                                                                                        )}

                                                                                                        {this.getButtomAnswerDate(question)}
                                                                                                        {this.getButtomQuestion(question)}
                                                                                                        {this.getButtomQuestionStyle(question)}
                                                                                                        {this.getButtomQuestionAction(question)}
                                                                                                        {this.getButtomQuestionCustomFormula(question)}
                                                                                                        {this.getButtomQuestionDate(question)}
                                                                                                    </div>

                                                                                                    <div className='col-md-12'>
                                                                                                        {this.getTypeQuestion(question)}
                                                                                                    </div>

                                                                                                    {false && (
                                                                                                        <div className="col-md-12 mt-2">
                                                                                                            <div style={question.label.style} className="bw-badge">{question.label.text == "" ? "Agrega una nota" : question.label.text}</div>
                                                                                                            {question.label.edit && (
                                                                                                                <React.Fragment>
                                                                                                                    <IndexEditLabel
                                                                                                                        question={question}
                                                                                                                        update_question={this.props.update_question}
                                                                                                                    />
                                                                                                                    <i className="fas fa-pencil-alt ml-2" id={`PopoverEditLabel${question.id}`} onClick={(e) => this.toogleLabelQuestion(e, question)}></i>
                                                                                                                </React.Fragment>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </div>
                                        </div>
                                    </DragDropContext>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="format-configuration ui-show-formats-formatos-configuration_panel__container card">
                        <div className="card-body ui-show-formats-formatos-configuration_panel-body">
                            <div className="ui-show-formats-formatos-configuration_panel-title__container">
                                <span className="ui-show-formats-formatos-configuration_panel-title">Configuración Formato</span>
                            </div>
                            {false && (
                                <div className="col-12">
                                    <div className="row mt-2 mb-2 ui-showFormatCategories-Config-cardsRow">

                                        <div className="col-6 ui-showFormatCategories-Config-cards">
                                            <a
                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/update_values`}
                                                className="btn btn-primary btn-shadow mr-2 btn-lg btn-block"
                                                data-turbolinks="true"
                                            >
                                                Personalizar Gestión
                                            </a>
                                        </div>

                                        <div className="col-6 ui-showFormatCategories-Config-cards">
                                            <a
                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/format_tree`}
                                                className="btn btn-primary btn-shadow mr-2 btn-lg btn-block"
                                                data-turbolinks="true"
                                            >
                                                Vista de arbol
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="ui-show-formats-formatos-configuration_panel-options-config__container">
                                {panelConfigFormat.map((panel, index) => (
                                    <div className="ui-show-formats-formatos-configuration_panel-options" key={index} onClick={panel.onclick}>
                                        <div className="ui-show-formats-formatos-configuration_panel-icons__container">
                                            <span className={`material-symbols-outlined ${this.state.loadedIcon ? 'loaded' : ''}`}>{panel.icon}</span>
                                        </div>
                                        <div className="ui-show-formats-formatos-configuration_panel-text__container">
                                            <span className='ui-show-formats-formatos-configuration_panel-text'>{panel.name}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Show;