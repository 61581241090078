import React, { Component } from 'react';
import HeaderTitle from "../../../GeneralComponents/HeaderTitle"
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Preloader from '../../../GeneralComponents/LoadingComponen'
import PreloaderMini from '../../../GeneralComponents/Preloaders';
import { isMobile } from 'react-device-detect';
import Filter from './Filter'
import Pagination from "react-js-pagination";
import UploadFile from './UploadFile'
import NumberFormat from 'react-number-format';
import ReactHtmlParser from 'react-html-parser';
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { array, string } from 'prop-types';
import ReactDOM from 'react-dom'
import ModalQr from './ModalQr'
import Select from "react-select";
import TimeField from "react-simple-timefield";
import ModalShowInfo from './ModalShowInfo';
import ModalReadQr from './ModalReadQr'
import QrReader from '../../../GeneralComponents/QrReader/QrReader';
import FormUpgradePlan from '../../settings/Users/FormUpgradePlan';
import { Link } from 'react-router-dom';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';

//Icons google
import GoogleFontLoader from 'react-google-font-loader';

class Index extends Component {
    constructor(props) {
        super(props);

        this.token = document.querySelector("[name='csrf-token']").content;
        this.url = this.props.survey_parent_id == 0 ? `${this.props.domain}/formatos/generate_questions/${this.props.format.token}/${0}/${this.props.custom_view_id != 0 ? this.props.custom_view_id : 0}` : `${this.props.domain}/formatos/generate_questions_surveys/${this.props.format.token}/${this.props.survey_parent_token}/parent`
        this.state = {
            modaReadQr: false,
            modalQrActive: true,
            modalQrData: [],
            isTemplateDownloaded: false,
            hasWritingMode: false,
            isMobile: false,
            formUpdateStyle: {
                width: '220px',
                writingMode: 'horizontal-tb'
            },
            formUpdateRepy: {

            },
            formUpdateRepySelect: {

            },

            formatValues: {

            },

            formUpdateWidth: {
                column_width: 0,
            },

            formUpdateAnswerSelect: {
                answer_type: "",
                value: "",
                label: "",
                selectItems: [],
            },
            stateEditRow: false,
            answer: {},
            modalText: false,
            modalUpgradePlan: false,

            showFilter: false,
            modaReadQr: false,
            question_id: "",
            modal: false,
            showPopover: false,
            sort: false,
            showIcons: false,
            showIconOrient: false,
            selectItems: [],
            editValues: [],
            checked: false,
            modalQr: false,
            options: [],

            answer_id: "",

            formUpdateAnswer: {
                answer_type: "",
                value: "",
                selectItems: [],
            },
            formatValuesFormat: {

            },

            //GER VALUES TO ANSWER TYPE FORMAT
            format_field_loading: false,
            format_field_current: 0,

            innerWidht: 0,
            stateUpdate: false,
            statePDF: false,
            msgUpload: "",
            msgUploadShow: false,
            url: this.props.survey_parent_id == 0 ? `${this.props.domain}/formatos/generate_questions/${this.props.format.token}/${0}/${this.props.custom_view_id != 0 ? this.props.custom_view_id : 0}` : `${this.props.domain}/formatos/generate_questions_surveys/${this.props.format.token}/${this.props.survey_parent_token}/parent`,
            is_updating_all: this.props.format.is_updating_all,
            update_time: "",
            update_has_error: false,

            stateDownloadExcel: false,
            creating_excel: this.props.format.creating_excel,
            last_excel_url: "",
            excel_was_create: false,
        }

        this.handleResize = this.handleResize.bind(this);
    }

    HandleChangeRepy = (e) => {
        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: e.target.value + "&" + (this.state.formUpdateRepySelect[e.target.dataset.id] != undefined ? this.state.formUpdateRepySelect[e.target.dataset.id] : "contiene")
            }
        });
    }

    HandleChangeRepyFormat = (e, type) => {
        console.log("fda", e)
        console.log("fda", type)
        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [type.id]: e.value + "&" + (this.state.formUpdateRepySelect[type.id] != undefined ? this.state.formUpdateRepySelect[type.id] : "contiene")
            }
        });
    }

    HandleChangeUpdateAnswer = (e) => {
        this.setState({
            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                [e.target.name]: e.target.value,
            }
        });
    }

    HandleChangeUpdateAnswerSelect = (e, type) => {
        if (type == "single") {
            this.setState({
                formUpdateAnswerSelect: {
                    ...this.state.formUpdateAnswerSelect,
                    label: e.label,
                    value: e.value
                }
            });
        } else {
            let array = []

            if (e) {
                e.map((item) => (
                    array.push(item.value)
                ))
            }


            this.setState({
                formUpdateAnswerSelect: {
                    ...this.state.formUpdateAnswerSelect,
                    value: array
                }
            });
        }
    }

    selectValue = (answer) => {
        this.loadOptions(answer.survey_id, answer.question_id)
        this.setState({
            answer_id: answer.survey_id,
            question_id: answer.question_id,

            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                answer_type: answer.type,
                value: answer.value,
            }
        })
    }

    selectValueSelect = (answer, is_multiple) => {
        this.loadOptions(answer.survey_id, answer.question_id)

        console.log("answer", answer)

        if (is_multiple) {

            let array = []
            let values = []

            if (answer.value) {
                answer.value.map((item) => (
                    array.push({ label: item.label, value: item.value }),
                    values.push(item.value)
                ))
            }

            this.setState({
                answer_id: answer.survey_id,
                question_id: answer.question_id,

                formUpdateAnswerSelect: {
                    ...this.state.formUpdateAnswerSelect,
                    answer_type: answer.type,
                    value: values,
                },

                editValues: array,
            })
        } else {
            this.setState({
                answer_id: answer.survey_id,
                question_id: answer.question_id,

                formUpdateAnswerSelect: {
                    ...this.state.formUpdateAnswerSelect,
                    answer_type: answer.type,
                    value: answer.value && answer.value[0] ? answer.value[0].value : "",
                    label: answer.value && answer.value[0] ? answer.value[0].label : "",
                },
            })
        }
    }

    loadOptions = (survey_id, question_id) => {
        fetch(`/formatos/get_options_surveys/${survey_id}/${question_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                this.setState({
                    options: data.register,
                })
            });
    }

    cancelValues = () => {
        console.log("hola mudno")
        this.setState({
            selectItems: [],
            answer_id: "",
            question_id: "",
            options: [],
            checked: false,

            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                answer_type: "",
                value: "",
                selectItems: [],
            },

            formUpdateAnswerSelect: {
                answer_type: "",
                value: "",
                label: "",
                selectItems: [],
            },
        })
    }


    HandleChangeCkaditor = (e) => {
        this.setState({
            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                value: e
            }
        })
    }

    updateValue = () => {


        if (this.state.formUpdateAnswer.selectItems.length > 0) {


            Swal.fire({
                title: "¿Estás seguro?",
                text: "¡Editarás todos los registros seleccionados!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#009688",
                cancelButtonColor: "#d33",
                confirmButtonText: "Editar",
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.value) {
                    console.log("jajaj")
                    this.updateValueRequest();
                }
            })
        } else {
            console.log("jajaj1111111")
            this.updateValueRequest();
        }

    }

    updateValueRequest = () => {
        console.log("jejejejejeejejeje")
        this.setState({
            stateEditRow: true
        })
        fetch(`/formatos/update_survey_values/${this.state.answer_id}/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateAnswer), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    stateEditRow: false
                })
                if (this.state.showFilter) {

                    this.props.handlePageChange_filter(this.props.activePage_filter);
                    this.setState({
                        show_count: this.state.show_count - 1
                    })
                }
                else {
                    this.props.handlePageChange_filter(this.props.activePage);
                }
                this.cancelValues();
            });
    }

    updateValueSelect = () => {


        if (this.state.formUpdateAnswerSelect.selectItems.length > 0) {


            Swal.fire({
                title: "¿Estás seguro?",
                text: "¡Editarás todos los registros seleccionados!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#009688",
                cancelButtonColor: "#d33",
                confirmButtonText: "Editar",
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.value) {
                    this.updateValueSelectRequest();
                }
            })
        } else {
            this.updateValueSelectRequest();
        }

    }

    updateValueSelectRequest = () => {
        console.log("jejejejejeejejeje")
        this.setState({
            stateEditRow: true
        })

        const form = {
            answer_type: this.state.formUpdateAnswerSelect.answer_type,
            value: this.state.formUpdateAnswerSelect.value,
            selectItems: this.state.formUpdateAnswerSelect.selectItems
        }


        fetch(`/formatos/update_survey_values/${this.state.answer_id}/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    stateEditRow: false
                })
                if (this.state.showFilter) {

                    this.props.handlePageChange_filter(this.props.activePage_filter);
                    this.setState({
                        show_count: this.state.show_count - 1
                    })
                }
                else {
                    this.props.handlePageChange(this.props.activePage);
                }
                this.cancelValues();
            });
    }





    HandleChangeWidth = (e) => {
        this.setState({
            formUpdateWidth: {
                ...this.state.formUpdateWidth,
                [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
        }
    }

    toogleAnswer = (from, answer) => {
        // console.log(answer)
        if (from == "new") {
            this.setState({ modalText: true, answer: answer })
        } else {
            this.setState({ modalText: false, answer: {} })
        }
    }

    setValues = (question) => {
        this.setState({
            question_id: question.id,
            formUpdateWidth: {
                ...this.state.formUpdateWidth,
                column_width: question.column_width,
            }
        })
    }

    HandleChangeRepySelect = (e) => {
        console.log(e.target.dataset)
        if (e.target.dataset === "answer_date" || e.target.dataset === "answer_autdatetime") {

        }
        var value = this.state.formUpdateRepy[e.target.dataset.id].split("&")[0];
        this.setState({
            formUpdateRepySelect: {
                ...this.state.formUpdateRepySelect,
                [e.target.dataset.id]: e.target.value
            },

            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: value + "&" + e.target.value
            }
        });
    }

    HandleChangeRepyDate1 = (e) => {
        var value = "";
        if (this.state.formUpdateRepy[e.target.dataset.id].includes("&")) {
            var value = `&${this.state.formUpdateRepy[e.target.dataset.id].split("&")[1]}`;
        }

        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: `${e.target.value},${this.state.formUpdateRepy[e.target.dataset.id] != undefined ? this.state.formUpdateRepy[e.target.dataset.id].split(",")[1] : null}${value}`
            }
        });
    }
    HandleChangeRepyDate2 = (e) => {
        var value = "";
        if (this.state.formUpdateRepy[e.target.dataset.id].includes("&")) {
            var value = `&${this.state.formUpdateRepy[e.target.dataset.id].split("&")[1]}`;
        }


        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: `${this.state.formUpdateRepy[e.target.dataset.id] != undefined ? this.state.formUpdateRepy[e.target.dataset.id].split(",")[0] : null},${e.target.value}${value}`
            }
        });
    }



    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    messageSuccessPDF = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            html: `${response.message}`,
            showConfirmButton: true,

        });
    };


    componentWillReceiveProps = (nexProps) => {
        /* this.loadValues(nexProps) */
        let filterPareameters = nexProps.filters
        //COMO EL COMPONENTE SE RENDEREA TRAE NUEVAMENTE LOS FILTROS
        this.setState({
            formUpdateRepy: filterPareameters,
            show_count: nexProps.isFilter ? nexProps.survey_total_filter : nexProps.survey_total,
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    componentDidMount = () => {
        let box = window.innerWidth - 360;
        this.setState({
            innerWidht: box
        });

        this.sendStats();

        this.handleDataQuestionUpdate(this.props.dataQuestion);

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    handleResize() {
        const windowWidth = window.innerWidth;

        const mobileThreshold = 768;
        this.setState({
            isMobile: windowWidth < mobileThreshold,
        });
    }

    componentDidUpdate(prevProps) {
        // Solo reacciona a cambios en dataQuestion
        if (prevProps.dataQuestion !== this.props.dataQuestion) {
            this.handleDataQuestionUpdate(this.props.dataQuestion);
        }
    }

    handleDataQuestionUpdate = (dataQuestion) => {
        if (dataQuestion && dataQuestion.length > 0) {
            const hasVerticalWritingMode = dataQuestion.some(question =>
                question.question_style && question.question_style.writingMode === 'vertical-lr'
            );

            if (hasVerticalWritingMode) {
                this.setState({ hasWritingMode: true });
            } else {
                this.setState({ hasWritingMode: false });
            }
        }
    }

    handleChangeStyleHeightTitleThead = (showFilter, writingMode) => {
        if (showFilter && writingMode) {
            return '180px'
        } else if (!showFilter && writingMode) {
            return '90px'
        } else if (showFilter && !writingMode) {
            return '140px'
        } else if (!showFilter && !writingMode) {
            return '59px'
        }
    }

    sendStats = () => {

        const payLoad = {
            tenant_id: this.props.tenant.id,
            tenant_name: this.props.tenant.name,
            format_name: this.props.format.name,
            format_id: this.props.format.id,
            format_name: this.props.format.name,
            custom_view_id: this.props.custom_view_id,
            surveys_count: this.props.survey_total,
            is_lazy: this.props.format.is_lazy,
            is_custom_view: this.props.custom_view_id == 0 ? false : true,
            datetime_viewed: new Date()
        }

        console.log(payLoad)
        fetch(`https://beewo-services-api-26wtgwq7.uc.gateway.dev/api/v1/survey_stat_index`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(payLoad),
            headers: {
                "auth_token": "1295a2e0-424f-40d8-93dc-881aecba213f",
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                console.log(data)
            });
    }




    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        if (this.state.showFilter) {

                            this.props.handlePageChange_filter(this.props.activePage_filter);
                            this.setState({
                                show_count: this.state.show_count - 1
                            })
                        }
                        else {
                            this.props.handlePageChange(this.props.activePage);
                        }
                        this.messageSuccess(response)
                    });
            }
        });
    };


    getWidth = (value) => {
        if (value > 4 || isMobile) {

            return (((value * 300) + 300).toString()) + "px";

        } else {
            return "100%";
        }

    }

    toogleUpgradePlan = (from) => {
        if (from == "new") {
            this.setState({ modalUpgradePlan: true })
        } else {
            this.setState({ modalUpgradePlan: false })
        }
    }

    generateQuestions = () => {
        if (this.props.count_registers >= this.props.current_plan.records_plan) {
            return this.toogleUpgradePlan("new");
        } else {
            Turbolinks.visit(`${this.url}`, '_self');
        }
    }

    dateDate = (fecha) => {
        var c = new Date();
        var e = new Date(fecha);
        var d = new Date(c.setDate(e.getDate() + 1));
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + (d.getDate()) + " " + 'del' + " " + d.getFullYear();

    }
    dateTime = (fecha) => {

        var c = new Date();
        var e = new Date(fecha);
        var d = new Date(c.setDate(e.getDate() + 1));

        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + (d.getDate()) + " " + 'del' + " " + d.getFullYear() + " / " + d.getHours() + ":" + (d.getMinutes() < 9 ? `0${d.getMinutes()}` : d.getMinutes());


    }
    showFilter = () => {
        this.setState({
            showFilter: true
        })

    }
    cancelFilter = () => {
        this.props.cleanFilters();
        this.props.loadData();
        this.setState({
            showFilter: false

        })

    }

    sortby = (question, order) => {
        var order_value = order ? "ASC" : "DESC"
        fetch(`/formatos/sort_list/${this.props.format.id}/${question}/${order_value}`, {
            method: "PATCH",
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                this.props.loadData();
                this.setState({
                    sort: !this.state.sort
                })

            });
    }

    queryExcel = (data) => {
        let query = Object.keys(data)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
            .join('&');
        return query
    }


    updateWidth = () => {
        if (this.state.formUpdateWidth.column_width) {
            fetch(`/formatos/update_width/${this.state.question_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formUpdateWidth), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({
                        question_id: "",
                        formUpdateWidth: {
                            column_width: 220,
                        }
                    })
                    this.props.loadData()
                });
        }
    }

    updateWritingMode = (question) => {
        let writingMode = '';
        if (question.question_style.writingMode == 'horizontal-tb') {
            writingMode = 'vertical-lr';
        } else {
            writingMode = 'horizontal-tb';
        }
        fetch(`/formatos/update_writing_mode/${question.id}`, {
            method: 'PATCH',
            body: JSON.stringify({ writingMode: writingMode }),
            headers: {
                "X-CSRF-Token": this.token,
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                this.props.loadData();
            })
            .catch(error => console.error("Error:", error));
    }

    // UPDATE SURVEYS
    updateSurveys = () => {
        this.setState({
            stateUpdate: true
        })
        fetch(`/formatos/update_surveys/${this.props.format.id}/${this.props.survey_parent_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify({ selected_items: this.state.selectItems }), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.type === "success") {
                    this.cancelValues();
                    this.setState({
                        selectItems: [],
                        checked: false,
                        stateUpdate: false,
                        is_updating_all: data.is_updating_all,
                    })
                    this.props.loadDataFilterUpdate(this.state.formUpdateRepy)
                    //this.props.loadData()
                    Swal.fire({
                        position: "center",
                        type: `success`,
                        title: `¡Los registros se están actualizando exitosamente!`,
                        showConfirmButton: true,
                        timer: 3000,
                    });

                    this.getSurveysUpdateState(data.format_id);


                }
            });
    }


    getSurveysUpdateState = (format_id) => {

        var timeoutId = null;
        this.setState({
            update_time: "0s",

        })

        timeoutId = setInterval(() => {
            console.log("consultando")
            fetch(`/formatos/get_is_updating_all/${format_id}`, {
                method: 'GET', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (!data.is_updating_all) {
                        clearTimeout(timeoutId);
                        this.props.loadDataFilterUpdate(this.state.formUpdateRepy)
                    }

                    if (data.has_error) {
                        clearTimeout(timeoutId);
                    }


                    this.setState({
                        is_updating_all: data.is_updating_all,
                        update_time: data.update_time,
                        update_has_error: data.has_error
                    })
                });
        }, 3000)
    }

    deleteUpdateErrorWarning = () => {
        fetch(`/formatos/update_error_state/${this.props.format.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify({}), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.type === "success") {
                    this.setState({
                        update_has_error: data.has_error
                    })

                }
            });
    }


    getFormatsValues = (datas) => {

        fetch(`/formatos/get_formats_values/${datas}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    formatValues: {
                        ...this.state.formatValues,
                        [datas]: data
                    }
                })
            });
    }

    loadValues = (nexProps) => {
        nexProps.dataQuestion.filter(value => value.question_type == "format_id").map((question, index) => {
            this.getFormatsValues(question.id)
        })
    }



    /// GET FORMAT VALUES
    handleInputChangeSelect = (value, e) => {
        if (value.length >= 3) {
            this.getFormatsValuesFormat(value, e)
        }
        console.log(value)
        console.log(e)
    }


    handleInputOnFocusSelect = (value, e) => {


        this.getFormatsValuesFormat("bw-blank", e)
        console.log(value, "value")
        console.log(e, "e")
    }


    getFormatsValuesFormat = (value, format_id) => {
        this.setState({
            format_field_current: format_id,
            format_field_loading: true,
        })
        fetch(`/formatos/get_formats_values_format/${format_id}/${value}/0/0`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    format_field_current: 0,
                    format_field_loading: false,
                    formatValuesFormat: {
                        ...this.state.formatValuesFormat,
                        [format_id]: data
                    }
                })
                console.log(data)
            });
    }







    copyLink = () => {
        if (this.props.count_registers >= this.props.current_plan.records_plan) {
            return this.toogleUpgradePlan("new");
        } else {
            Swal.fire({
                position: "center",
                type: `success`,
                title: `Link copiado`,
                showConfirmButton: false,
                timer: 1500,
            });

            navigator.clipboard.writeText(this.url)
            this.setState({
                modalQr: false,
            })
        }
    }

    addAllValues = (e) => {
        console.log(e.target.checked)
        if (e.target.checked) {
            const array = []

            this.props.dataAnswers.map((survey) => (
                array.push(survey.data_to_show.survey)
            ))

            this.setState({
                selectItems: array,
                checked: e.target.checked,
                formUpdateAnswerSelect: {
                    ...this.state.formUpdateAnswerSelect,
                    selectItems: array,
                },
                formUpdateAnswer: {
                    ...this.state.formUpdateAnswer,
                    selectItems: array,
                }

            })
        } else {
            this.setState({
                selectItems: [],
                checked: e.target.checked,
                formUpdateAnswer: {
                    ...this.state.formUpdateAnswer,
                    selectItems: [],
                },
                formUpdateAnswerSelect: {
                    ...this.state.formUpdateAnswerSelect,
                    selectItems: [],
                },
            })
        }
    }


    addItem = (survey, state) => {
        const selectItems = this.state.selectItems

        if (!state) {
            selectItems.push(survey.survey)

            this.setState({
                selectItems: selectItems,
                formUpdateAnswer: {
                    ...this.state.formUpdateAnswer,
                    selectItems: selectItems,
                },
                formUpdateAnswerSelect: {
                    ...this.state.formUpdateAnswerSelect,
                    selectItems: selectItems,
                }
            })
        } else {
            var arr = selectItems
            var index = selectItems.indexOf(parseInt(survey.survey))
            delete arr[index]

            this.setState({
                selectItems: arr.filter(item => item != null),
                formUpdateAnswer: {
                    ...this.state.formUpdateAnswer,
                    selectItems: arr.filter(item => item != null),
                },
                formUpdateAnswerSelect: {
                    ...this.state.formUpdateAnswerSelect,
                    selectItems: arr.filter(item => item != null),
                }
            })
        }
    }

    deleteAll = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡Los registros serán eliminados para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/delete_surveys/${this.state.selectItems}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ selectItems: [], checked: false });
                        if (this.state.showFilter) {

                            this.props.handlePageChange_filter(this.props.activePage_filter);
                            this.setState({
                                show_count: this.state.show_count - 1
                            })
                        }
                        else {
                            this.props.handlePageChange(this.props.activePage);
                        }
                        this.messageSuccess(response)
                    });
            }
        });
    };

    updateView = () => {
        fetch(`/formatos/update_suveys_view/${this.state.selectItems}/${this.props.view}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData()
                this.setState({ selectItems: [], checked: false })
            });
    }




    toogleModalQr = (from, url) => {
        if (from == "new") {
            this.setState({ modalQr: true, url: url })
        } else {
            this.setState({ modalQr: false, url: this.url })
        }
    }


    msgUpload = (mesasage) => {
        this.setState({
            msgUpload: mesasage,
            msgUploadShow: true
        })
    }
    closeMsgUpload = (mesasage) => {
        this.setState({
            msgUploadShow: false
        })
    }


    setTitle = () => {
        if (this.props.view === "ACTIVE") {
            return "Desactivar"
        } else if (this.props.view === "INACTIVE") {
            return "Activar"
        }
    }


    sendPdf = (pdf) => {
        this.setState({
            statePDF: true,

        })
        fetch(`/formatos/send_pdf_all/${this.props.format.id}/${this.props.custom_view_id}/${this.props.survey_parent_id}.pdf?view=${this.props.view}&pdf_id=${pdf.id}&${this.queryExcel(this.state.formUpdateRepy)}&page=${this.props.activePage}`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify({ selected_items: this.state.selectItems }),
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                console.log("hola mundo")
                this.setState({
                    statePDF: false,
                    selectItems: [],
                    checked: false

                })
                this.messageSuccessPDF(data)
            });
    }

    toogleReadQr = (from) => {
        if (from == "new") {
            this.setState({ modaReadQr: true })
        } else {
            this.setState({ modaReadQr: false })
        }
    }

    onResult = (result) => {
        if (!!result) {
            console.log("onResult", result)
            console.log(`${result}/${this.props.format_id}`)
            fetch(`${result}/${this.props.format_id}`, {
                method: 'get', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if (data.status === "success") {
                        this.setState({
                            modalQrActive: false,
                            modalQrData: data.response
                        })
                    }

                });

        }
    }

    actvieQr = () => {
        this.setState({
            modalQrActive: true,
        })
    }

    changeValueToShow = (e) => {
        this.props.handleChangeQuantityToShow(e);
        this.setState({
            selectItems: [],
            checked: false,
            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                selectItems: [],
            },
            formUpdateAnswerSelect: {
                ...this.state.formUpdateAnswerSelect,
                selectItems: [],
            },
        })
    }

    handleDescargarPlantilla = () => {
        // Realiza cualquier otra lógica necesaria aquí
        this.setState({ isTemplateDownloaded: true });
    };




    messageSuccessDownloadExcel = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `El Excel se está generando.`,
            html: `${response.message}`,
            showConfirmButton: true,

        });
    };

    handleDownloadExcel = () => {
        this.setState({
            stateDownloadExcel: true,
            excel_was_create: false,
        })
        fetch(`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/get_surveys_index_excel_view/${this.props.custom_view_id}/${this.props.survey_parent_id}/download?${this.queryExcel(this.state.formUpdateRepy)}&view=${this.props.view}`, {
            method: 'get', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                if (data.type === "success") {
                    this.setState({
                        creating_excel: data.creating_excel,
                    })
                    /*   this.messageSuccessDownloadExcel(data); */
                    this.getDownloadExcelState(this.props.format.id);
                }
            });
    }
    // Verifica el estado del excel
    getDownloadExcelState = (format_id) => {

        var timeoutId = null;


        timeoutId = setInterval(() => {
            console.log("consultando")
            fetch(`/formatos/get_is_download_excel/${format_id}`, {
                method: 'GET', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    if (!data.creating_excel) {
                        clearTimeout(timeoutId);
                        this.setState({
                            excel_was_create: true,
                        })
                    }
                    this.setState({
                        last_excel_url: data.last_excel_url,
                        creating_excel: data.creating_excel,
                    })
                });
        }, 3000)
    }

    handleChangepositionIconsActive = (writingMode, widthColumn, rotate) => {

        let className = 'ml-2';
        if ((writingMode === 'vertical-lr' && widthColumn && rotate) || (writingMode === 'vertical-lr' && !widthColumn && rotate) || writingMode === 'vertical-lr' && widthColumn && !rotate) {
            return 'ui-indexSurveys-td__containers-icons'
        } else if ((writingMode === 'horizontal-tb' && widthColumn && rotate) || (writingMode === 'horizontal-tb' && !widthColumn && rotate) || (writingMode === 'horizontal-tb' && widthColumn && !rotate)) {
            return 'ui-indexSurveys-td__containers-icons-horizont'
        } else {
            return className;
        }
    }

    changeValueColumn = (writingMode, column_width, active_filter) => {
        let style = {
            maxWidth: '',
            transform: '',
            padding: '',
            maxHeight: '',
            textAlign: ''
        };

        if (writingMode === 'vertical-lr' && column_width >= 120 && active_filter) {
            style = {
                maxWidth: '70px',
                transform: 'rotate(-90deg',
                padding: '12px 4px',
                maxHeight: '50px',
                textAlign: 'center'
            };

        } else if (writingMode === 'vertical-lr' && column_width >= 120 && !active_filter) {
            style = {
                maxWidth: '90px',
                transform: 'rotate(-90deg',
                padding: '12px 4px',
                maxHeight: '50px',
                textAlign: 'center'
            };
        } else if (writingMode === 'vertical-lr' && column_width <= 120 && active_filter) {
            style = {
                maxWidth: '90px',
                transform: 'rotate(-90deg',
                padding: '12px 4px',
                maxHeight: '50px',
                textAlign: 'center'
            };
        } else if (writingMode === 'vertical-lr' && column_width <= 120 && !active_filter) {
            style = {
                maxWidth: column_width,
                transform: 'rotate(-90deg',
                padding: '12px 4px',
                maxHeight: '50px',
                textAlign: 'center'
            };
        } else {
            style = {
                maxWidth: '80%',
                transform: 'none',
                padding: '0px',
                maxHeight: '42px',
                textAlign: 'inherit'
            };
        }
        return style;
    }

    render() {
        const config = {
            language: 'es',
            quickInsertEnabled: false,
            colorsHEXInput: true,
            autoFocus: true,
            toolbartop: true,
            linkAlwaysBlank: true,
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            htmlExecuteScripts: true,
            key: this.props.key_text,
            imageUploadToS3: this.props.imageUpload,
            iframe: true,
            tabSpaces: 4,
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'wordPaste'],
            toolbarButtons: {
                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

                    // Alignment of the group in the toolbar.
                    align: 'left',

                    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                    buttonsVisible: 12
                },


                moreParagraph: {
                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                    align: 'left',
                    buttonsVisible: 11
                },

                moreRich: {
                    buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                    align: 'left',
                    buttonsVisible: 10
                },

                moreMisc: {
                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'spellChecker', 'selectAll', 'html', 'help'],
                    align: 'right',
                    buttonsVisible: 9
                }
            }
        }

        const configMobile = {
            language: 'es',
            quickInsertEnabled: false,
            colorsHEXInput: true,
            autoFocus: true,
            toolbartop: true,
            linkAlwaysBlank: true,
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            htmlExecuteScripts: true,
            key: this.props.key_text,
            imageUploadToS3: this.props.imageUpload,
            iframe: true,
            tabSpaces: 4,
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
            toolbarButtons: {
                moreText: {
                    buttons: ['bold',
                        'italic',
                        //   'underline',
                        //    'strikeThrough', 'subscript',
                        //     'superscript',
                        'fontFamily',
                        'fontSize',
                        'textColor',
                        'backgroundColor',
                        //    'inlineClass', 
                        //    'inlineStyle', 
                        //    'clearFormatting'
                    ],

                    // Alignment of the group in the toolbar.
                    align: 'left',

                    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                    buttonsVisible: 12
                },


                moreParagraph: {
                    buttons: ['alignLeft',
                        'alignCenter',
                        // 'formatOLSimple',
                        'alignRight',
                        'alignJustify',
                        // 'formatOL', 'formatUL',
                        // 'paragraphFormat',
                        // 'paragraphStyle',
                        // 'lineHeight', 'outdent',
                        // 'indent',
                        // 'quote'
                    ],
                    align: 'left',
                    buttonsVisible: 11
                },

                // moreRich: {
                //     buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                //     align: 'left',
                //     buttonsVisible: 10
                // },

                // moreMisc: {
                //     buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                //     align: 'right',
                //     buttonsVisible: 9
                // }
            }
        }
        return (
            <React.Fragment>
                <GoogleFontLoader
                    fonts={[
                        {
                            font: 'Material Symbols Outlined',
                            weights: [400],
                        },
                    ]}
                    async={true}
                    defer={true}
                />

                <HeaderTitle
                    title={`${this.props.format.name}`}
                    subTitle={"Registros"}
                    titleNew={"Crear"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    showNew={this.props.dataQuestion.length >= 1 && this.props.estados.create && this.props.state_records != "inactive"}
                    onClickNew={this.generateQuestions}
                    showFilter={this.props.dataQuestion.length >= 1 ? true : false}
                    onClickFilter={this.showFilter}

                    is_index={true}
                    url={this.props.current_url}
                />

                {this.state.modal && (
                    <UploadFile
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Subir archivo"}
                        format={this.props.format}
                        format_category={this.props.format_category}
                        loadData={this.props.loadData}
                        msgUpload={this.msgUpload}
                        closeMsgUpload={this.closeMsgUpload}
                        survey_parent_id={this.props.survey_parent_id ? this.props.survey_parent_id : 0}
                    />
                )}

                {this.state.modalQr && (
                    <ModalQr
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalQr}
                        toggle={this.toogleModalQr}
                        title={"Código QR"}
                        url={this.state.url}
                    />
                )}

                {this.state.modalText && (
                    <ModalShowInfo
                        backdrop={"static"}
                        modal={this.state.modalText}
                        question={this.state.answer}
                        toggle={this.toogleAnswer}
                        title={"Respuesta Parráfo"}
                    />
                )}

                {this.state.modalUpgradePlan && (
                    <FormUpgradePlan
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalUpgradePlan}
                        toggle={this.toogleUpgradePlan}
                        title={"Mejora tu Plan"}
                        messageModal={["Has alcanzado ", <span className='ui_formUpgrade_Plan_message_first'>el límite de registros</span>, " permitidos en tu plan actual"]}
                        plans={this.props.plans}
                        current_plan={this.props.current_plan}
                    />
                )}

                {!this.props.isLoaded ? (
                    this.props.dataQuestion.length >= 1 ? (
                        <React.Fragment>
                            <div className="ui-index-surveys-fordata-options__container card mt-3 mb-2">
                                <div className="ui-index-surveys-fordata-options card-body">
                                    {this.props.survey_parent_id == 0 && (
                                        <React.Fragment>
                                            <UncontrolledTooltip target='bw_back_directory' placement='top'>{'Volver al directorio'}</UncontrolledTooltip>
                                            <a
                                                href={`/formatos/format_categories/${this.props.format.parent_category.token}?categories=${this.props.format.array_categories}`}
                                                className="btn btn-outline-info mobile mr-2"
                                                data-turbolinks="true"
                                                id='bw_back_directory'
                                                style={{ height: '33px' }}
                                            >
                                                <i className="fas fa-angle-left"></i>
                                            </a>
                                        </React.Fragment>
                                    )}
                                    <div className="ui-index-surveys-fordata-options-icon-text">
                                        {/*  {this.props.survey_parent_id != 0 && (
                                            <button
                                                onClick={() => this.updateSurveys()}
                                                className="btn ui-index-surveys-fordata-options-btn"
                                                data-turbolinks="true"
                                            >
                                                <i className="fas fa-sync-alt mr-2"></i>
                                                <span className='ui-indexSurvey-label'>Actualizar</span>
                                            </button>
                                        )} */}
                                        <UncontrolledDropdown className="btn-group ui-index-surveys-fordata-dropdown__container">
                                            <DropdownToggle className="ui-index-surveys-fordata-options-btn-dropdown">
                                                <span className="material-symbols-outlined mr-2 ui-indexSurvey-icons_materials ui-icon_options">&#xe429;</span>
                                                <span className="label ui-indexSurvey-label">Opciones</span>
                                                <span className="material-symbols-outlined ml-1 ui-indexSurvey-icons_materials ui-icons-chevron">&#xe5cc;</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {this.state.showIcons ?
                                                    <DropdownItem
                                                        onClick={() => this.setState({ showIcons: !this.state.showIcons })}
                                                    >
                                                        Cerrar Tamaño Columna
                                                    </DropdownItem>
                                                    :
                                                    <React.Fragment>
                                                        <DropdownItem
                                                            onClick={() => this.setState({ showIcons: !this.state.showIcons })}
                                                        >
                                                            Tamaño Columna
                                                        </DropdownItem>
                                                    </React.Fragment>
                                                }
                                                {this.state.showIconOrient ?
                                                    <DropdownItem
                                                        onClick={() => this.setState({ showIconOrient: !this.state.showIconOrient })}
                                                    >
                                                        Cerrar Orientación Título Columna
                                                    </DropdownItem>
                                                    :
                                                    <DropdownItem
                                                        onClick={() => this.setState({ showIconOrient: !this.state.showIconOrient })}
                                                    >
                                                        Orientación Título Columna
                                                    </DropdownItem>}

                                                {false && (
                                                    <QrReader
                                                        format_id={this.props.format.id}>
                                                    </QrReader>
                                                )}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>

                                        {(this.props.estados.showFormat && this.props.custom_view_id == 0) && (
                                            <a
                                                className="btn ui-index-surveys-fordata-options-btn-edit-format"
                                                data-turbolinks="true"
                                                href={`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}`}
                                                style={{ textAlign: 'left' }}
                                            >
                                                {isMobile ? (<React.Fragment><span className="material-symbols-outlined mr-2 ui-indexSurvey-icons_materials ui-icon_edit">&#xe3c9;</span><span className="ui-indexSurvey-label"> Editar Formato</span></React.Fragment>) : (<React.Fragment><span className="material-symbols-outlined mr-2 ui-indexSurvey-icons_materials ui-icon_edit">&#xe3c9;</span><span className="ui-indexSurvey-label">Editor Formatos</span></React.Fragment>)}
                                            </a>
                                        )}

                                        {true && (
                                            <QrReader format_id={this.props.format.id} className="btn ui-index-surveys-fordata-options-qr"></QrReader>

                                        )}

                                        {(this.props.tenant.tenant_state != "beewoker" && this.props.estados.shareFormat) ? (
                                            <React.Fragment>
                                                {(this.props.state_records != "inactive" && this.props.estados.share_link == true) ? (
                                                    <UncontrolledDropdown className="btn-group ui-index-surveys-fordata-dropdown__container">
                                                        <DropdownToggle className="ui-index-surveys-fordata-options-btn-dropdown">
                                                            <span className="material-symbols-outlined mr-2 ui-indexSurvey-icons_materials ui-icon_share">&#xe80d;</span>
                                                            <span className="label ui-indexSurvey-label">Compartir</span>
                                                            <span className="material-symbols-outlined ml-1 ui-indexSurvey-icons_materials ui-icons-chevron">&#xe5cc;</span>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            {this.props.estados.shareFormat && (
                                                                <DropdownItem onClick={() => this.copyLink()}>
                                                                    Copiar Link
                                                                </DropdownItem>
                                                            )}

                                                            {this.props.estados.shareFormat && (
                                                                <DropdownItem onClick={() => this.toogleModalQr("new", this.url)}>
                                                                    Código QR
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                ) : ('')}
                                            </React.Fragment>
                                        ) : ('')}

                                        {(this.props.format_custom_view_dashboard && this.props.format_custom_view_dashboard.length >= 1) ? (
                                            <UncontrolledDropdown className="btn-group ui-index-surveys-fordata-dropdown__container">
                                                <DropdownToggle className="ui-index-surveys-fordata-options-btn-dropdown">
                                                    <span className="material-symbols-outlined mr-2 ui-indexSurvey-icons_materials ui-icon_chart">&#xe26b;</span>
                                                    <span className="label ui-indexSurvey-label">Estadísticas</span>
                                                    <span className="material-symbols-outlined ml-1 ui-indexSurvey-icons_materials ui-icons-chevron">&#xe5cc;</span>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {this.props.format_custom_view_dashboard.map(dashboard => (
                                                        <DropdownItem key={dashboard.id}
                                                            href={`/formatos/custom_dashboard/${dashboard.id}`}
                                                            target="_blank"
                                                        >
                                                            {dashboard.name}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        ) : ('')}

                                        {(this.props.estados.importFormat || this.props.estados.downloadTemplateFormat || this.props.estados.exportFormat) && (
                                            <React.Fragment>
                                                {this.props.current_plan.name != "beefree" ? (
                                                    <UncontrolledDropdown className="btn-group ui-index-surveys-fordata-dropdown__container">

                                                        <DropdownToggle className="ui-index-surveys-fordata-options-btn-dropdown">
                                                            <span className="material-symbols-outlined mr-2 ui-indexSurvey-icons_materials ui-icon_import-export">&#xe66d;</span>
                                                            <span className="label ui-indexSurvey-label">Importar/Exportar</span>
                                                            <span className="material-symbols-outlined ml-1 ui-indexSurvey-icons_materials ui-icons-chevron">&#xe5cc;</span>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            {this.props.estados.downloadTemplateFormat && (
                                                                <DropdownItem
                                                                    href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/get_surveys_index_excel_template.xlsx`}
                                                                    data-turbolinks="true"
                                                                    onClick={this.handleDescargarPlantilla}
                                                                >
                                                                    Descargar plantilla
                                                                </DropdownItem>
                                                            )}

                                                            {(this.props.estados.importFormat && this.props.current_plan.name != "beefree") && (
                                                                <DropdownItem onClick={() => this.toogle("new")} >
                                                                    <UncontrolledTooltip target='bw-info_layout' placement='top'>{'Para importar registros desde Excel primero debes descargar la plantilla'}</UncontrolledTooltip>
                                                                    <span>
                                                                        Importar registros desde excel
                                                                    </span>
                                                                    <i className="fas fa-info-circle ml-3 text-secondary" id='bw-info_layout'></i>
                                                                </DropdownItem>
                                                            )}

                                                            {this.props.estados.exportFormat && this.state.show_count > 0 && (
                                                                <DropdownItem
                                                                    onClick={() => this.handleDownloadExcel()}
                                                                >

                                                                    Exportar registros a excel
                                                                </DropdownItem>
                                                            )}

                                                            {this.props.format_pdfs.map(pdf => (
                                                                <DropdownItem
                                                                    onClick={() => this.sendPdf(pdf)}
                                                                    key={pdf.id}
                                                                >
                                                                    Enviar PDF {pdf.name}
                                                                </DropdownItem>
                                                            ))}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                ) : (
                                                    <button
                                                        onClick={() => this.toogleUpgradePlan("new")}
                                                        className="btn ui-index-surveys-fordata-options-btn"
                                                        data-turbolinks="true"
                                                    >
                                                        <i className="fas fa-file mr-2"></i>
                                                        <span className='ui-indexSurvey-label'>Importar/Exportar</span>
                                                    </button>
                                                )}

                                            </React.Fragment>
                                        )}

                                        {this.props.custom_view_id == 0 && (
                                            <UncontrolledDropdown className="btn-group ui-index-surveys-fordata-dropdown__container">
                                                <DropdownToggle className="ui-index-surveys-fordata-options-btn-dropdown">
                                                    <span className="material-symbols-outlined mr-2 ui-indexSurvey-icons_materials ui-icon_eye">&#xe8f4;</span>
                                                    <span className="label ui-indexSurvey-label">Vistas</span>
                                                    <span className="material-symbols-outlined ml-1 ui-indexSurvey-icons_materials ui-icons-chevron">&#xe5cc;</span>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {true && (
                                                        <DropdownItem
                                                            href={`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/surveys?view=ALL`}
                                                            data-turbolinks="true"
                                                        >
                                                            Ver todos
                                                        </DropdownItem>
                                                    )}

                                                    {true && (
                                                        <DropdownItem
                                                            href={`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/surveys?view=ACTIVE`}
                                                            data-turbolinks="true"
                                                        >
                                                            Activos
                                                        </DropdownItem>
                                                    )}

                                                    {true && (
                                                        <DropdownItem
                                                            href={`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/surveys?view=INACTIVE`}
                                                            data-turbolinks="true"
                                                        >
                                                            Inactivos
                                                        </DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}

                                    </div>
                                </div>
                            </div>
                            {this.state.stateEditRow && (
                                <div className='survey-index-global-messages alert alert-success' style={{ display: "flex", justifyContent: "center" }}>
                                    <div className='mr-2'>Actualizando  </div><PreloaderMini />
                                </div>
                            )}
                            {this.state.statePDF && (
                                <div className='survey-index-global-messages alert alert-success' style={{ display: "flex", justifyContent: "center" }}>
                                    <div className='mr-2'>Enviando PDF  </div><PreloaderMini />
                                </div>

                            )}
                            <div className="content main-card mb-3 card mh-400 mt-4 card-style bw-index-container" >
                                {/*                                 <div className="alert alert-warning mb-2">Hemos actualizado la configuración de la plataforma para mostrar 30 registros por página en todas las listas. Esta modificación se realizó con el propósito de mejorar el rendimiento general. Sin embargo, si necesitas ver 100 registros por página, puedes ajustar esta configuración de acuerdo a tus necesidades.</div>
 */}
                                {this.state.is_updating_all && (
                                    <div className="alert alert-info ">
                                        <div className="d-flex justify-content-start bw-wd-200 updating-surveys-msg">
                                            <span className='mr-2'>Actualizando...{this.state.update_time ? this.state.update_time : "0s"}</span> <PreloaderMini />
                                        </div>
                                    </div>
                                )

                                }
                                {this.state.creating_excel && (
                                    <div className="alert alert-info ">
                                        <div className="d-flex justify-content-start bw-wd-200 updating-surveys-msg">
                                            <span className='mr-2'>Generando Excel...</span> <PreloaderMini />
                                        </div>
                                    </div>
                                )}
                                {this.state.excel_was_create && (
                                    <div className="alert alert-info ">
                                        <div className="d-flex justify-content-start updating-surveys-msg align-items-center">
                                            <span className='mr-2'> El Excel se generó exitosamente </span> <a className='btn btn-primary' href={this.state.last_excel_url}>Descargar</a>
                                        </div>
                                    </div>
                                )
                                }

                                {this.state.update_has_error && (
                                    <div className="alert alert-danger">Hubo un error <button onClick={() => this.deleteUpdateErrorWarning()} className='btn btn-danger ml-2'>Eliminar este mensaje</button></div>
                                )}


                                <div className="card-body ui-index-surveys-fordata-register-card-body" style={{ display: "grid", justifyContent: "center" }}>
                                    <div className="ui-index-surveys-fordata-register-check__container">
                                        <div className="ui-index-surveys-fordata-check">


                                            {this.state.selectItems.length >= 1 && (
                                                <React.Fragment>
                                                    <button
                                                        disabled={this.state.is_updating_all}
                                                        onClick={() => this.updateSurveys()}
                                                        className="btn bbtn btn-info mr-2"
                                                        data-turbolinks="true"
                                                    >
                                                        <div style={{ display: "flex", justifyConten: "space-between" }}>
                                                            <i className="fas fa-sync-alt mr-2" ></i>
                                                            <span className='ui-indexSurvey-label mr-1' >Actualizar </span>
                                                            {this.state.stateUpdate && (
                                                                <PreloaderMini />
                                                            )}

                                                        </div>
                                                    </button>
                                                    {(this.props.view != "ALL" && this.props.view != null) && (
                                                        <button
                                                            style={{ display: "none" }}
                                                            className="btn-shadow btn btn-info"
                                                            onClick={() => this.updateView()}
                                                        >
                                                            {this.setTitle()} {this.state.selectItems.length} registros
                                                        </button>
                                                    )}

                                                    {this.props.estados.delete && (
                                                        <button
                                                            className="btn-shadow btn btn-danger ml-2"
                                                            onClick={() => this.deleteAll()}
                                                        >
                                                            Eliminar {this.state.selectItems.length} registros
                                                        </button>
                                                    )}

                                                </React.Fragment>
                                            )}
                                            <div className="ui-index-surveys-fordata-count-register__container">
                                                <span>{this.state.show_count} registros</span>
                                            </div>
                                        </div>
                                        <div className="ui-index-surveys-fordata-regiter-select">
                                            <span>Mostrar</span>
                                            <select
                                                value={this.props.countPage_filter}
                                                onChange={this.changeValueToShow}
                                                className="form form-control mr-2"
                                                name="quantity_to_show"
                                                style={{ width: "75px", height: "33px", border: "1px solid #16aaff" }}
                                            >
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                                <option value="60">60</option>
                                                <option value="100">100</option>

                                            </select>
                                            <span>Registros</span>
                                        </div>
                                    </div>
                                    {!this.props.isLoaded ? (
                                        <React.Fragment>
                                            <div className="content-table ui-index-surveys-fordata-table__container" id="for-data" >
                                                {this.props.count_registers >= this.props.current_plan.records_plan && (
                                                    <div className="alert alert-primary" role="alert">
                                                        <b>Registros permitidos {this.props.count_registers} / {this.props.current_plan.records_plan}</b>
                                                    </div>
                                                )}

                                                {this.state.msgUploadShow && (
                                                    <div className="alert alert-warning msg-show-upload"><div>{this.state.msgUpload}</div> <hr /><button className="btn btn-warning" onClick={this.closeMsgUpload}>Cerrar</button></div>

                                                )}
                                                <table className="table table-hover table-striped table-fd table-survey-index" id="sampleTable" style={{ width: this.props.dataQuestion.length > 4 && (this.state.innerWidht < this.props.tableWidth) ? this.props.tableWidth : "100%", tableLayout: this.props.dataQuestion.length > 4 && (this.state.innerWidht < this.props.tableWidth) ? "initial" : "fixed", maxWidth: '100%' }}>
                                                    {(this.props.dataQuestion.length >= 1) && (
                                                        <thead className='ui-index-surveys-fordata-table-header__container' style={{ height: this.handleChangeStyleHeightTitleThead(this.state.showFilter, this.state.hasWritingMode) }}>
                                                            {this.state.showFilter && (
                                                                <tr className='table-survey-index__tr ui-index-surveys-fordata-filter' style={{ position: 'relative' }}>
                                                                    <td colSpan='2' className='survey-index__td'>
                                                                        <div className="filter-actions">
                                                                            <button className="btn btn-primary w-100p mt-1 action-filter-index" onClick={() => this.props.loadDataFilter(this.state.formUpdateRepy)}>Filtrar</button>
                                                                            <button className="btn btn-outline-danger w-100p mt-2 cancel-filter-index ui-index-surveys-fordata-filter-btn_cancel" onClick={() => this.cancelFilter()} >Cancelar</button>
                                                                        </div>
                                                                    </td>
                                                                    {this.props.dataQuestion.length >= 1 ? (
                                                                        this.props.dataQuestion.filter(value => value.show_filter).map((question, index) => (
                                                                            (question.question_type != "") ? (
                                                                                <td key={index}>
                                                                                    <div className="filter-inputs ui-indexsurvey-formatos-filter_inputs" style={{ height: '75px', marginBottom: '7px' }}>
                                                                                        <Filter question={question}
                                                                                            isDashboard={false}
                                                                                            users={this.props.users}
                                                                                            procesos={this.props.procesos}
                                                                                            positions={this.props.positions}
                                                                                            HandleChangeRepy={this.HandleChangeRepy}
                                                                                            HandleChangeRepyFormat={this.HandleChangeRepyFormat}
                                                                                            HandleChangeRepySelect={this.HandleChangeRepySelect}
                                                                                            formUpdateRepy={this.state.formUpdateRepy}
                                                                                            formUpdateRepySelect={this.state.formUpdateRepySelect}
                                                                                            HandleChangeRepyDate1={this.HandleChangeRepyDate1}
                                                                                            HandleChangeRepyDate2={this.HandleChangeRepyDate2}
                                                                                            formValues={this.state.formatValues}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            ) : (
                                                                                <td></td>
                                                                            )))
                                                                    ) : (
                                                                        <div></div>
                                                                    )}
                                                                </tr>
                                                            )}
                                                            <tr style={{ zIndex: 0 }}>
                                                                {/* <div className='ui-index-surveys-fordata-td-sticky__container-title'>
                                                                    <td width="30px" style={{display: 'flex', alignItems: 'center'}} className="td-header ui-index-surveys-fordata-table-header">
                                                                        <input type="checkbox" onChange={(e) => this.addAllValues(e)} checked={this.state.checked} />
                                                                    </td>
                                                                    <td width="42px" className="td-header ui-index-surveys-fordata-table-header"></td>
                                                                </div> */}
                                                                <td width="30px" className="td-header ui-index-surveys-fordata-table-header">
                                                                    <input type="checkbox" onChange={(e) => this.addAllValues(e)} checked={this.state.checked} />
                                                                </td>
                                                                <td width="42px" className="td-header ui-index-surveys-fordata-table-header" style={{ zIndex: 2 }}></td>




                                                                {this.props.dataQuestion.length >= 1 ? (
                                                                    this.props.dataQuestion.map(question => (

                                                                        <td key={question.id} className="td-header ui-index-surveys-fordata-table-header" style={{ width: this.props.dataQuestion.length > 4 ? question.column_width + "px" : "" }}>

                                                                            <div className="gm-sort" style={{ alignItems: 'center' }}>
                                                                                <UncontrolledTooltip placement="top" target={`is${question.id}`}>
                                                                                    {question.question}
                                                                                </UncontrolledTooltip>
                                                                                <span className="ui-indexSurvey-td" id={`is${question.id}`} style={this.changeValueColumn(question.question_style.writingMode, question.column_width, this.state.showFilter)}>
                                                                                    {question.question}
                                                                                </span>
                                                                                <div className={this.handleChangepositionIconsActive(question.question_style.writingMode, this.state.showIcons, this.state.showIconOrient)}>
                                                                                    {
                                                                                        // question.question_style.writingMode === 'horizontal-tb' 
                                                                                        true && (
                                                                                            <a className="sort-up" href="javascript:void(0)" onClick={() => this.sortby(question.id, this.state.sort)}>
                                                                                                <i style={{ position: "relative", top: "-3px", fontSize: "12px" }} className="fas fa-sort"></i>
                                                                                            </a>
                                                                                        )}

                                                                                    {this.state.showIcons && (
                                                                                        <React.Fragment>
                                                                                            <i style={{ position: "relative", top: "-3px", color: "#ffff", cursor: "pointer" }} id={`PopoverLegacy${question.id}`} className="fas fa-text-width" onClick={() => this.setValues(question)}></i>
                                                                                            <UncontrolledTooltip placement="top" target={`PopoverLegacy${question.id}`}>
                                                                                                {"ACTUALIZAR TAMAÑO DE COLUMNAS"}
                                                                                            </UncontrolledTooltip>

                                                                                            <UncontrolledPopover isOpen={this.state.question_id == question.id} placement="top" target={`PopoverLegacy${question.id}`}>
                                                                                                <PopoverHeader>{"Cambiar tamaño"}<a className="close-popover" onClick={() => this.setState({ question_id: "", formUpdateWidth: { column_width: 0 } })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                                                                <PopoverBody>
                                                                                                    <div style={{ width: "289px" }}>
                                                                                                        <input
                                                                                                            type="number"
                                                                                                            name="column_width"
                                                                                                            value={this.state.formUpdateWidth.column_width}
                                                                                                            onChange={this.HandleChangeWidth}
                                                                                                            className={`form form-control`}
                                                                                                            placeholder="Tamaño"
                                                                                                        />

                                                                                                        <div className="mt-3">
                                                                                                            <button
                                                                                                                className="btn-shadow btn btn-info btn-block"
                                                                                                                disabled={this.state.formUpdateWidth.column_width ? false : true}
                                                                                                                onClick={() => this.updateWidth()}
                                                                                                            >
                                                                                                                {!this.state.formUpdateWidth.column_width ? "Debe de tener un valor" : "Actualizar valor"}
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </PopoverBody>
                                                                                            </UncontrolledPopover>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    {this.state.showIconOrient && (
                                                                                        <React.Fragment>
                                                                                            <UncontrolledTooltip placement="top" target={`PopoverLegacy2${question.id}`}>
                                                                                                {question.question_style.writingMode === 'horizontal-tb' ? "ROTAR TITULO A VERTICAL" : "ROTAR TITULO A HORIZONTAL"}
                                                                                            </UncontrolledTooltip>
                                                                                            <i style={{ position: "relative", top: "-3px", color: "#ffff", cursor: "pointer", fontSize: '13px' }} id={`PopoverLegacy2${question.id}`} className="fas fa-undo-alt" onClick={() => this.updateWritingMode(question)}></i>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </td>


                                                                    ))
                                                                ) : (<div></div>
                                                                )}
                                                            </tr>
                                                        </thead>
                                                    )}

                                                    < tbody>
                                                        {
                                                            this.props.dataAnswers.length >= 1 ? (
                                                                this.props.dataAnswers.filter(data => data.data_to_show != "{}").map(survey => (
                                                                    <tr className={`ui-indexSurvery-tr ${(this.state.selectItems.includes(survey.id) || this.state.answer.survey_id == survey.id) ? "ui-background-color_survey" : 'ui-background-survey-color-origin'}`} key={survey.id}>
                                                                        {/*   <div className='ui-index-surveys-fordata-td-sticky__container-data'> */}
                                                                        <td style={{ width: "30px" }}>
                                                                            <input type="checkbox" style={{ position: "relative" }} value={survey.data_to_show.survey} onChange={() => this.addItem(survey.data_to_show, this.state.selectItems.includes(survey.data_to_show.survey))} checked={this.state.selectItems.includes(survey.data_to_show.survey)} />
                                                                        </td>
                                                                        <td className="text-center" style={{ width: "30px" }}>
                                                                            {true && (
                                                                                <UncontrolledDropdown direction="up" placement="right" className="btn-group ui-index-surveys-fordata-dropdown__container">
                                                                                    <DropdownToggle style={{ paddingTop: '3px' }} className="btn-shadow btn btn-info">
                                                                                        <i className="fas fa-bars" style={{ verticalAlign: 'top' }}></i>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu className='ui-index-survey-dropdown-menu-bars'>
                                                                                        {true && (
                                                                                            <DropdownItem
                                                                                                href={`/formatos/format_categories/${this.props.format_category.token}/formats/${survey.data_to_show.format.token}/gestion/${survey.token}/${this.props.custom_view_id}`}
                                                                                                data-turbolinks="true"
                                                                                            >
                                                                                                Gestionar
                                                                                            </DropdownItem>
                                                                                        )}

                                                                                        {this.props.estados.edit && (
                                                                                            <DropdownItem
                                                                                                href={`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/surveys/${survey.data_to_show.token}${this.props.survey_parent_id != 0 ? "?type=parent" : ""}${this.props.custom_view_id != 0 ? "?type=own&custom_view_id=" + this.props.custom_view_id : ""} `}
                                                                                                data-turbolinks="true"
                                                                                            >
                                                                                                Editar
                                                                                            </DropdownItem>
                                                                                        )}

                                                                                        {this.props.estados.delete && (
                                                                                            <DropdownItem
                                                                                                onClick={() => this.delete(survey.id)}
                                                                                            >
                                                                                                Eliminar
                                                                                            </DropdownItem>
                                                                                        )}
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            )}
                                                                        </td>
                                                                        {/*   </div> */}


                                                                        {survey.data_to_show.answers.filter(value => ((value.show_table || this.props.custom_view_id != 0) && (this.props.dataQuestion.find(question => question.id == value.question_id) ? true : false))).map(answer => (
                                                                            <td key={answer.id} className="td-fordata-info" style={{ background: answer.style ? answer.style.background : "none", color: answer.style ? answer.style.color : "none", textAlign: this.props.dataQuestion.find(question => question.id === answer.question_id)?.question_style?.writingMode === 'vertical-lr' ? 'center' : 'inherit' }}>

                                                                                {(answer.type == "na") && (
                                                                                    <span>{answer.value != null ? answer.value : "null"}</span>

                                                                                )}

                                                                                {/*   {(answer.type != "answer_integer" && answer.type != "url" && answer.type != "answer_formula" && answer.type != "answer_file" && answer.type != "answer_checkbox" && answer.type != "answer_datetime" && answer.type != "na" && answer.type != "answer_date" && answer.type != "answer_text" && answer.type != "answer_radio" && answer.type != "answer_select" && answer.type != "answer_string" && answer.type != "answer_hour" && answer.type != "answer_signature" && answer.type != "answer_user_id" && answer.type != "proceso_id" && answer.type != "position_id" && answer.type != "format_id") && (
                                                                                    <span>{typeof answer.value !== 'object' ? `${answer.value}` : ""}</span>
                                                                                )}
    */}

                                                                                {/* 1 ANSWER STRING */}
                                                                                {answer.type == "email" && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "email" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    name="value"
                                                                                                    className="form form-control"
                                                                                                    onChange={this.HandleChangeUpdateAnswer}
                                                                                                    value={this.state.formUpdateAnswer.value}
                                                                                                    style={{ width: "90%", display: "inherit" }}
                                                                                                />
                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            <div className='input-value-container' onDoubleClick={() => this.selectValue(answer)} > < div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}> <span style={{ flex: "1", paddingBottom: '7px' }} >{typeof answer.value !== 'object' ? answer.value : ""}</span><span style={{ minWidth: "40px", minHeight: "40px", color: answer.check_test[1] }}><i className={answer.check_test[0]}></i></span></div></div>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {/* 1 ANSWER STRING */}
                                                                                {answer.type == "answer_string" && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_string" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <input
                                                                                                    disabled={this.state.stateEditRow}
                                                                                                    type="text"
                                                                                                    name="value"
                                                                                                    className="form form-control"
                                                                                                    onChange={this.HandleChangeUpdateAnswer}
                                                                                                    value={this.state.formUpdateAnswer.value}
                                                                                                    style={{ width: "90%", display: "inherit" }}
                                                                                                />
                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            <div className='input-value-container' onDoubleClick={() => this.selectValue(answer)} > < div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}> <span style={{ flex: "1", paddingBottom: '7px' }} >{typeof answer.value !== 'object' ? answer.value : ""}</span><span style={{ minWidth: "40px", minHeight: "40px", color: answer.check_test[1] }}><i className={answer.check_test[0]}></i></span></div></div>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {/* 2 ANSWER TEXT */}
                                                                                {answer.type == "answer_text" && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_text" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <FroalaEditor
                                                                                                    model={this.state.formUpdateAnswer.value}
                                                                                                    config={this.state.isMobile ? configMobile : config}
                                                                                                    onModelChange={this.HandleChangeCkaditor}
                                                                                                />
                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            <React.Fragment>
                                                                                                {answer.value != '' ? (
                                                                                                    <React.Fragment>
                                                                                                        <div className="ui-indexSurvey-container-onclick-text">
                                                                                                            <div className="gm-ellipsis ui-indexSurvey-answerText ui-p" style={{ maxWidth: (answer.column_width - 20) + "px", maxHeight: "44px", minwidth: "50px", minHeight: "45px", cursor: "pointer" }}>
                                                                                                                <i className="fas fa-info-circle" onClick={() => this.toogleAnswer("new", answer)} ></i>
                                                                                                                <span className="ui-indexSurvey-answerText-span" onDoubleClick={() => this.selectValue(answer)}>{ReactHtmlParser(answer.value)}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </React.Fragment>
                                                                                                ) :
                                                                                                    (
                                                                                                        <div className="gm-ellipsis ui-indexSurvey-answerText ui-p" onDoubleClick={() => this.selectValue(answer)} style={{ maxWidth: (answer.column_width - 20) + "px", maxHeight: "44px", minwidth: "50px", minHeight: "45px", cursor: "pointer" }}>

                                                                                                        </div>
                                                                                                    )}
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                    </React.Fragment>

                                                                                )}

                                                                                {/* 3 4 ANSWER INTEGER AND FORMULE */}
                                                                                {((answer.type == "answer_integer" || answer.type == "answer_formula") /*&&  answer.is_currency != true */) && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_integer" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    name="value"
                                                                                                    className="form form-control"
                                                                                                    onChange={this.HandleChangeUpdateAnswer}
                                                                                                    value={this.state.formUpdateAnswer.value}
                                                                                                    style={{ width: "90%", display: "inherit" }}
                                                                                                />
                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            <span style={{ minWidth: "40px", minHeight: "40px", display: "block", cursor: "pointer" }} onDoubleClick={() => this.selectValue(answer)}>

                                                                                                {answer.is_currency ? (

                                                                                                    <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={answer.value} />
                                                                                                ) : (
                                                                                                    <React.Fragment>{answer.value}</React.Fragment>

                                                                                                )}
                                                                                            </span>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {/*  5 ANSWER MULTIPLE OPTIONS  */}
                                                                                {(answer.type == "answer_radio") && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_radio" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <select
                                                                                                    disabled={this.state.stateEditRow}
                                                                                                    name="value"
                                                                                                    className="form form-control"
                                                                                                    onChange={this.HandleChangeUpdateAnswer}
                                                                                                    value={this.state.formUpdateAnswer.value}
                                                                                                >
                                                                                                    <option value="">Seleccione un valor</option>
                                                                                                    {this.state.options.map((value, index) => (
                                                                                                        <option value={value.name} key={index}>{value.name}</option>
                                                                                                    ))}
                                                                                                </select>

                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            < div onDoubleClick={() => this.selectValue(answer)} style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}> <span style={{ flex: "1" }} >{answer.value ? answer.value.toString().replace(",", "") : ""} </span> <span style={{ minWidth: "40px", minHeight: "20px", color: answer.check_test[1] }}><i className={answer.check_test[0]}></i></span> </div>


                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}



                                                                                {/*  6 ANSWER FILE */}
                                                                                {answer.type == "answer_file" && (
                                                                                    <a
                                                                                        href={answer.value}
                                                                                        target="_blanck"
                                                                                    >
                                                                                        {answer.value != null && answer.value != "" ? "Ver Archivo" : ""}

                                                                                    </a>
                                                                                )}

                                                                                {/* 7 ANSWER CHECKBOX */}
                                                                                {(answer.type == "answer_checkbox") && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswerSelect.answer_type == "answer_checkbox" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <input
                                                                                                    type="hidden"
                                                                                                    name="position_ids"
                                                                                                    value={this.state.formUpdateAnswerSelect.value}
                                                                                                />

                                                                                                <Select
                                                                                                    onChange={(e) => this.HandleChangeUpdateAnswerSelect(e, "multi")}
                                                                                                    options={this.state.options}
                                                                                                    isMulti
                                                                                                    closeMenuOnSelect={false}
                                                                                                    autoFocus={false}
                                                                                                    className={`link-form`}
                                                                                                    classNamePrefix="select"
                                                                                                    placeholder="Seleccione"
                                                                                                    name="position_ids"
                                                                                                    defaultValue={this.state.editValues}
                                                                                                />

                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValueSelect()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            <div onDoubleClick={() => this.selectValueSelect(answer, true)} style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}>
                                                                                                <div style={{ width: "172px", minHeight: "20px", display: "flex", justifyContent: "space-between" }}>
                                                                                                    {answer.value && (
                                                                                                        <div>
                                                                                                            {answer.value && (
                                                                                                                <React.Fragment>
                                                                                                                    {answer.value.map((format, index) => (
                                                                                                                        <span key={index}>{index == 0 ? "" : ", "} {format ? format.label : ""}</span>
                                                                                                                    ))}
                                                                                                                </React.Fragment>
                                                                                                            )}

                                                                                                        </div>
                                                                                                    )}
                                                                                                    <span style={{ minWidth: "40px", minHeight: "40px", color: answer.check_test[1] }}><i className={answer.check_test[0]}></i></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        )}
                                                                                    </React.Fragment>

                                                                                )}

                                                                                {/*  8 ANSWER DATE */}
                                                                                {answer.type == "answer_date" && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_date" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <input
                                                                                                    disabled={this.state.stateEditRow}
                                                                                                    type="date"
                                                                                                    name="value"
                                                                                                    className="form form-control"
                                                                                                    onChange={this.HandleChangeUpdateAnswer}
                                                                                                    value={this.state.formUpdateAnswer.value}
                                                                                                    style={{ width: "90%", display: "inherit" }}
                                                                                                />
                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            <span style={{ minWidth: "40px", minHeight: "40px", display: "block", cursor: "pointer" }} onDoubleClick={() => this.selectValue(answer)}>{answer.value != null && answer.value != "" ? answer.value : ""}</span>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {/* 9 ANSWER HOUR */}
                                                                                {answer.type == "answer_hour" && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_hour" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>

                                                                                                <TimeField
                                                                                                    onChange={this.HandleChangeUpdateAnswer}
                                                                                                    value={this.state.formUpdateAnswer.value}
                                                                                                    input={
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            name="value"
                                                                                                            className="form form-control"
                                                                                                            style={{ width: "90%", display: "inherit" }}
                                                                                                        />
                                                                                                    }
                                                                                                />
                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            <span onClick={() => this.selectValue(answer)} style={{ minWidth: "100%", minHeight: "20px", display: "block", cursor: "pointer" }}>{typeof answer.value !== 'object' ? answer.value : ""}</span>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {/* 10 ANSWER DATETIME AUTOMATIC */}
                                                                                {answer.type == "answer_autdatetime" && (
                                                                                    <span>{answer.value != null && answer.value != "" ? answer.value : ""}</span>
                                                                                )}

                                                                                {/* 11  ANSWER LINK */}
                                                                                {(answer.type == "url") && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "url" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    name="value"
                                                                                                    className="form form-control"
                                                                                                    onChange={this.HandleChangeUpdateAnswer}
                                                                                                    value={this.state.formUpdateAnswer.value}
                                                                                                    style={{ width: "90%", display: "inherit" }}
                                                                                                />
                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            <React.Fragment>
                                                                                                <div style={{ width: "100%", cursor: "pointer", minHeight: "40px" }} onDoubleClick={() => this.selectValue(answer)} className='ui-indexSurvey-answer_url__container'>
                                                                                                    {answer.value.length >= 1 && (
                                                                                                        <button
                                                                                                            className="ml-2 ui-indexSurvey-btn-qr"
                                                                                                            onClick={() => this.toogleModalQr("new", answer.value)}
                                                                                                        >
                                                                                                            <UncontrolledTooltip target={`generate${this.state.answer_id}`}>{'Ver QR del Enlace'}</UncontrolledTooltip>
                                                                                                            <i className="fas fa-qrcode" id={`generate${this.state.answer_id}`}></i>
                                                                                                        </button>
                                                                                                    )}
                                                                                                    <a href={answer.value} target="_blank" className='ui-indexSurvey-a_questions'>{answer.value}</a>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}



                                                                                {/* 12 ANSWER SELECT */}
                                                                                {answer.type == "answer_select" && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_select" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <select
                                                                                                    disabled={this.state.stateEditRow}
                                                                                                    name="value"
                                                                                                    className="form form-control"
                                                                                                    onChange={this.HandleChangeUpdateAnswer}
                                                                                                    value={this.state.formUpdateAnswer.value}
                                                                                                >
                                                                                                    <option value="">Seleccione un valor</option>
                                                                                                    {this.state.options.map((value, index) => (
                                                                                                        <option key={index} value={value.name}>{value.name}</option>
                                                                                                    ))}
                                                                                                </select>
                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            < div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onDoubleClick={() => this.selectValue(answer)}> <span style={{ flex: "1" }} >{typeof answer.value !== 'object' ? answer.value : ""}</span> <span style={{ minWidth: "40px", minHeight: "20px", color: answer.check_test[1] }}><i className={answer.check_test[0]}></i></span> </div>

                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {/* 13 ANSWER USER */}
                                                                                {answer.type == "answer_user_id" && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "answer_user_id" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <select
                                                                                                    name="value"
                                                                                                    className="form form-control"
                                                                                                    onChange={this.HandleChangeUpdateAnswer}
                                                                                                    value={this.state.formUpdateAnswer.value}
                                                                                                >
                                                                                                    <option value="">Seleccione un valor</option>
                                                                                                    {this.props.users.map((value, index) => (
                                                                                                        <option key={index} value={value.id}>{value.name}</option>
                                                                                                    ))}
                                                                                                </select>
                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            < div onDoubleClick={() => this.selectValue(answer)} style={{ display: "flex", justifyContent: "space-between", minWidth: "40px", minHeight: "20px", cursor: "pointer" }}> <span style={{ flex: "1" }} >{typeof answer.value !== 'object' ? answer.value : ""}</span> <span style={{ width: "20px", color: answer.check_test[1] }}><i className={answer.check_test[0]}></i></span></div>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {/* 14 ANSWER PROCESO */}
                                                                                {answer.type == "proceso_id" && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "proceso_id" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <select
                                                                                                    name="value"
                                                                                                    className="form form-control"
                                                                                                    onChange={this.HandleChangeUpdateAnswer}
                                                                                                    value={this.state.formUpdateAnswer.value}
                                                                                                >
                                                                                                    <option value="">Seleccione un valor</option>
                                                                                                    {this.props.procesos.map((value, index) => (
                                                                                                        <option key={index} value={value.id}>{value.name}</option>
                                                                                                    ))}
                                                                                                </select>
                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            < div onDoubleClick={() => this.selectValue(answer)} style={{ display: "flex", justifyContent: "space-between", minWidth: "40px", minHeight: "20px", cursor: "pointer" }}>  <span style={{ flex: "1" }} >{typeof answer.value !== 'object' ? answer.value : ""}</span><span style={{ minWidth: "20px", color: answer.check_test[1] }}><i className={answer.check_test[0]}></i></span></div>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {/* 15 ANSWER CARGO */}
                                                                                {answer.type == "position_id" && (
                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswer.answer_type == "position_id" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <select
                                                                                                    name="value"
                                                                                                    className="form form-control"
                                                                                                    onChange={this.HandleChangeUpdateAnswer}
                                                                                                    value={this.state.formUpdateAnswer.value}
                                                                                                >
                                                                                                    <option value="">Seleccione un valor</option>
                                                                                                    {this.props.positions.map((value, index) => (
                                                                                                        <option key={index} value={value.id}>{value.name}</option>
                                                                                                    ))}
                                                                                                </select>
                                                                                                <div className="mt-2 mb-1">
                                                                                                    <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValue()}></i>
                                                                                                    <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            < div onDoubleClick={() => this.selectValue(answer)} style={{ display: "flex", justifyContent: "space-between", minWidth: "40px", minHeight: "20px", cursor: "pointer" }}><span style={{ flex: "1" }} >{typeof answer.value !== 'object' ? answer.value : ""}</span> <span style={{ width: "20px", color: answer.check_test[1] }}><i className={answer.check_test[0]}></i></span> </div>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}


                                                                                {/* 16 ANSWER CODE */}
                                                                                {answer.type == "code_format" && (
                                                                                    <span>{typeof answer.value !== 'object' ? `${answer.value}` : ""}</span>
                                                                                )}


                                                                                {/* 17 ANSWER FORMATO */}
                                                                                {answer.type == "format_id" && (

                                                                                    <React.Fragment>
                                                                                        {(this.state.question_id == answer.question_id && this.state.answer_id == answer.survey_id && this.state.formUpdateAnswerSelect.answer_type == "format_id" && this.props.estados.edit) ? (
                                                                                            <React.Fragment>
                                                                                                <div className="question-format-id">
                                                                                                    {(this.state.format_field_current == answer.question_id && this.state.format_field_loading) && (
                                                                                                        <div className='question-format-id__loading--index'>  <PreloaderMini /></div>
                                                                                                    )}
                                                                                                    {answer.is_multiple ? (
                                                                                                        <React.Fragment>
                                                                                                            <input
                                                                                                                type="hidden"
                                                                                                                name="position_ids"
                                                                                                                value={this.state.formUpdateAnswerSelect.value}
                                                                                                            />

                                                                                                            <Select
                                                                                                                onChange={(e) => this.HandleChangeUpdateAnswerSelect(e, "multi")}
                                                                                                                options={this.state.formatValues[answer.question_id].data}
                                                                                                                isMulti
                                                                                                                closeMenuOnSelect={false}
                                                                                                                autoFocus={false}
                                                                                                                className={`link-form`}
                                                                                                                classNamePrefix="select"
                                                                                                                placeholder="Seleccione"
                                                                                                                name="position_ids"
                                                                                                                defaultValue={this.state.editValues}
                                                                                                            />
                                                                                                        </React.Fragment>
                                                                                                    ) : (
                                                                                                        <React.Fragment>
                                                                                                            <input
                                                                                                                type="hidden"
                                                                                                                name="specific_area_id"
                                                                                                                value={this.state.formUpdateAnswerSelect.value}
                                                                                                            />

                                                                                                            <Select
                                                                                                                onChange={(e) => this.HandleChangeUpdateAnswerSelect(e, "single")}
                                                                                                                onInputChange={(e) => this.handleInputChangeSelect(e, answer.question_id)}
                                                                                                                onFocus={(e) => this.handleInputOnFocusSelect(e, answer.question_id)}
                                                                                                                options={this.state.formatValuesFormat[answer.question_id] ? this.state.formatValuesFormat[answer.question_id].data : []}
                                                                                                                autoFocus={false}
                                                                                                                className={`link-form`}
                                                                                                                value={this.state.formUpdateAnswerSelect}
                                                                                                            />
                                                                                                        </React.Fragment>
                                                                                                    )}
                                                                                                    <div className="mt-2 mb-1">
                                                                                                        <i className="fas fa-check-circle mr-2 ml-2" style={{ color: "#5b9a17", fontSize: "25px" }} onClick={() => this.updateValueSelect()}></i>
                                                                                                        <i className="fas fa-times-circle" style={{ color: "#ff0000", fontSize: "25px" }} onClick={() => this.cancelValues()}></i>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            <div onDoubleClick={() => this.selectValueSelect(answer, answer.is_multiple)} style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}>

                                                                                                {answer.is_multiple ? (
                                                                                                    <div style={{ minWidth: "40px", minHeight: "20px" }} onClick={() => this.selectValueSelect(answer, answer.is_multiple)}>
                                                                                                        {answer.value &&
                                                                                                            (
                                                                                                                <React.Fragment>
                                                                                                                    {answer.value.map((format, index) => (
                                                                                                                        <span key={index}>{index >= 1 ? "," : ""} {format ? format.label : ""} </span>
                                                                                                                    ))}
                                                                                                                </React.Fragment>
                                                                                                            )
                                                                                                        }

                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <span style={{ flex: "1", minwidth: "40px", minHeight: "20px" }} >{answer.value && answer.value[0] ? answer.value[0].label : ""}</span>
                                                                                                )}
                                                                                            </div>

                                                                                        )}
                                                                                    </React.Fragment>

                                                                                )}

                                                                                {/* 18 ANSWER LOCALIZATION */}
                                                                                {answer.type == "answer_localization" && (
                                                                                    <span>{typeof answer.value !== 'object' ? `${answer.value}` : ""}</span>
                                                                                )}

                                                                                {/* 19 ANSWER DYNAMIC */}
                                                                                {answer.type == "dynamic_field" && (
                                                                                    <span>{typeof answer.value !== 'object' ? `${answer.value}` : ""}</span>
                                                                                )}

                                                                                {/* 20 ANSWER SIGNATURE */}
                                                                                {answer.type == "answer_signature" && answer.value && (
                                                                                    <img style={{ width: "150px", height: "50px" }} src={answer.value} />
                                                                                )}

                                                                                {/* 21 ANSWER CODE */}
                                                                                {answer.type == "answer_subform" && (
                                                                                    <div>
                                                                                        {answer.type == "answer_subform" && (
                                                                                            <a
                                                                                                href={`/formatos/format_categories/${this.props.format_category.token}/formats/${survey.data_to_show.format.token}/gestion/${survey.token}?format_association_id=${answer.value}`}
                                                                                                data-turbolinks="true"
                                                                                                target="_blank"
                                                                                            >
                                                                                                Ver datos
                                                                                            </a>

                                                                                        )}
                                                                                    </div>
                                                                                )}

                                                                                {/* 22 ANSWER CODE */}
                                                                                {answer.type == "ce_10" && (
                                                                                    <span>{answer.value}</span>
                                                                                )}


                                                                                {/*  {answer.type == "answer_datetime" && (
                                                                                    <span>{answer.value != null && answer.value != "" ? answer.value : ""}</span>
                                                                                )} */}







                                                                                {/*                                                              {((answer.type == "answer_integer" || answer.type == "answer_formula") && answer.is_currency) && (

                                                                                    <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={answer.value} />

                                                                                )} */}



                                                                            </td>
                                                                        ))}

                                                                    </tr>
                                                                ))

                                                            ) : (
                                                                <tr>
                                                                    <td className="text-center" colSpan={this.props.dataQuestion.length + 2}>
                                                                        <div className="text-center mt-4 mb-4" >
                                                                            <h4>No hay registros</h4>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <div className="col-md-12 text-center">
                                            <Preloader />
                                        </div>
                                    )
                                    }

                                </div>

                                {!this.props.isFilter ? (
                                    <div className="col-md-12 ui-index-surveys-fordata-count-register_pagination__container">
                                        {/*   {this.props.isLoadedPag && (
                                            <span>Cargando..</span>
                                        )} */}
                                        <Pagination
                                            hideNavigation
                                            activePage={this.props.activePage}
                                            itemsCountPerPage={this.props.countPage}
                                            itemClass="page-item"
                                            innerClass="pagination"
                                            linkClass="page-link"
                                            totalItemsCount={this.props.survey_total}
                                            pageRangeDisplayed={5}
                                            onChange={this.props.handlePageChange}
                                        />
                                    </div>
                                ) : (
                                    <div className="col-md-12">
                                        <Pagination
                                            hideNavigation
                                            activePage={this.props.activePage_filter}
                                            itemsCountPerPage={this.props.countPage_filter}
                                            itemClass="page-item"
                                            innerClass="pagination"
                                            linkClass="page-link"
                                            totalItemsCount={this.props.survey_total_filter}
                                            pageRangeDisplayed={5}
                                            onChange={this.props.handlePageChange_filter}
                                        />
                                    </div>
                                )}


                            </div>
                        </React.Fragment >
                    ) : (

                        <div className="no-fordata"><i className="far fa-list-alt"></i> <br />
                            ¡Aún no has completado tu Formato! <br /> crea almenos una entrada</div>

                    )
                ) : (
                    <div className="col-md-12 text-center">
                        <Preloader />
                    </div>
                )}
            </React.Fragment >
        );
    }
}

export default Index;