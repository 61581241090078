import React, { Component } from 'react';
import Dropzone from "react-dropzone";
import Select from "react-select";
import { isMobile } from 'react-device-detect';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';
import { Progress } from 'reactstrap';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import loading from '../../../GeneralComponents/LoadingComponen'
import LoadingComponen from '../../../GeneralComponents/LoadingComponen';
import CustomAutocomplete from '../../../GeneralComponents/CustomAutocomplete';
import FileTypeFormat from './FileTypeFormat'
import NumberFormat from 'react-number-format';
import ModalSubForm from './ModalSubForm'
import ModalSubFormMobile from './ModalSubFormMobile'
import SignaturePad from 'react-signature-canvas'
import TimeField from "react-simple-timefield";
import is_imagen from 'is-image'
import CreatableSelect from 'react-select/creatable';
import GoogleDocsViewer from 'react-google-docs-viewer';
import PreloaderMini from '../../../GeneralComponents/Preloaders';
import Matrix from "./Matrix"
import { debounce } from 'lodash';
import * as rdd from 'react-device-detect';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';

class Show extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.sigPad = {}
        this.state = {
            timeStamp: Date.now(),
            sigPad: [],
            survey_answer_id: "",
            isLoaded: true,
            isSaved: true,
            modal: false,
            errorValues: true,
            countProgress: 0,
            nameFile: "",
            nexStep: 0,
            prevStep: 1,
            maxStep: 0,
            loading: false,
            loadingNextStep: false,
            completed: false,
            countPageValidation: 0,
            countPageInputs: 0,
            formulaError: false,
            dataAnswerSubForms: {},
            errorSubform: true,
            isMobileSize: false,
            configFroala: {},
            arrayHidden: {},
            hide_show_ids_: {},

            formUpdateRepy: {
                answer_string: "",
                answer_text: "",
                answer_date: "",
                answer_datetime: "",
                answer_file: "",
                answer_select: "",
                answer_radio: "",
                answer_checkbox: [],
                answer_user_id: "",
                answer_integer: "",
            },

            geolocalizacion: {
                Latitude: "",
                Longitude: "",
            },

            formFormat: {
                name: "",
                question_id: "",
            },

            formUpdateRepy1: {

            },
            formUpdateRepy2: {

            },

            formatValues: {

            },

            formatValuesFormat: {

            },

            selectedOptionUser: {
                answer_user_id: "",
                label: "Selección de usuario",
            },

            selectCustomAutocomplete: {
                name: "",
                label: "Buscar de registro",
            },

            selectedOptionCe10: {
                ce_10: "",
                label: "Buscar de registro",
            },

            ce_10s: [],
            question_id: "",


            formTest: {
                name: "",
                data: [],
            },

            trimmedDataURL: null,
            surveySubform: {},
            dataSubForms: [],
            hide_show_ids: [],
            formatSubForm: "",
            users: [],
            options: [],
            cargos: [],
            procesos: [],
            question_actual: 0,
            errorUploadFile: {
                question_id: "",
                message: ""
            },

            //GER VALUES TO ANSWER TYPE FORMAT
            format_field_loading: false,
            format_field_current: false

        }
        this.handleInputChangeSelect = debounce(this.handleInputChangeSelect.bind(this), 500);
    }


    //********************* GET VALUES FROM OTHER FORMATS TO AUTOCOMPLET ANSERS TYPE FORMAT ID  KEY=FORMATVALUES *********************/

    createClearSelectLog = (surveyId, questionId, deletedValue) => {
        const form = { survey_id: surveyId, question_id: questionId, deleted_value: deletedValue }

        fetch(`/formatos/create_clear_select_log`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .catch(error => console.error("Error:", error))
    }

    // UPDATE VALUES WHEN A VALUE DEPEND FRON OTHER VALUE ////
    updateDynamicQuestions = (data) => {
        console.log(data, "holaaaaaaaa");
        if (data.survey_relation_state) {
            data.survey_relation_parent.map((value) => {
                this.setState({
                    formatValues: {
                        ...this.state.formatValues,
                        [value.id]: [value.format_id, value.survey_relation]
                    },
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [value.id]: { ...this.state.formUpdateRepy1[value.id], value: [{ label: "Seleccione una opción", value: "bw-na" }] }
                    }
                })
            })
        }

        if (data.data.length > 0) {
            data.data.map((value) => {
                console.log(value.question)
                console.log(value.value)
                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [value.question]: { ...this.state.formUpdateRepy1[value.question], value: [value.value] }
                    }
                })
            })
        }




    }

    updateDynamicQuestionsLoad = (data) => {
        console.log(data, "holaaaaaaaa");
        if (data.survey_relation_state) {
            data.survey_relation_parent.map((value) => {
                this.setState({
                    formatValues: {
                        ...this.state.formatValues,
                        [value.id]: [value.format_id, value.survey_relation]
                    },

                })
            })
        }
    }

    getFilterFormatValues = (question_id, val) => {
        fetch(`/formatos/get_filter_format_values/${question_id}/${val}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                console.log(data, "hola mundoalli")
                this.updateDynamicQuestionsLoad(data);
                // SI ES EL ULTIMO STEP REDIRECCIONA A EL INDEX O AL LA VISTA DEPENTDIENDO DE DONDE VENGA
            });
    }




    // GET FORMAT VALUES WHEN IS A INPUT
    handleChangeAutocompleteSelectInput = (selectedOption, question) => {
        var selectedOptionGlobal = selectedOption;


        this.setState({
            formUpdateRepy2: {
                ...this.state.formUpdateRepy2,
                [question.id]: { ...this.state.formUpdateRepy2[question.id], value: [{ label: selectedOptionGlobal.target.value }] }
            }
        }, () => {
            console.log(this.state.formUpdateRepy2[question.id].value[0].label.length, "111")
            console.log(question.decimal_count_show)
            if (this.state.formUpdateRepy2[question.id].value[0].label.length >= question.decimal_count_show) {


                let format_filter = []
                if (this.state.formatValues[question.id]) {
                    format_filter = this.state.formatValues[question.id]
                } else {
                    format_filter = [0, 0]
                }

                let value2 = this.state.formUpdateRepy2[question.id].value[0].label ? this.state.formUpdateRepy2[question.id].value[0].label : "bw-basic"

                fetch(`/formatos/get_formats_values_format/${question.id}/${value2}/${format_filter[0]}/${format_filter[1]}`, {
                    method: 'GET', // or 'PUT'
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        this.setState({
                            formatValuesFormat: {
                                ...this.state.formatValuesFormat,
                                [question.id]: data,

                            },

                        }, (selectedOptionGlobal) => {
                            console.log("hla afads")
                            let formatValues = this.state.formatValuesFormat[question.id] ? this.state.formatValuesFormat[question.id].data : []
                            let val = this.state.formUpdateRepy2[question.id].value[0].label;
                            let totalvalues = formatValues.filter(value => value.label == val && val).length
                            if (totalvalues > 0) {
                                let valuelast = formatValues.find(value => value.label == val)
                                let value = this.state.formUpdateRepy1[question.id]
                                value["value"] = [valuelast]

                                this.setState({
                                    formUpdateRepy1: {
                                        ...this.state.formUpdateRepy1,
                                        [question.id]: value
                                    }
                                }, () => {
                                    if (question.question_type == "format_id") {
                                        this.updateValuesFormat("format", question.id);
                                    }

                                })
                            }
                        })
                        console.log(data)
                    });
            }

        })


    };




    /// GET FORMAT VALUES WHEN WHIRETE MORE THAN 3 LETTERS ON SELECT FORMAT_ID
    handleInputChangeSelect = (value, e) => {
        if (value.length >= e.decimal_count_show && value.length) {
            this.getFormatsValuesFormat(value, e.id)
        }
        console.log(value)
        console.log(e)
    }

    // GET FORMAT VALUES WHEN CLICK ON SELECT FORMAT_ID
    handleInputOnFocusSelect = (value, e) => {

        console.log("entree")
        this.getFormatsValuesFormat("bw-blank", e.id)
        console.log(value, "value")
        console.log(e, "e")
    }


    // METHOD TO GET VALUES ON SELECT FORMAT_ID
    getFormatsValuesFormat = (value, question_id) => {

        // WHEN IS SEARCHING IT SHOW A LOADING
        this.setState({
            format_field_current: question_id,
            format_field_loading: true,
        })


        let format_filter = [];
        if (this.state.formatValues[question_id]) {
            format_filter = this.state.formatValues[question_id]
        } else {
            format_filter = [0, 0]
        }



        fetch(`/formatos/get_formats_values_format/${question_id}/${value}/${format_filter[0]}/${format_filter[1]}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    format_field_current: 0,
                    format_field_loading: false,
                    formatValuesFormat: {
                        ...this.state.formatValuesFormat,
                        [question_id]: data,

                    }
                })
                console.log(data)
            });
    }


    //*********************END GET VALUES FROM OTHER FORMATS TO AUTOCOMPLET ANSERS TYPE FORMAT ID  KEY=FORMATVALUES *********************/





    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentWillMount = () => {
        this.usersSelect();
        this.handleResize = this.handleResize.bind(this);

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    handleResize() {
        const windowWidth = window.innerWidth;

        const mobileThreshold = 768;
        this.setState({
            isMobileSize: windowWidth < mobileThreshold,
        });
    }

    hasConfigurationSizeScreen = () => {
        if (this.state.isMobileSize) {
            this.setState({
                configFroala: {
                    language: 'es',
                    quickInsertEnabled: false,
                    colorsHEXInput: true,
                    autoFocus: true,
                    toolbartop: true,
                    linkAlwaysBlank: true,
                    fontFamilySelection: true,
                    fontSizeSelection: true,
                    paragraphFormatSelection: true,
                    htmlExecuteScripts: true,
                    key: this.props.key_text,
                    imageUploadToS3: this.props.imageUpload,
                    iframe: true,
                    tabSpaces: 4,
                    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'wordPaste'],
                    toolbarButtons: {
                        moreText: {
                            buttons: ['bold',
                                'italic',
                                'fontFamily',
                                'fontSize',
                                'textColor',
                                'backgroundColor',
                            ],

                            // Alignment of the group in the toolbar.
                            align: 'left',

                            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                            buttonsVisible: 12
                        },
                        moreRich: {
                            buttons: ['insertImage'],
                            align: 'left',
                            buttonsVisible: 10
                        },

                        moreParagraph: {
                            buttons: ['alignLeft',
                                'alignCenter',
                                'alignRight',
                                'alignJustify',
                            ],
                            align: 'left',
                            buttonsVisible: 11
                        }
                    }
                }
            })
        } else {
            this.setState({
                configFroala: {
                    language: 'es',
                    quickInsertEnabled: false,
                    colorsHEXInput: true,
                    autoFocus: true,
                    toolbartop: true,
                    linkAlwaysBlank: true,
                    fontFamilySelection: true,
                    fontSizeSelection: true,
                    paragraphFormatSelection: true,
                    htmlExecuteScripts: true,
                    key: this.props.key_text,
                    imageUploadToS3: this.props.imageUpload,
                    iframe: true,
                    tabSpaces: 4,
                    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'wordPaste'],
                    toolbarButtons: {
                        moreText: {
                            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

                            // Alignment of the group in the toolbar.
                            align: 'left',

                            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                            buttonsVisible: 12
                        },
                        moreParagraph: {
                            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                            align: 'left',
                            buttonsVisible: 11
                        },
                        moreRich: {
                            buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                            align: 'left',
                            buttonsVisible: 10
                        },
                        moreMisc: {
                            buttons: ['undo', 'redo', 'fullscreen', 'print', 'spellChecker', 'selectAll', 'html', 'help'],
                            align: 'right',
                            buttonsVisible: 9
                        }
                    }
                }
            })

        }
    }

    getGeolocalizacion = (question) => {
        let app = this
        if (app.props.survey.state_complete != "saved") {
            console.log("entre aqui a las geo code")
            navigator.geolocation.getCurrentPosition(function (position) {
                app.setState({
                    formUpdateRepy1: {
                        ...app.state.formUpdateRepy1,
                        [question.id]: { value: [`${position.coords.latitude},${position.coords.longitude}`], type: question.question_type, step: app.state.formUpdateRepy1[question.id].step, is_mandatory: question.id.is_mandatory, is_active: question.id_active }
                    }
                });
            });
        }
    }

    usersSelect = () => {
        let array = [];

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            users: array,
        })
    }

    componentDidMount = () => {
        let array = [];
        let arrayCargos = [];
        let arrayProcesos = [];

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.props.positions.map((item) => (
            arrayCargos.push({ label: item.name, value: item.id })
        ))

        this.props.procesos.map((item) => (
            arrayProcesos.push({ label: item.name, value: item.id })
        ))

        this.setState({
            options: array,
            cargos: arrayCargos,
            procesos: arrayProcesos,
        })


        this.hasConfigurationSizeScreen();
    }

    componentWillReceiveProps = (nexProps) => {
        /* this.loadValues(nexProps); */

        this.setState({
            maxStep: nexProps.data.length,
            formUpdateRepy1: nexProps.dataAnswer,
            formUpdateRepy2: nexProps.dataAnswer,
            arrayHidden: nexProps.hide_show_ids
        })

        console.log("adf", nexProps)

        Object.entries(nexProps.dataAnswer)
            .filter(([key, value]) => value.type === 'format_id')
            .forEach(([key, value]) => {
                console.log("key", key)
                console.log("fad", value)
                let value_final = ""
                value_final = value.value[0] ? value.value[0].value : "";
                this.getFilterFormatValues(key, value_final);
            });




        nexProps.data_all.filter(value => value.question_type == "answer_signature").map((item) => {
            console.log("jejejesiseñores")
            this.setState({
                sigPad: {
                    ...this.state.sigPad,
                    [item.id]: {}
                }
            })

        })
    }


    updateBackValue = (variable) => {
        this.setState({ nexStep: this.state.nexStep - 1, errorValues: true })
    }



    HandleChangeRepy = (e) => {
        const { target } = e;

        console.log("------------------------ haasdasd")
        console.log(e.target.value)

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: [e.target.value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        }, () => {
            this.changevalue(target);
        });
    }

    HandleChangeCkaditor = (e, question) => {
        let val = []
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: { value: [e], type: question.question_type, step: this.state.formUpdateRepy1[question.id].step, is_mandatory: question.is_mandatory, is_active: question.is_active }
            }
        })
    }

    HandleChangeRepyNumber = (e) => {
        const { target } = e;
        const value = e.target.value.replace("$", "").replace(",", "").replace(",", "").replace(",", "").replace(",", "")

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: [value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        }, () => {
            this.changevalue(target);

        });
    }


    changevalue = (target) => {

        if (target.dataset.type == "answer_integer" || target.dataset.type == "answer_radio" || target.dataset.type == "answer_select") {
            //this.hasFormule(target.value);
        }
    }


    hasValues = (data) => {
        //Tu Texto
        //String match
        let data_array = [];
        data.match(/\{([.\w\s]*)\}/g)
            .forEach((item) => {
                //console.log( item );
                //Puedes limpiar el texto con:
                data_array.push(item.replace(/[\{\}]/g, ''));
            });
        return data_array
    }


    HandleChangeRepyRadio = (e, id) => {
        const { target } = e;
        var name = id;
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: [e.target.value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            },
        }, () => {
            this.changevalue(target);
            this.hideSHowQuestions(name, target.value)
            //this.updateValues(true)
            //this.updateValuesFormat("basic_field")

        });
    }


    HandleChangeRepyRadioInput = (e, question) => {
        const { target } = e;
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: { value: [this.state.formUpdateRepy1[question.id].value[0]], type: question.question_type, step: this.state.formUpdateRepy1[question.id].step, is_mandatory: question.is_mandatory, is_active: question.is_active }
            },
        }, () => {
            this.changevalue(target);

        });
    }



    HandleChangeHour = (value) => {
        const showSecond = true;
        const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: value.format(str), question_type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        })
    }



    HandleChangeRepyArray = (e, question) => {
        let array = this.state.formUpdateRepy1[e.target.dataset.question].value[0];
        const value = e.target.value != "on" ? e.target.value : ""

        if (e.target.checked) {
            array.push(value)

            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [e.target.dataset.question]: { value: [array], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
                },


            })

        } else {


            this.setState({

                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [e.target.dataset.question]: { value: [array.filter(item => item != e.target.value)], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
                },
            })

        }
    }

    HandleChangeFile = (archivo, id, is_mandatory, is_active, question) => {
        question = question.id;

        if (archivo[0].size >= 11485760) {
            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: { value: [{ name: undefined, value: undefined }], type: "answer_file", step: this.state.formUpdateRepy1[question].step, is_mandatory: is_mandatory, is_active: is_active }
                },

                errorUploadFile: {
                    question_id: question,
                    message: "El archivo excede el límite de 10 MB permitido. Te recomendamos utilizar una pregunta de tipo link para este tipo de archivos"
                }
            })
        } else {
            archivo.map(file => (
                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: { value: [file], type: "answer_file", step: this.state.formUpdateRepy1[question].step, is_mandatory: is_mandatory, is_active: is_active }
                    },

                    survey_answer_id: id,
                    nameFile: file.path,
                    errorUploadFile: {
                        question_id: "",
                        message: ""
                    }
                })
            ));
        }
    }

    setValueSelect = (e, survey_answer) => {
        const { target } = e;
        var name = survey_answer.id
        this.setState({

            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [name]: { value: [e.target.value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[name].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        }, () => {
            this.changevalue(target);
            this.hideSHowQuestions(name, target.value)
        })
    }

    hideSHowQuestions = (question_id, value) => {
        fetch(`/formatos/get_hide_show_questions/${question_id}/${value}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {

                this.setState({
                    arrayHidden: { ...this.state.arrayHidden, ...data.ids },
                })
            });
    }

    getValidation = (question) => {
        if (question.question_type == "text" && question.answer_string != null) {
            return true
        }
    }


    validateEmail(email) {
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        console.log(emailRegex.test(email))
        //Se muestra un texto a modo de ejemplo, luego va a ser un icono
        if (emailRegex.test(email)) {
            console.log(emailRegex.test(email))
            return true
        } else {
            return false
        }
    }

    updateValues = (field = false) => {

        let state = true;
        let state_sub_form = true;
        let state_email = true;

        // SETEA EN FALSE EL ERROR STATE
        this.setState({
            errorValues: false,
            errorSubform: false,

        })
        //RECORRE CADA UNO DE LAS PREGUNTAS PARA VALIDAR QUE SEA DEL MISMO STEP ACTUAL Y SI EL CAMPO ES OBLIGATORIA Y LE PREGUNTA ESTA VACIA NO DEJA SEGUIR 
        if (!field) {


            Object.keys(this.state.formUpdateRepy1).forEach(key => {
                if (this.state.formUpdateRepy1[key].step == this.state.nexStep && (this.state.formUpdateRepy1[key].is_mandatory == "true" || this.state.formUpdateRepy1[key].is_mandatory == true) && (this.state.formUpdateRepy1[key].value[0] === "" || (this.state.formUpdateRepy1[key].value[0] && (this.state.formUpdateRepy1[key].value[0].value == "bw-na" || this.state.formUpdateRepy1[key].value[0].value?.toString().includes("_delete"))) || this.state.formUpdateRepy1[key].value[0] == null || this.state.formUpdateRepy1[key].value[0] == null || this.state.formUpdateRepy1[key].value[0].length == 0 || (this.state.formUpdateRepy1[key].type == "answer_file" ? this.state.formUpdateRepy1[key].value[0].name == undefined : false))) {
                    state = false
                }

                if (this.state.formUpdateRepy1[key].step == this.state.nexStep && this.state.formUpdateRepy1[key].type == "answer_subform" && (this.state.formUpdateRepy1[key].is_mandatory == "true" || this.state.formUpdateRepy1[key].is_mandatory == true)) {
                    if (this.state.formUpdateRepy1[key].value[0].answers.length < 1) {
                        state_sub_form = false

                    }
                }
                if (this.state.formUpdateRepy1[key].step == this.state.nexStep && this.state.formUpdateRepy1[key].type == "email") {

                    if (this.state.formUpdateRepy1[key].value[0].length > 0 || (this.state.formUpdateRepy1[key].is_mandatory == "true" || this.state.formUpdateRepy1[key].is_mandatory == true)) {
                        if ((this.state.formUpdateRepy1[key].value[0].length > 0)) {
                            state_email = this.validateEmail(this.state.formUpdateRepy1[key].value[0])

                        } else {
                            state_email = this.state.formUpdateRepy1[key].value[0] != ""

                        }

                    }

                }
            });
        }

        // SI TODAS LAS PREGUNTAS TIENEN VALOR O NO SON OBLIGATORIAS
        if (state && state_sub_form && state_email) {
            const formData = new FormData();

            // SI HAY SECCIONES PRIVADAS ENTRA POR QUE LAS SECCIONES PRIVADAS QUEDAN DE ULTIMAS
            if (this.props.data.filter(value => value.is_private == true).length > 0) {

                var step_data_1 = this.props.data[this.state.nexStep];

                //RECORRE formUpdateRepy1 PARA TRAER LOS VALORES DE LAS PREGUNTAS
                Object.keys(this.state.formUpdateRepy1).forEach(key => {
                    if (this.state.formUpdateRepy1[key].step == step_data_1.step_position) {

                        //SI EL TYPO DE PREGUNTA ES USUARIOS, PROCESOS, CARGOS Y O FORMATO.
                        if (this.state.formUpdateRepy1[key].type.includes("answer_user_id") || this.state.formUpdateRepy1[key].type.includes("proceso_id") || this.state.formUpdateRepy1[key].type.includes("position_id") || this.state.formUpdateRepy1[key].type.includes("format_id")) {
                            console.log(this.state.formUpdateRepy1[key].value, "hola mundo ajaja")
                            // SI EL ARRAY ES MAYOR QUE 0 LLEVE EL VALOR DEL LA PREGUNTA SI NO LLEVA UN ARRAY VACIO
                            let array = this.state.formUpdateRepy1[key].value.length > 0 ? this.state.formUpdateRepy1[key].value[0] : []
                            let final_array = []

                            // SI EL LENGTH ES MAYOR QUE UN A FINAL ARRAY LE LLEBA EL VALUE DEL ARRAY
                            if (array.length >= 1) {
                                array.map((value) => {
                                    final_array.push(value.value)
                                })
                            } else {
                                final_array.push(array.value)
                            }
                            // AGREGA EL VALOR DE LA PREGUNTA AL FORDATA
                            formData.append(key, final_array);
                        } else {
                            var test = this.state.formUpdateRepy1[key];
                            // SI ES ANSWER TEXT O ANSWER RADIO AGREGA TODO EL ARRAY
                            if (this.state.formUpdateRepy1[key].type.includes("answer_text")) {
                                formData.append(key, this.state.formUpdateRepy1[key].value);

                            } else if (this.state.formUpdateRepy1[key].type.includes("answer_radio")) {
                                formData.append(key, this.state.formUpdateRepy1[key].value);

                            }
                            else {
                                // SI ES ANSWER CUALQUIER OTRO AGREGA TODO EL VALOR
                                formData.append(key, this.state.formUpdateRepy1[key].value[0]);

                            }

                        }
                    }
                });
            } else {
                // LOS MISMOS COMENTARIOS DE ARRIBA SOLO QUE AQUI ES CUANDO NO HAY SECCIONES PRIVADAS
                // TO DO PONER EL CODIGO COMPARTIDO EN UNA MISMA FUNCION

                Object.keys(this.state.formUpdateRepy1).forEach(key => {
                    if (this.state.formUpdateRepy1[key].type) {
                        if (this.state.formUpdateRepy1[key].type.includes("answer_user_id") || this.state.formUpdateRepy1[key].type.includes("proceso_id") || this.state.formUpdateRepy1[key].type.includes("position_id") || this.state.formUpdateRepy1[key].type.includes("format_id")) {
                            let ex = this.state.formUpdateRepy1;
                            let ex0 = this.state.formUpdateRepy1[key];
                            console.log(this.state.formUpdateRepy1[key].value, "hola mundo ajaja")

                            if (Array.isArray(this.state.formUpdateRepy1[key].value[0])) {
                                var ex1 = this.state.formUpdateRepy1[key].value;
                            } else {


                                var ex1 = [this.state.formUpdateRepy1[key].value];

                            }

                            let array = ex1.length > 0 ? ex1[0] : []
                            let final_array = []

                            if (array.length >= 1) {
                                array.map((value) => {
                                    final_array.push(value.value)
                                })
                            } else {
                                final_array.push(array.value)
                            }
                            formData.append(key, final_array);
                        } else {
                            var test = this.state.formUpdateRepy1[key];

                            if (this.state.formUpdateRepy1[key].type.includes("answer_text")) {
                                formData.append(key, this.state.formUpdateRepy1[key].value);

                            }
                            else if (this.state.formUpdateRepy1[key].type.includes("answer_radio")) {
                                formData.append(key, this.state.formUpdateRepy1[key].value);

                            }
                            else {
                                console.log(key, this.state.formUpdateRepy1[key].value[0])
                                formData.append(key, this.state.formUpdateRepy1[key].value[0]);

                            }
                        }
                    }
                });
            }

            // VERIFICA SI ES EL ULTIMO STEP SI ES EL ULTIMO STEP A LAST STEP LE LLEVA 1
            let lastStep = 0;
            if ((this.state.nexStep + 1) == this.state.maxStep) {
                this.setState({
                    loading: true
                })
                lastStep = 1;
            }
            else {
                this.setState({
                    loadingNextStep: true
                })
            }

            if (field) {
                this.setState((prevState, props) => ({
                    errorValues: false,
                    errorSubform: false,
                    loadingNextStep: false,
                    loading: false,

                }));
            }
            console.log(formData.entries)
            // HACE EL REQUEST ENVIA EL ID DEL SURVEY EL DATA ARRAY QUE SON LOS NOMBRES DE LAS PREGUNTAS SI ES LAST STEP O NO Y EL ID DEL STEP
            fetch(`/formatos/update_ansewers/${this.props.survey.id}/${lastStep}/${this.props.data[this.state.nexStep].id}`, {
                method: 'PATCH', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadData();
                    // SI ES EL ULTIMO STEP REDIRECCIONA A EL INDEX O AL LA VISTA DEPENTDIENDO DE DONDE VENGA
                    if (!field) {
                        if ((this.state.nexStep + 1) == this.state.maxStep) {
                            this.redirectSurvey()
                        } else {
                            window.scrollTo(0, 0)
                            this.setState((prevState, props) => ({
                                errorValues: true,
                                errorSubform: true,
                                nexStep: prevState.nexStep + 1,
                                loading: false,
                                loadingNextStep: false,
                                completed: false
                            }));
                        }
                    }

                });

        } else {
            this.setState({
                completed: true
            })
        }
    }

    updateValuesFormat = (type, question_id = 0) => {
        console.log("dddddddd")
        let state = true;
        // SETEA EN FALSE EL ERROR STATE
        /*  this.setState({
             errorValues: false,
         }) */
        //RECORRE CADA UNO DE LAS PREGUNTAS PARA VALIDAR QUE SEA DEL MISMO STEP ACTUAL Y SI EL CAMPO ES OBLIGATORIA Y LE PREGUNTA ESTA VACIA NO DEJA SEGUIR 
        /*  Object.keys(this.state.formUpdateRepy1).forEach(key => {
             if (this.state.formUpdateRepy1[key].step == this.state.nexStep && (this.state.formUpdateRepy1[key].is_mandatory == "true" || this.state.formUpdateRepy1[key].is_mandatory == true) && (this.state.formUpdateRepy1[key].value[0] === "" || this.state.formUpdateRepy1[key].value[0] == null || this.state.formUpdateRepy1[key].value[0].length == 0 || (this.state.formUpdateRepy1[key].type == "answer_file" ? this.state.formUpdateRepy1[key].value[0].name == undefined : false))) {
                 state = false
             }
         }); */

        // SI TODAS LAS PREGUNTAS TIENEN VALOR O NO SON OBLIGATORIAS
        if (true) {
            const formData = new FormData();

            // SI HAY SECCIONES PRIVADAS ENTRA POR QUE LAS SECCIONES PRIVADAS QUEDAN DE ULTIMAS
            if (this.props.data.filter(value => value.is_private == true).length > 0) {

                var step_data_1 = this.props.data[this.state.nexStep];

                //RECORRE formUpdateRepy1 PARA TRAER LOS VALORES DE LAS PREGUNTAS
                Object.keys(this.state.formUpdateRepy1).forEach(key => {
                    if (this.state.formUpdateRepy1[key].step == step_data_1.step_position) {

                        //SI EL TYPO DE PREGUNTA ES USUARIOS, PROCESOS, CARGOS Y O FORMATO.
                        if (this.state.formUpdateRepy1[key].type.includes("answer_user_id") || this.state.formUpdateRepy1[key].type.includes("proceso_id") || this.state.formUpdateRepy1[key].type.includes("position_id") || this.state.formUpdateRepy1[key].type.includes("format_id")) {

                            // SI EL ARRAY ES MAYOR QUE 0 LLEVE EL VALOR DEL LA PREGUNTA SI NO LLEVA UN ARRAY VACIO
                            let array = this.state.formUpdateRepy1[key].value.length > 0 ? this.state.formUpdateRepy1[key].value[0] : []
                            let final_array = []

                            // SI EL LENGTH ES MAYOR QUE UN A FINAL ARRAY LE LLEBA EL VALUE DEL ARRAY
                            if (array.length >= 1) {
                                array.map((value) => {
                                    final_array.push(value.value)
                                })
                            } else {
                                final_array.push(array.value)
                            }
                            // AGREGA EL VALOR DE LA PREGUNTA AL FORDATA
                            formData.append(key, final_array);
                        } else {
                            var test = this.state.formUpdateRepy1[key];
                            // SI ES ANSWER TEXT O ANSWER RADIO AGREGA TODO EL ARRAY
                            if (this.state.formUpdateRepy1[key].type.includes("answer_text")) {
                                formData.append(key, this.state.formUpdateRepy1[key].value);

                            } else if (this.state.formUpdateRepy1[key].type.includes("answer_radio")) {
                                formData.append(key, this.state.formUpdateRepy1[key].value);

                            }
                            else {
                                // SI ES ANSWER CUALQUIER OTRO AGREGA TODO EL VALOR
                                formData.append(key, this.state.formUpdateRepy1[key].value[0]);

                            }

                        }
                    }
                });
            } else {
                // LOS MISMOS COMENTARIOS DE ARRIBA SOLO QUE AQUI ES CUANDO NO HAY SECCIONES PRIVADAS
                // TO DO PONER EL CODIGO COMPARTIDO EN UNA MISMA FUNCION
                Object.keys(this.state.formUpdateRepy1).forEach(key => {
                    if (this.state.formUpdateRepy1[key].step == this.state.nexStep) {
                        if (this.state.formUpdateRepy1[key].type.includes("answer_user_id") || this.state.formUpdateRepy1[key].type.includes("proceso_id") || this.state.formUpdateRepy1[key].type.includes("position_id") || this.state.formUpdateRepy1[key].type.includes("format_id")) {
                            let array = this.state.formUpdateRepy1[key].value.length > 0 ? this.state.formUpdateRepy1[key].value[0] : []
                            let final_array = []

                            if (array.length >= 1) {
                                array.map((value) => {
                                    final_array.push(value.value)
                                })
                            } else {
                                final_array.push(array.value)
                            }
                            formData.append(key, final_array);
                        } else {
                            var test = this.state.formUpdateRepy1[key];

                            if (this.state.formUpdateRepy1[key].type.includes("answer_text")) {
                                formData.append(key, this.state.formUpdateRepy1[key].value);

                            }
                            else if (this.state.formUpdateRepy1[key].type.includes("answer_radio")) {
                                formData.append(key, this.state.formUpdateRepy1[key].value);

                            }
                            else {
                                console.log(key, this.state.formUpdateRepy1[key].value[0])
                                formData.append(key, this.state.formUpdateRepy1[key].value[0]);

                            }
                        }
                    }
                });
            }

            // HACE EL REQUEST ENVIA EL ID DEL SURVEY EL DATA ARRAY QUE SON LOS NOMBRES DE LAS PREGUNTAS SI ES LAST STEP O NO Y EL ID DEL STEP
            if (type == "format") {

                console.log("hola mundo format aqui alejo")
                fetch(`/formatos/update_ansewers_format/${this.props.survey.id}/${type}?question_id=${question_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        console.log(data, "hola mundoalli")
                        this.updateDynamicQuestions(data);
                        // SI ES EL ULTIMO STEP REDIRECCIONA A EL INDEX O AL LA VISTA DEPENTDIENDO DE DONDE VENGA
                    });
            }
            else if (type == "integer") {
                console.log("entre")
                fetch(`/formatos/update_ansewers_integer/${this.props.survey.id}/${type}`, {
                    method: 'PATCH', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateDynamicQuestions(data);
                        // SI ES EL ULTIMO STEP REDIRECCIONA A EL INDEX O AL LA VISTA DEPENTDIENDO DE DONDE VENGA
                    });
            }
            else {
                fetch(`/formatos/update_ansewers_subform/${this.props.survey.id}/${type}`, {
                    method: 'PATCH', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        // SI ES EL ULTIMO STEP REDIRECCIONA A EL INDEX O AL LA VISTA DEPENTDIENDO DE DONDE VENGA
                    });
            }


        } else {
            this.setState({
                completed: true
            })
        }
    }


    redirectSurvey = () => {
        // SI EL USUARIO ESTA LOGUEADO REDIRECCION A INDEX O INDICADORES O DOCUMENTOS
        if (this.props.user_signed) {
            if (this.props.survey.tenant_id == this.props.survey.tenant_user_id) {
                if (this.props.survey.have_indicator) {
                    Turbolinks.visit(`/indicator/category_indicators/${this.props.survey.indicator.category_indicator_token}/indicators/${this.props.survey.indicator.indicator_token}`);
                } else if (this.props.survey.have_survey_tab && this.props.type == "parent") {
                    Turbolinks.visit(`/formatos/format_categories/${this.props.survey.survey_tab.format_categories_token}/formats/${this.props.survey.survey_tab.format_token}/gestion/${this.props.survey.survey_tab.survey_token}?format_association_id=${this.props.format.id}`);
                } else if (this.props.survey.have_survey_document) {
                    Turbolinks.visit(`/document_management/documents/${this.props.survey.document_id}`);
                } else if (this.props.custom_view_id != 0) {
                    Turbolinks.visit(`/formatos/custom_view/${this.props.custom_view_id}`);

                } else {
                    /*Turbolinks.visit(`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/surveys?view=ACTIVE`)*/
                    if (this.props.validate) {
                        Turbolinks.visit(`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/surveys?view=ACTIVE`)
                    } else {
                        this.messageSuccess({ type: "success", success: "¡Tu registro se ha guardado con éxito!" })
                        setTimeout(() => {
                            Turbolinks.visit(`/`)
                        }, 2000);
                    }
                }
            } else {
                Turbolinks.visit(`/formatos/congratulations_quiz/${this.props.survey.token}`);
            }
        } else {
            // SI NO ESTA LOGUEADO PREGUNTA SI ESE FORMULARIO TIENE UN REDIRECCIONAMIENTO DIFERENTE
            if (this.props.format.redirect_external) {
                Turbolinks.visit(this.props.format.url_redirect);
            } else {
                Turbolinks.visit(`/formatos/congratulations_quiz/${this.props.survey.token}`);
            }
        }
    }

    dateTime = (fecha) => {
        var d = new Date(fecha)
        return d.getHours() + ":" + (d.getMinutes() < 9 ? `0${d.getMinutes()}` : d.getMinutes());
    }


    handleChangeAutocompleteUser = (selectedOptionUser, id) => {
        this.setState({
            selectedOptionUser,
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.id]: { value: [selectedOptionUser.value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.id].step }
            },
        });
    }


    getDateTime = (datetime) => {
        let time = new Date(datetime)
        let result = time.getFullYear() + "-" + (time.getMonth() < 10 ? `0${time.getMonth()}` : time.getMonth()) + "-" + (time.getDate() < 10 ? `0${time.getDate()}` : time.getDate()) + "T" + (time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()) + ":" + (time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes())
        return result
    }

    //custom select


    handleChangeCustomAutocomplete = (selectCustomAutocomplete, question) => {
        let value = this.state.formUpdateRepy1[question]
        value["value"] = [selectCustomAutocomplete]


        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question]: value
            }
        })
    }

    handleChangeCustomAutocompleteSingleFormat = (selectCustomAutocomplete, question) => {
        let value = this.state.formUpdateRepy1[question.id]
        value["value"] = [selectCustomAutocomplete]


        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: value
            }
        })
    }


    handleChangeCustomAutocompleteMultiple = (selectCustomAutocomplete, question) => {
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []



            let value = this.state.formUpdateRepy1[question]
            value["value"] = [selectCustomAutocomplete]


            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: value
                    }
                })
            }
        }
    }


    getSubform = (id, type) => {
        this.updateValuesFormat("basic_field");
        fetch(`/formatos/get_questions_subform/${id}/${this.props.survey.id}/0/${type}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data, "adjfñlasfk")
                this.setState({
                    modal: true,
                    question_actual: id,
                    surveySubform: data.survey,
                    formatSubForm: data.format,
                    dataAnswerSubForms: data.dataAnswer,
                    dataSubForms: data.data,
                    hide_show_ids_: data.hide_show_ids,
                })
            });
    }

    editSubform = (question_id, surveyid) => {

        fetch(`/formatos/get_questions_subform/${question_id}/${this.props.survey.id}/${surveyid}/sf`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    modal: true,
                    surveySubform: data.survey,
                    formatSubForm: data.format,
                    dataAnswerSubForms: data.dataAnswer,
                    dataSubForms: data.data,
                    hide_show_ids_: data.hide_show_ids,
                })
            });
    }

    deleteSubform = (question_id, surveyid) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/delete_questions_subform/${question_id}/${this.props.survey.id}/${surveyid}`, {
                    method: 'GET', // or 'PUT'
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(data => {
                        this.props.loadData()
                    });
            }
        });
    }


    //select values

    handleChangeAutocompleteSelect = (selectedOption, question) => {

        //this.updateValuesFormat("basic_field");
        console.log(question)
        console.log("afasddsasdf", selectedOption)
        console.log()
        let value = this.state.formUpdateRepy1[question.id]
        value["value"] = [selectedOption]

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: value
            }
        }, () => {
            if (question.question_type == "format_id") {
                this.updateValuesFormat("format", question.id);
            }

        })
    };



    clearValue = (question) => {

        //this.updateValuesFormat("basic_field");
        this.createClearSelectLog(this.props.survey.id, question.id, this.state.formUpdateRepy1[question.id].value[0])
        console.log(question.id)
        console.log("clear", this.state.formUpdateRepy1[question.id].value)
        let value = this.state.formUpdateRepy1[question.id]
        let value_final = [{ label: "seleccione una opción", value: `${value.value[0].value}_delete` }]
        value["value"] = [{ label: 'Seleccione una opción', value: `${this.state.formUpdateRepy1[question.id].value[0].value}_delete` }]

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: value
            },
            formUpdateRepy2: {
                ...this.state.formUpdateRepy2,
                [question.id]: { ...this.state.formUpdateRepy2[question.id], value: [{ label: "" }] }
            }
        }, () => {
            if (question.question_type == "format_id") {
                this.updateValuesFormat("format", question.id);
            }

        })
    }





    handleChangeCustomAutocompleteMultipleSelect = (selectCustomAutocomplete, question) => {
        console.log("hola")
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []
            let value = this.state.formUpdateRepy1[question]
            value["value"] = [selectCustomAutocomplete]
            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: value
                    }
                })
            }
        }
    }


    updateStateCe10 = (question) => {
        let value = this.state.formUpdateRepy1[question.id]
        value["is_ce10"] = !value["is_ce10"]

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: value
            }
        })
    }

    handleChangeAutocompleteCe10 = (question, selectedOptionCe10) => {
        if (selectedOptionCe10.__isNew__ && selectedOptionCe10.label.length >= 3) {
            const form = { search: selectedOptionCe10.value }
            let value = this.state.formUpdateRepy1[question.id]
            value["is_ce10"] = true

            this.setState({
                question_id: question.id,
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question.id]: value
                },
            })

            fetch(`/settings/search_health_codes`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    let value = this.state.formUpdateRepy1[question.id]
                    value["is_ce10"] = false
                    value["value"] = [selectedOptionCe10.label]

                    this.setState({
                        formUpdateRepy1: {
                            ...this.state.formUpdateRepy1,
                            [question.id]: value
                        },

                        ce_10s: data.data
                    })
                });
        } else {
            if (this.state.ce_10s.length >= 1 && this.state.question_id == question.id) {
                let value = this.state.formUpdateRepy1[question.id]
                value["value"] = [selectedOptionCe10.label]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question.id]: value
                    },
                })
            }
        }
    }

    handleRefreshClick = () => {
        this.setState({
            timeStamp: Date.now()
        })
    }


    getTypeQuestion = (question) => {
        if (question.question_type == "answer_string") {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        name="answer_string"
                        className="form form-control imput-options-disabled"
                        data-id={question.id}
                        data-question={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        disabled={!question.is_enable}
                        autoComplete="off"
                    //onBlur={() => this.updateValuesFormat("basic_field")}

                    />
                </React.Fragment>
            )

        } if (question.question_type == "answer_hour") {
            return (
                <React.Fragment>
                    <TimeField
                        onChange={this.HandleChangeRepy}
                        value={this.state.formUpdateRepy1[question.id].value}
                        input={
                            <input
                                type="text"
                                name="answer_hour"
                                className="form-control"
                                data-id={question.id}
                                data-question={question.id}
                                data-type={question.question_type}
                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
                                disabled={!question.is_enable}
                                autoComplete="off"
                            />
                        }
                    />
                </React.Fragment>
            )

        }
        else if (question.question_type == "answer_integer") {

            return (
                <React.Fragment>
                    {question.is_currency ? (
                        <NumberFormat
                            name="answer_integer"
                            thousandSeparator={true}
                            prefix={'$'}
                            className={`form form-control`}

                            data-id={question.id}
                            data-question={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            disabled={!question.is_enable}
                            autoComplete="off"
                            onBlur={() => this.updateValuesFormat("integer")}
                            value={this.state.formUpdateRepy1[question.id].value.toString()}
                            onChange={this.HandleChangeRepyNumber}

                        />

                    ) : (

                        <input
                            type="number"
                            className="form form-control imput-options-disabled"
                            name="answer_integer"
                            data-id={question.id}
                            data-question={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            disabled={!question.is_enable}
                            value={this.state.formUpdateRepy1[question.id].value}
                            onChange={this.HandleChangeRepy}
                            onBlur={() => this.updateValuesFormat("integer")}
                            autoComplete="off"
                        />
                    )}
                </React.Fragment>
            )

        } else if (question.question_type == "code_format") {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        name="answer_string"
                        data-question={question.id}
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder="El código se generará automáticamente cuando guardes la información"
                        disabled={true}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        autoComplete="off"
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "pdf_viewer") {
            console.log("question estivne", question)
            return (
                <div className="col-md-12 text-center ui-documentGestion-containerfiles">
                    {/*  <p>Si no te aparece el pdf por favor haz click en el boton Recargar</p> */}
                    <GoogleDocsViewer
                        width={isMobile ? ('100%') : ('900px')}
                        height="780px"
                        fileUrl={question.has_default_value ? question.default_value_text : `https://beewo-survey-pdfs.s3.us-east-2.amazonaws.com/uploads/${this.props.survey.tenant_id}/surveys/${this.props.survey.id}/${question.format_pdf_id}/${this.props.format.name.replaceAll(" ", "-")}.pdf?t=${this.state.timeStamp}`}
                    />
                    <button className='btn btn-primary' onClick={this.handleRefreshClick}>Recargar</button>
                </div>
            )


        } else if (question.question_type == "answer_user_id") {

            if (question.is_multiple) {

                return (
                    <React.Fragment>
                        <input
                            type="hidden"
                            name="answer_user_id"
                            value={this.state.formUpdateRepy1[question.id].value}
                        />

                        <Select
                            onChange={(e) => this.handleChangeCustomAutocompleteMultipleSelect(e, question.id)}
                            options={this.state.options}
                            isMulti
                            closeMenuOnSelect={false}
                            autoFocus={false}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione una o varias opciones"
                            isDisabled={!question.is_enable}
                            name="position_ids"
                            /*  onBlur={() => this.updateValuesFormat("basic_field")} */
                            defaultValue={[
                                this.state.formUpdateRepy1[question.id].value[0],
                                this.state.formUpdateRepy1[question.id].value[1],
                                this.state.formUpdateRepy1[question.id].value[2],
                                this.state.formUpdateRepy1[question.id].value[3],
                                this.state.formUpdateRepy1[question.id].value[4],
                                this.state.formUpdateRepy1[question.id].value[5]
                            ]}
                        />
                    </React.Fragment>
                )

            } else {

                return (
                    <React.Fragment>
                        <input
                            type="hidden"
                            name="answer_user_id"
                            value={this.state.formUpdateRepy1[question.id].value[0] ? this.state.formUpdateRepy1[question.id].value[0].value : ""}
                        />

                        <Select
                            onChange={(e) => this.handleChangeAutocompleteSelect(e, question)}
                            options={this.state.options}
                            isDisabled={!question.is_enable}
                            autoFocus={false}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione una opción"
                            value={this.state.formUpdateRepy1[question.id].value[0]}
                        />
                    </React.Fragment>
                )
            }

        } else if (question.question_type == "position_id") {

            if (question.is_multiple) {

                return (
                    <React.Fragment>
                        <input
                            type="hidden"
                            name="position_id"
                            value={this.state.formUpdateRepy1[question.id].value}
                        />

                        <Select
                            onChange={(e) => this.handleChangeCustomAutocompleteMultipleSelect(e, question.id)}
                            options={this.state.cargos}
                            isMulti
                            closeMenuOnSelect={false}
                            autoFocus={false}
                            isDisabled={!question.is_enable}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione una o varias opciones"
                            /* onBlur={() => this.updateValuesFormat("basic_field")} */

                            name="position_id"
                            defaultValue={[
                                this.state.formUpdateRepy1[question.id].value[0],
                                this.state.formUpdateRepy1[question.id].value[1],
                                this.state.formUpdateRepy1[question.id].value[2],
                                this.state.formUpdateRepy1[question.id].value[3],
                                this.state.formUpdateRepy1[question.id].value[4],
                                this.state.formUpdateRepy1[question.id].value[5]
                            ]}
                        />
                    </React.Fragment>
                )

            } else {

                return (
                    <React.Fragment>
                        <input
                            type="hidden"
                            name="position_id"
                            value={this.state.formUpdateRepy1[question.id].value[0] ? this.state.formUpdateRepy1[question.id].value[0].value : ""}
                        />

                        <Select
                            onChange={(e) => this.handleChangeAutocompleteSelect(e, question)}
                            options={this.state.cargos}
                            isDisabled={!question.is_enable}
                            autoFocus={false}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione una opción"
                            /*  onBlur={() => this.updateValuesFormat("basic_field")} */

                            value={this.state.formUpdateRepy1[question.id].value[0]}
                        />
                    </React.Fragment>
                )
            }

        } else if (question.question_type == "proceso_id") {

            if (question.is_multiple) {

                return (
                    <React.Fragment>
                        <input
                            type="hidden"
                            name="proceso_id"
                            value={this.state.formUpdateRepy1[question.id].value}
                        />

                        <Select
                            onChange={(e) => this.handleChangeCustomAutocompleteMultipleSelect(e, question.id)}
                            options={this.state.procesos}
                            isMulti
                            closeMenuOnSelect={false}
                            autoFocus={false}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione una o varias opciones"
                            /*  onBlur={() => this.updateValuesFormat("basic_field")} */
                            isDisabled={!question.is_enable}

                            name="proceso_id"
                            defaultValue={[
                                this.state.formUpdateRepy1[question.id].value[0],
                                this.state.formUpdateRepy1[question.id].value[1],
                                this.state.formUpdateRepy1[question.id].value[2],
                                this.state.formUpdateRepy1[question.id].value[3],
                                this.state.formUpdateRepy1[question.id].value[4],
                                this.state.formUpdateRepy1[question.id].value[5]
                            ]}
                        />
                    </React.Fragment>
                )

            } else {

                return (
                    <React.Fragment>
                        <input
                            type="hidden"
                            name="proceso_id"
                            value={this.state.formUpdateRepy1[question.id].value[0] ? this.state.formUpdateRepy1[question.id].value[0].value : ""}
                        />

                        <Select
                            onChange={(e) => this.handleChangeAutocompleteSelect(e, question)}
                            options={this.state.procesos}
                            autoFocus={false}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione una opción"
                            isDisabled={!question.is_enable}
                            /*  onBlur={() => this.updateValuesFormat("basic_field")} */

                            value={this.state.formUpdateRepy1[question.id].value[0]}
                        />
                    </React.Fragment>
                )
            }

        }

        else if (question.question_type == "answer_text") {
            return (
                <React.Fragment>
                    {question.is_enable ? (
                        <>
                            <FroalaEditor
                                key={this.state.nexStep}
                                model={this.state.formUpdateRepy1[question.id].value[0]}
                                config={this.state.configFroala}
                                question={question}
                                onModelChange={(e) => this.HandleChangeCkaditor(e, question)}
                            />
                        </>

                    ) : (
                        <React.Fragment>
                            <div className='answer-text-container'>{ReactHtmlParser(this.state.formUpdateRepy1[question.id].value[0])}</div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )

        } else if (question.question_type == "answer_date") {
            return (
                <React.Fragment>
                    {isMobile && (
                        <i className="far fa-calendar ui-show-surveys_icon_calendar_float"></i>
                    )}
                    <input
                        type="date"
                        className="form form-control imput-options-disabled ui-show-surveys_answer_date"
                        name="answer_date"
                        data-id={question.id}
                        data-question={question.id}
                        /*  onBlur={() => this.updateValuesFormat("basic_field")} */
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        disabled={!question.is_enable}
                        autoComplete="off"
                    />
                    {(isMobile && this.state.formUpdateRepy1[question.id].value == '') && (
                        <div className='mt-1'>¡Por favor, ingresa una fecha!</div>
                    )}
                </React.Fragment>
            )

        } else if (question.question_type == "ce_10") {
            return (
                <React.Fragment>
                    <input
                        type="hidden"
                        name="ce_10"
                        onChange={(e) => console.log(e)}
                        value={this.state.formUpdateRepy1[question.id].value}
                    />

                    <CreatableSelect
                        isClearable
                        onChange={(e) => this.handleChangeAutocompleteCe10(question, e)}
                        options={this.state.question_id == question.id ? this.state.ce_10s : []}
                        noOptionsMessage={() => 'Escribe el nombre o código de la enfermedad'}
                        autoFocus={false}
                        className={`link-form`}
                        value={{ label: this.state.formUpdateRepy1[question.id].value, value: this.state.formUpdateRepy1[question.id].value }}
                        formatCreateLabel={(inputValue) => `Buscar "${inputValue}"`}
                    />

                    {this.state.formUpdateRepy1[question.id].is_ce10 && (
                        <LoadingComponen />
                    )}
                </React.Fragment>
            )

        } else if (question.question_type == "answer_datetime") {
            return (
                <React.Fragment>

                    <input
                        type="datetime-local"
                        className="form form-control imput-options-disabled"
                        name="answer_time"
                        data-id={question.id}
                        data-question={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.getDateTime(this.state.formUpdateRepy1[question.id].value)}
                        onChange={this.HandleChangeRepy}
                        disabled={!question.is_enable}
                        autoComplete="off"
                    />

                </React.Fragment>
            )

        } else if (question.question_type == "answer_file") {
            return (
                <React.Fragment>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="mt-3">
                                {question.is_enable ? (
                                    <React.Fragment>
                                        <Dropzone
                                            onDrop={(files) => this.HandleChangeFile(files, question.id, question.is_mandatory, question.is_active, question)}
                                            disabled={this.props.survey.state}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div
                                                    {...getRootProps({
                                                        className: 'dropzone',
                                                    })}
                                                >
                                                    {question.special_value == "camera" ? (
                                                        <input capture="user"  {...getInputProps()} />
                                                    ) : (
                                                        <input    {...getInputProps()} />
                                                    )}

                                                    <p style={{ color: this.state.formUpdateRepy1[question.id].value[0].name ? "#0094ff" : "#a5a5a5" }}>{`${(this.state.formUpdateRepy1[question.id].value[0].name ? this.state.formUpdateRepy1[question.id].value[0].name : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                </div>
                                            )}
                                        </Dropzone>

                                        {rdd.mobileModel == "iPhone" && (
                                            <div className="alert alert-primary mt-3 text-center" role="alert">
                                                <b>{"Si vas a subir una imagen tomada desde un celular iPhone, esta debe ser tomada con orientación horizontal"}</b>
                                            </div>
                                        )}

                                        {(this.state.errorUploadFile.question_id == question.id && this.state.errorUploadFile.message != "") ? (
                                            <div className="alert alert-primary mt-3" role="alert">
                                                <b>{this.state.errorUploadFile.message}</b>
                                            </div>
                                        ) : (
                                            <p className="mb-0 mt-2"><b>Archivo: </b>
                                                <a
                                                    href={this.state.formUpdateRepy1[question.id].value[0].url}
                                                    target="_blanck"
                                                >
                                                    {this.state.formUpdateRepy1[question.id].value[0].name}
                                                </a>
                                            </p>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <div
                                        className="text-center"
                                        style={{ padding: "24px", background: "#f2f2f2" }}
                                    >
                                        <h5>Pregunta inactiva</h5>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (question.question_type == "answer_select") {

            return (
                <select
                    name="answer_select"
                    className="form form-control"
                    onChange={(e) => this.setValueSelect(e, question)}
                    disabled={!question.is_enable}
                    data-id={question.id}
                    data-type={question.question_type}
                    data-mandatory={question.is_mandatory}
                    data-active={question.is_active}
                    data-question={question.id}
                    autoComplete="off"
                    /*  onBlur={() => this.updateValuesFormat("basic_field")} */

                    value={this.state.formUpdateRepy1[question.id].value}
                >
                    <option value="">Seleccione una opción</option>
                    {question.options.map((name, index) => (
                        <option value={name.name}>{name.name}</option>
                    ))}

                </select>

            )

        } else if (question.question_type == "answer_localization") {
            this.getGeolocalizacion(question)
            return (
                <div>
                    <input
                        type={!question.is_enable ? "hidden" : "text"}
                        disabled={true}
                        value={this.state.formUpdateRepy1[question.id].value.toString()}
                        className={`form form-control mt-4`}
                        autoComplete="off"
                    />
                </div>
            )

        } else if (question.question_type == "answer_autdatetime") {
            return (
                <input
                    type="text"
                    className="form form-control"
                    disabled={!question.is_enable}
                    value={this.state.formUpdateRepy1[question.id].value}
                    autoComplete="off"
                />
            )

        } else if (question.question_type == "answer_radio") {

            return (
                <div className="row mt-3">
                    {question.align_item == "vertical" ? (
                        question.options.map((name, index) => (
                            <div className="col-md-12" style={{ display: 'flex', alignItems: 'baseline' }} key={index}>
                                <input
                                    id={name.name}
                                    onChange={(e) => this.HandleChangeRepyRadio(e, question.id)}
                                    type="radio"
                                    value={name.name}
                                    data-id={question.id}
                                    data-question={question.id}


                                    data-type={question.question_type}
                                    disabled={!question.is_enable}
                                    data-mandatory={question.is_mandatory}
                                    data-active={question.is_active}
                                    checked={this.state.formUpdateRepy1[question.id].value[0] == name.name ? true : false}
                                    autoComplete="off"
                                    className="mr-2"
                                />
                                <label> {name.name} </label>
                            </div>
                        ))

                    ) : (
                        <div className="ui-showSurvey-inputRadioHorizontal">
                            {question.options.map((name, index) => (
                                <div className="ui-showSurvey-inputRadio-input-group" key={index}>
                                    <label className='ui-showSurvey-inputRadio-label'>{name.name}</label>
                                    <input
                                        id={name.name}
                                        onChange={(e) => this.HandleChangeRepyRadio(e, question.id)}
                                        type="radio"
                                        value={name.name}
                                        data-id={question.id}
                                        data-question={question.id}


                                        data-type={question.question_type}
                                        disabled={!question.is_enable}
                                        data-mandatory={question.is_mandatory}
                                        data-active={question.is_active}
                                        checked={this.state.formUpdateRepy1[question.id].value[0] == name.name ? true : false}
                                        autoComplete="off"
                                    />
                                </div>

                            ))}

                        </div>
                    )}
                </div>
            )

        } else if (question.question_type == "answer_checkbox") {
            return (
                <div className="row mt-3">
                    {question.align_item == "vertical" ? (
                        question.options.map((name, index) => (
                            <div className="col-md-12" style={{ display: 'flex', alignItems: 'baseline' }} key={index}>
                                <input
                                    name="answer_checkbox"
                                    onChange={(e) => this.HandleChangeRepyArray(e, question)}
                                    value={name.name}
                                    data-id={question.id}
                                    data-type={question.question_type}
                                    disabled={!question.is_enable}
                                    data-question={question.id}
                                    data-mandatory={question.is_mandatory}
                                    data-active={question.is_active}
                                    checked={this.state.formUpdateRepy1[question.id].value[0].includes(name.name)}
                                    autoComplete="off"
                                    type="checkbox"
                                    /*  onBlur={() => this.updateValuesFormat("basic_field")} */

                                    className="mr-2"
                                />
                                <label > {name.name}</label>
                            </div>
                        ))

                    ) : (
                        <div className="ui-showSurvey-inputCheckHorizontal">
                            {question.options.map((name, index) => (
                                <div className="ui-showSurvey-inputCheck-input-group" key={index}>
                                    <input
                                        name="answer_checkbox"
                                        onChange={(e) => this.HandleChangeRepyArray(e, question)}
                                        value={name.name}
                                        data-id={question.id}
                                        data-type={question.question_type}
                                        disabled={!question.is_enable}
                                        data-question={question.id}
                                        data-mandatory={question.is_mandatory}
                                        data-active={question.is_active}
                                        checked={this.state.formUpdateRepy1[question.id].value[0].includes(name.name)}
                                        autoComplete="off"
                                        type="checkbox"
                                        /*  onBlur={() => this.updateValuesFormat("basic_field")} */

                                        className="mr-2"
                                    />
                                    <label className='ui-showSurvey-inputCheck-label'>{name.name}</label>
                                </div>
                            ))}

                        </div>
                    )}
                </div>
            )
        } else if (question.question_type == "answer_formula") {
            return (
                <React.Fragment>
                    {question.is_currency ? (
                        <NumberFormat
                            name="answer_formula"
                            thousandSeparator={true}
                            prefix={'$'}
                            className={`form form-control`}
                            data-id={question.id}
                            data-question={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            autoComplete="off"
                            disabled={true}
                            value={this.state.formUpdateRepy1[question.id].value.toString()}
                            onChange={this.HandleChangeRepyNumber}

                        />

                    ) : (
                        <input
                            type="text"
                            className="form form-control imput-options-disabled"
                            name="answer_formula"
                            data-id={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            disabled={true}
                            value={this.state.formUpdateRepy1[question.id].value}
                            onChange={this.HandleChangeRepy}
                            autoComplete="off"
                        />
                    )}
                </React.Fragment>
            )

        } // Aformat_id 
        else if (question.question_type == "format_id") {
            if (question.is_multiple) {

                return (
                    <React.Fragment>
                        <div className="question-format-id">
                            <input
                                type="hidden"
                                name="formats_id"
                                value={this.state.formUpdateRepy1[question.id].value}
                            />

                            <Select
                                onChange={(e) => this.handleChangeCustomAutocompleteMultipleSelect(e, question.id)}
                                options={this.state.formatValues[question.id] ? this.state.formatValues[question.id].data : ""}
                                isMulti
                                closeMenuOnSelect={false}
                                autoFocus={false}
                                className={`link-form`}
                                classNamePrefix="select"
                                isDisabled={!question.is_enable}
                                placeholder="Seleccione"
                                name="formats_id"
                                defaultValue={[
                                    this.state.formUpdateRepy1[question.id].value[0],
                                    this.state.formUpdateRepy1[question.id].value[1],
                                    this.state.formUpdateRepy1[question.id].value[2],
                                    this.state.formUpdateRepy1[question.id].value[3],
                                    this.state.formUpdateRepy1[question.id].value[4],
                                    this.state.formUpdateRepy1[question.id].value[5]
                                ]}
                            />
                            {question.add_new && (
                                <i className="fas fa-plus float-right icon-add mr-2" onClick={() => this.getSubform(question.id, "fm")}></i>
                            )}

                        </div>
                    </React.Fragment>
                )

            } else {

                return (
                    <React.Fragment>
                        <span style={{ cursor: "pointer", color: "gray", float: "right", paddingRight: "12px" }} onClick={() => this.clearValue(question)}>Limpiar</span><br />
                        <div className="question-format-id">
                            {(this.state.format_field_current == question.id && this.state.format_field_loading) && (
                                <div className='question-format-id__loading'>  <PreloaderMini /></div>
                            )}

                            <input
                                type="hidden"
                                name="formats_id"
                                value={this.state.formUpdateRepy1[question.id].value[0] ? this.state.formUpdateRepy1[question.id].value[0].value : ""}
                            />

                            <div style={{ display: question.is_currency ? "none" : "", width: "100%" }}>

                                <Select
                                    onChange={(e) => this.handleChangeAutocompleteSelect(e, question)}
                                    onInputChange={(e) => this.handleInputChangeSelect(e, question)}
                                    onFocus={(e) => this.handleInputOnFocusSelect(e, question)}
                                    options={this.state.formatValuesFormat[question.id] ? this.state.formatValuesFormat[question.id].data : []}
                                    autoFocus={false}
                                    className={`link-form`}
                                    placeholder="Seleccione una opción"
                                    isDisabled={!question.is_enable}
                                    value={this.state.formUpdateRepy1[question.id].value[0] ? this.state.formUpdateRepy1[question.id].value[0] : ""}
                                />
                            </div>


                            <input
                                style={{ display: !question.is_currency ? "none" : "" }}
                                type="text"
                                className="form form-control"
                                name="formats_id"
                                onChange={(e) => this.handleChangeAutocompleteSelectInput(e, question)}
                                value={this.state.formUpdateRepy2[question.id] && this.state.formUpdateRepy2[question.id].value[0] ? this.state.formUpdateRepy2[question.id].value[0].label : ""}
                            />
                            {question.add_new && (
                                <i className="fas fa-plus float-right icon-add mr-2 ml-2" onClick={() => this.getSubform(question.id, "fm")}></i>
                            )}
                        </div>
                    </React.Fragment>
                )
            }

        } else if (question.question_type == "dynamic_field") {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        name="answer_string"
                        data-question={question.id}
                        data-id={question.id}

                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder="El código se generará automáticamente cuando guardes la información"
                        disabled={true}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        autoComplete="off"
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "email") {
            return (
                <React.Fragment>
                    <input
                        type="email"
                        className="form form-control imput-options-disabled"
                        name="email"
                        data-question={question.id}
                        data-id={question.id}
                        data-type={question.question_type}
                        disabled={!question.is_enable}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder="beewocorreo@ejemplo.com"
                        /*  onBlur={() => this.updateValuesFormat("basic_field")} */
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        autoComplete="off"
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "url") {
            return (
                <React.Fragment>
                    <input
                        type="url"
                        className="form form-control imput-options-disabled"
                        name="url"
                        data-question={question.id}
                        data-id={question.id}
                        data-type={question.question_type}
                        disabled={!question.is_enable}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder=""
                        /*   onBlur={() => this.updateValuesFormat("basic_field")} */
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        autoComplete="off"
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_subform") {
            return (
                <React.Fragment>
                    <div className="row sub-form-input" style={{ paddingRight: "20px" }}>
                        <div className="col-md-12 text-right" style={{ paddingRight: "0px" }}>
                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.getSubform(question.id, "sf")}
                            >
                                Nuevo
                            </button>

                            <hr />
                        </div>
                        {isMobile ? (
                            <div className="col-md-12">
                                {this.state.formUpdateRepy1[question.id].value[0].answers.map(answer => (
                                    <div className="sub-form-container" style={{ padding: "10px", marginTop: "10px", border: "1px solid #e2e2e2" }} >
                                        <div className="sub-form-container__options  text-right">
                                            <i onClick={() => this.editSubform(question.id, answer.id)} className="fa-pencil-alt fas mr-2"></i>
                                            <i onClick={() => this.deleteSubform(question.id, answer.id)} className="fa-trash-alt fas"></i>
                                        </div>
                                        {answer.value.map(answer_child => (

                                            <div className="sub-form-container__item"><b>{answer_child.question}</b> <br />
                                                {answer_child.type == "answer_file" && (
                                                    <a href={answer_child.value}>Archivo</a>
                                                )}

                                                {(answer_child.type == "answer_string" || answer_child.type == "email" || answer_child.type == "answer_select" || answer_child.type == "answer_radio" || answer_child.type == "ce_10") && (
                                                    <p>{answer_child.value}</p>
                                                )}
                                                {answer_child.type == "answer_text" && (
                                                    <p>{ReactHtmlParser(answer_child.value)}</p>
                                                )}
                                                {(answer_child.type == "answer_integer" || answer_child.type == "answer_formula") && (
                                                    <p>
                                                        {answer_child.is_currency ? (

                                                            <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={answer_child.value} />
                                                        ) : (
                                                            <React.Fragment>{answer_child.value}</React.Fragment>

                                                        )}
                                                    </p>
                                                )}
                                                {(answer_child.type == "answer_date") && (
                                                    <p>{answer_child.value}</p>
                                                )}
                                                {(answer_child.type == "answer_checkbox") && (
                                                    <p>{answer_child.value}</p>
                                                )}
                                                {(answer_child.type == "answer_signature") && (
                                                    <p> <img style={{ width: "150px", height: "50px" }} src={answer_child.value} /></p>
                                                )}
                                                {(answer_child.type == "answer_user_id") && (
                                                    <p>{answer_child.value}</p>
                                                )}
                                                {(answer_child.type == "proceso_id") && (
                                                    <p>{answer_child.value}</p>
                                                )}
                                                {(answer_child.type == "position_id") && (
                                                    <p>{answer_child.value}</p>
                                                )}
                                                {(answer_child.type == "url") && (
                                                    <p><a href={answer_child.value} target="_blank">{answer_child.value}</a></p>
                                                )}
                                                {(answer_child.type == "dynamic_field") && (
                                                    <p><a href={answer_child.value} target="_blank">{answer_child.value}</a></p>
                                                )}

                                            </div>
                                        ))}


                                    </div>
                                ))}

                            </div>
                        ) : (
                            <div className="col-md-12 table-sub-form_container">
                                <table className="table-sub-form" style={{ padding: "10px", marginTop: "10px", width: this.state.formUpdateRepy1[question.id].value[0].questions.length > 4 ? (this.state.formUpdateRepy1[question.id].value[0].questions.length * 200) : "100%" }} >
                                    <tr>
                                        <th className="text-center" style={{ width: "80px" }}></th>
                                        {this.state.formUpdateRepy1[question.id].value[0].questions.map(answer_th => (
                                            <th>{answer_th}</th>
                                        )
                                        )}
                                    </tr>
                                    {this.state.formUpdateRepy1[question.id].value[0].answers.map(answer => (
                                        < tr >
                                            <td className="sub-form-container__options text-center">
                                                <i onClick={() => this.editSubform(question.id, answer.id)} className="fas fa-pencil-alt mr-2"></i>
                                                <i onClick={() => this.deleteSubform(question.id, answer.id)} className="fas fa-trash-alt"></i>
                                            </td>
                                            {answer.value.map(answer_child => (

                                                <td>
                                                    {answer_child.type == "answer_file" && (
                                                        <a href={answer_child.value}>Archivo</a>
                                                    )}
                                                    {(answer_child.type == "answer_string" || answer_child.type == "email" || answer_child.type == "answer_select" || answer_child.type == "answer_radio" || answer_child.type == "ce_10") && (
                                                        <p>{answer_child.value}</p>
                                                    )}
                                                    {answer_child.type == "answer_text" && (
                                                        <p>{ReactHtmlParser(answer_child.value)}</p>
                                                    )}
                                                    {(answer_child.type == "answer_integer" || answer_child.type == "answer_formula") && (
                                                        <p>
                                                            {answer_child.is_currency ? (

                                                                <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={answer_child.value} />
                                                            ) : (
                                                                <React.Fragment>{answer_child.value}</React.Fragment>

                                                            )}
                                                        </p>
                                                    )}
                                                    {(answer_child.type == "answer_date") && (
                                                        <p>{answer_child.value}</p>
                                                    )}
                                                    {(answer_child.type == "answer_hour") && (
                                                        <p>{answer_child.value}</p>
                                                    )}

                                                    {(answer_child.type == "format_id") && (
                                                        <p>{answer_child.value}</p>
                                                    )}
                                                    {(answer_child.type == "answer_checkbox") && (
                                                        <p>{answer_child.value}</p>
                                                    )}
                                                    {(answer_child.type == "answer_signature") && (
                                                        <p> <img style={{ width: "150px", height: "50px" }} src={answer_child.value} /></p>
                                                    )}
                                                    {(answer_child.type == "answer_user_id") && (
                                                        <p>{answer_child.value}</p>
                                                    )}
                                                    {(answer_child.type == "proceso_id") && (
                                                        <p>{answer_child.value}</p>
                                                    )}
                                                    {(answer_child.type == "position_id") && (
                                                        <p>{answer_child.value}</p>
                                                    )}
                                                    {(answer_child.type == "url") && (
                                                        <p><a href={answer_child.value} target="_blank">{answer_child.value}</a></p>
                                                    )}
                                                    {(answer_child.type == "dynamic_field") && (
                                                        <p><a href={answer_child.value} target="_blank">{answer_child.value}</a></p>
                                                    )}
                                                </td>
                                            ))}

                                        </tr>
                                    ))}
                                </table>

                            </div>
                        )

                        }


                    </div>
                </React.Fragment >
            )

        } else if (question.question_type == "answer_signature") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={`${isMobile ? "canvas-container" : ""}`}>
                                <SignaturePad canvasProps={{ className: `sigPad_generate sigPad_generate_${isMobile ? "290" : "505"}`, width: isMobile ? 290 : 505, height: 150 }} ref={(ref) => { this.state.sigPad[question.id] = ref }} />

                                <div className="mt-2">
                                    <button className={"btn-shadow btn btn-info mr-2"} onClick={() => this.clear(question)}>
                                        <i className="fas fa-trash-alt"></i> Limpiar
                                    </button>

                                    <button className={"btn-shadow btn btn-info"} onClick={() => this.trim(question)}>
                                        <i className="fas fa-camera"></i> Confirmar
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-4">
                            {this.state.formUpdateRepy1[question.id] != undefined && (
                                <React.Fragment>

                                    <img style={{ width: "100%" }} className={"sigImage"} src={this.state.formUpdateRepy1[question.id].value[0]} />
                                </React.Fragment>
                            )}
                        </div>
                    </div>



                </React.Fragment>
            )
        } else if (question.question_type == "answer_matrix") {
            let matrix = this.props.dataAnswer[question.id]
            return <>
                <Matrix matrix={matrix} surveyId={this.props.survey.id} setValues={this.setMatrix} />
            </>
        }
    }

    setMatrix = (value, question_id) => {
        console.log("valuessss", question_id)
        console.log("valuessss1111", value)
        console.log("valuessss22222", value)
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question_id]: { ...this.state.formUpdateRepy1[question_id], value: [value] }
            },
        })
    }

    clear = (question) => {
        this.state.sigPad[question.id].clear()
    }

    trim = (question) => {
        const value = this.state.sigPad[question.id].getTrimmedCanvas().toDataURL('image/png')
        this.setState({
            trimmedDataURL: value,
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: { value: [value], type: "answer_signature", step: this.state.formUpdateRepy1[question.id].step, is_mandatory: false, is_active: true }
            },
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false, dataSubForms: [] })
        }
    }
    HandledChangeSubFormValid = () => {
        this.setState({
            errorSubform: true,

        })
    }


    isDisplayed = question => {

        let display = false
        let display_next = false

        Object.entries(this.state.arrayHidden).forEach(([key, value]) => {
            display_next = value.find(value => value == question.id)
            display = display_next || display ? true : false
        })

        return display

    }


    goBack = () => {
        if (this.props.user_signed) {
            if (this.props.survey.have_indicator) {
                Turbolinks.visit(`/indicator/category_indicators/${this.props.survey.indicator.category_indicator_token}/indicators/${this.props.survey.indicator.indicator_token}`);
            } else if (this.props.survey.have_survey_tab && this.props.type == "parent") {
                Turbolinks.visit(`/formatos/format_categories/${this.props.survey.survey_tab.format_categories_token}/formats/${this.props.survey.survey_tab.format_token}/gestion/${this.props.survey.survey_tab.survey_token}?format_association_id=${this.props.format.id}`);
            } else if (this.props.survey.have_survey_document) {
                Turbolinks.visit(`/document_management/documents/${this.props.survey.document_id}`);
            } else if (this.props.custom_view_id != 0) {
                Turbolinks.visit(`/formatos/custom_view/${this.props.custom_view_id}`);

            } else {
                /*Turbolinks.visit(`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/surveys?view=ACTIVE`)*/
                if (this.props.validate) {
                    Turbolinks.visit(`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/surveys?view=ACTIVE`)
                } else {
                    this.messageSuccess({ type: "success", success: "¡Tu registro se ha guardado con éxito!" })
                    setTimeout(() => {
                        Turbolinks.visit(`/`)
                    }, 2000);
                }
            }
        }
    }


    render() {
        return (
            <React.Fragment>
                {this.state.modal && (
                    <div>
                        {isMobile ? (<ModalSubFormMobile
                            //modal props
                            loadData={this.props.loadData}
                            changeSubFormValid={this.HandledChangeSubFormValid}
                            backdrop={"static"}
                            modal={this.state.modal}
                            toggle={this.toogle}
                            title={this.state.formatSubForm}
                            data={this.state.dataSubForms}
                            survey={this.props.survey.id}
                            surveySubform={this.state.surveySubform}
                            hide_show_ids={this.state.hide_show_ids_}
                            dataAnswer={this.state.dataAnswerSubForms}
                            editSubform={this.editSubform}
                            question={this.state.question_actual}
                            users={this.props.users}
                            positions={this.props.positions}
                            procesos={this.props.procesos}
                            key_text={this.props.key_text}
                        />) : (<ModalSubForm
                            //modal props
                            loadData={this.props.loadData}
                            changeSubFormValid={this.HandledChangeSubFormValid}
                            backdrop={"static"}
                            modal={this.state.modal}
                            toggle={this.toogle}
                            title={this.state.formatSubForm}
                            data={this.state.dataSubForms}
                            survey={this.props.survey.id}
                            surveySubform={this.state.surveySubform}
                            hide_show_ids={this.state.hide_show_ids_}
                            dataAnswer={this.state.dataAnswerSubForms}
                            editSubform={this.editSubform}
                            question={this.state.question_actual}
                            users={this.props.users}
                            positions={this.props.positions}
                            procesos={this.props.procesos}
                            key_text={this.props.key_text}
                        />)}

                    </div>
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        <div className={`container  ${isMobile ? "p-0" : ""}`}>
                            <div className="alert alert-danger" style={{ display: this.state.formulaError ? "block" : "none" }}>¡ Tienes un error en alguna de las formulas por favor revisar. !</div>
                            {this.props.showHeader && (
                                <div className={`col-md-12 text-right`}>
                                    <a
                                        className="btn btn-secondary-info"
                                        style={{ color: "#16aafb" }}
                                        onClick={() => this.goBack()}
                                        data-turbolinks="true"

                                    >
                                        <i className="fas fa-chevron-left"></i> Volver
                                    </a>
                                </div>
                            )}


                            {this.props.format.imagen_url != null && (
                                <div className="col-md-12 mb-3 mt-3">
                                    <div className="card text-center pt-3 pb-3" >
                                        <div>
                                            <img style={{ width: `${this.props.format.max_width_image}%`, height: "auto" }} src={this.props.format.imagen_url} className="" />
                                        </div>
                                    </div>
                                </div>
                            )}


                            <div className={`col-md-12 mx-auto ${isMobile ? "p-0" : ""}`}>

                                <ul className="nav nav-tabs mb-0 ui-navTab-Tabs" id="myTab" role="tablist">
                                    {this.props.data.map((form, index) => (
                                        <li className="nav-item">
                                            {form.survey_step_state ? (
                                                <a
                                                    className={`nav-link ${this.state.nexStep == index ? "active" : ""}`}
                                                    id="home-tab"
                                                    data-toggle="tab"
                                                    /*  href={`/#home${form.id}`} */
                                                    onClick={() => this.setState({ nexStep: index })}
                                                    role="tab"
                                                    aria-disabled={true}
                                                    aria-controls="home"
                                                    aria-selected="true"
                                                >
                                                    {form.step.name == "Formulario sin título" ? "Sección" : form.step.name} {index + 1}/{this.props.data.length}
                                                </a>
                                            ) : (
                                                <div className={`nav-link ${this.state.nexStep == index ? "active" : ""}`}>
                                                    {form.step.name == "Formulario sin título" ? "Sección" : form.step.name} {index + 1}/{this.props.data.length}
                                                </div>
                                            )}

                                        </li>
                                    ))}
                                </ul>

                                <div className="tab-content" id="myTabContent">
                                    {this.props.data.map((form, index) => (
                                        <div className={`tab-pane fade show ${this.state.nexStep == index ? "active mt-4" : ""}`} id={`home${form.id}`} role="tabpanel" aria-labelledby="home-tab">
                                            <div className={`card ${index > 0 ? "mt-4" : ""}`} key={form.id}>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <h4>{form.step.name}</h4>
                                                        </div>

                                                        <div className="col-md-12">
                                                            {ReactHtmlParser(form.step.description)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.props.format.columns_format_number == 1 ? (
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {/* HIDE SHOW QUESTIONS */}
                                                        {form.survey_answers.filter(answer => answer.question_type == "answer_localization" && !answer.is_enable).map((question, index) => (
                                                            <React.Fragment>
                                                                {this.getTypeQuestion(question)}
                                                            </React.Fragment>
                                                        ))}
                                                        {form.survey_answers.filter(answer => answer.question_type != "answer_localization" || (answer.question_type == "answer_localization" && answer.is_enable)).map((question, index) => (
                                                            <div style={{ display: this.isDisplayed(question) ? "none" : "block" }}
            /*                                             className={`card mt-3 ${this.getValidation(question) ? "border-questions" : "border-questions-error"} `}
            */                                            className="card mt-3 border-questions"

                                                                key={question.id}
                                                            >

                                                                <div className="card-body" >
                                                                    <div className="row">
                                                                        <div className={`col-md-12`}>
                                                                            <h5>{index + 1}) {question.question}</h5>
                                                                            <p>{ReactHtmlParser(question.description)}</p>

                                                                            {question.file.url && (
                                                                                <div className="mb-3">
                                                                                    {is_imagen(question.file.url) ? (
                                                                                        <img src={question.file.url} alt="" className="img-thumbnail" style={{ height: "192px" }} />
                                                                                    ) : (
                                                                                        <a
                                                                                            href={question.file.url}
                                                                                            onClick={(e) => e.stopPropagation()}
                                                                                        >
                                                                                            Descargar archivo
                                                                                        </a>
                                                                                    )}
                                                                                </div>
                                                                            )}

                                                                            {this.getTypeQuestion(question)}
                                                                            <div>

                                                                                {this.state.formUpdateRepy1 != undefined ? ((this.state.formUpdateRepy1[question.id].value[0] == null || (this.state.formUpdateRepy1[question.id].value[0] && (this.state.formUpdateRepy1[question.id].value[0].value == "bw-na" || this.state.formUpdateRepy1[question.id].value[0].value?.toString().includes("_delete"))) || this.state.formUpdateRepy1[question.id].value[0].length == 0 || (this.state.formUpdateRepy1[question.id].type == "answer_file" ? this.state.formUpdateRepy1[question.id].value[0].name == undefined : false)) && this.state.errorValues == false && (this.state.formUpdateRepy1[question.id].is_mandatory == "true" || this.state.formUpdateRepy1[question.id].is_mandatory == true) && question.question_type != "answer_subform" ? <b style={{ color: "#ff0000" }}>Esta pregunta es obligatoria</b> : "") : ""}
                                                                            </div>
                                                                            {question.question_type == "answer_subform" && (
                                                                                <div>
                                                                                    {this.state.formUpdateRepy1[question.id].is_mandatory == true && !this.state.errorSubform && this.state.formUpdateRepy1[question.id].value[0].answers.length < 1 ? (<b style={{ color: "#ff0000" }}>Esta pregunta es obligatoria</b>) : ""}
                                                                                </div>
                                                                            )}
                                                                            {(question.question_type == "email" && this.state.formUpdateRepy1[question.id].value.length > 0) && (
                                                                                <div>
                                                                                    {!this.validateEmail(this.state.formUpdateRepy1[question.id].value[0]) && this.state.formUpdateRepy1[question.id].value[0] != "" && this.state.errorValues == false ? (<b style={{ color: "#ff0000" }}>Email no valido</b>) : ""}
                                                                                </div>
                                                                            )}



                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (

                                                <div className="form-survey">
                                                    {form.survey_answers.filter(answer => answer.question_type != "answer_localization" || (answer.question_type == "answer_localization" && answer.is_enable)).map((question, index) => (
                                                        <div
/*                                             className={`card mt-3 ${this.getValidation(question) ? "border-questions" : "border-questions-error"} `}
*/                                            className="card mt-3 border-questions"

                                                            key={question.id}
                                                        >

                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className={`col-md-6`}>
                                                                        <h5>{index + 1}) {question.question}</h5>
                                                                        <p>{question.description}</p>

                                                                        {question.file.url && (
                                                                            <div className="mb-3">
                                                                                <img src={question.file.url} alt="" className="img-thumbnail" style={{ height: "192px" }} />
                                                                            </div>
                                                                        )}


                                                                        {this.getTypeQuestion(question)}
                                                                        <div>
                                                                            {this.state.formUpdateRepy1 != undefined ? ((this.state.formUpdateRepy1[question.id].value[0] == null || this.state.formUpdateRepy1[question.id].value[0].length == 0 || (this.state.formUpdateRepy1[question.id].type == "answer_file" ? this.state.formUpdateRepy1[question.id].value[0].name == undefined : false)) && this.state.errorValues == false && (this.state.formUpdateRepy1[question.id].is_mandatory == "true" || this.state.formUpdateRepy1[question.id].is_mandatory == true) ? <b style={{ color: "#ff0000" }}>Esta pregunta es obligatoria</b> : "") : ""}
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                            )}
                                        </div>
                                    ))}
                                    {this.state.completed && (
                                        <div className="alert alert-danger mt-3">
                                            Hay campos incompletos o invalidos
                                        </div>
                                    )}
                                    <div className="card mt-3">
                                        <div className="card-body">

                                            {this.state.loading && (
                                                <div className="survey-loading-component text-center">

                                                    <div> Enviando formulario </div>
                                                    <LoadingComponen />
                                                </div>
                                            )}
                                            {this.state.loadingNextStep && (
                                                <div className="survey-loading-component text-center">

                                                    <div> Guardando sección </div>
                                                    <LoadingComponen />
                                                </div>
                                            )}
                                            <div className="survey-actions">
                                                <div>
                                                    {this.state.nexStep > 0 && (

                                                        <button
                                                            className="btn-shadow btn btn-light"
                                                            onClick={() => this.updateBackValue()}
                                                        >
                                                            Anterior
                                                        </button>

                                                    )}
                                                </div>
                                                <div>
                                                    <button
                                                        disabled={this.state.loadingNextStep}
                                                        className={`btn-shadow btn btn-${this.state.nexStep + 1 >= this.state.maxStep ? "info" : "light"}`}
                                                        onClick={() => this.updateValues(false)}
                                                    >  {this.state.nexStep + 1 >= this.state.maxStep ? "Guardar" : "Siguiente"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <div className="col-md-12 text-center">
                        <LoadingComponen />
                    </div>
                )}

            </React.Fragment>
        );
    }
}

export default Show;
